import { Timestamp } from "firebase/firestore";

export interface OrderInfo {
  id: string;
  item: string;
  eventPredictor: string[]; // This will now be populated based on brand status
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  customerComment?: string;
  imageUrl: string;
  work?: string; // Optional work field
  tailorNote?: string; // Optional tailor note field
  previousPrice?: number[]; // Optional previous price field
  count?: number; // Optional count field
  event?: Event[]; // Optional event field // Optional event field
  placeholder: string;
  estimatedFinishDate?: Timestamp;
  analytics?: orderInfoAnalytics;
  dateforpickup?: string;
  datefordelivery?: string;
}

export interface orderInfoAnalytics {
  landingCost?: number;
  salesPrice?: number;
  moneyReturned?: boolean;
  carbonFootprint?: number;
}

export interface Event {
  date: string;
  status: string;
  description?: string;
  handler: string;
  Price?: number;
  tailorNote?: string;
  oldPrice?: number;
  newPrice?: number;
}

export interface Order {
  id: string;
  date: string;
  orderInfo: OrderInfo[]; //Kan seltte denne etterhvert som vi har oppdatert OrderPageV2.tsx
  totalPrice: string;
  deliveryMethod: string;
  retrieveMethod: string;
  contactInfo: {
    department: string;
    name: string;
    phone: string;
    address?: string;
  };
  receiveMarketing: boolean;
  email: string;
  paid: boolean;
  qrCodeUrl?: string;
  activity: ActivityItem[];
  resourceNumber?: string;
  deliveryAddress?: string;
  deliveryDepartment?: string;
  retrieveAddress?: string;
  tailorsNote?: string;
  orderCreationUrl?: string;
  claim?: {
    note: string;
    retailPrice?: number;
    article: string;
    model?: string;
  };
  retailId?: string;
  theyDeliver?: boolean;
  brand?: string;
  customerRef?: string;
  delegatedTailor?: string;
  usePostalService?: boolean;
  flags?: {
    customSmsSender?: boolean;
  };
}

export interface OrderSummary extends Order {
  pageName: string | null;
  retailName?: string;
}

export interface ActivityItem {
  id: number;
  content: string;
  message?: string;
  date: string;
  dateTime: string;
  iconBackground: string;
  deviationPrice?: number;
  deviationdeResponse?: string;
  handler: string;
}

export interface Deviation {
  id: string;
  date: string;
  orderInfo: OrderInfo[];
  totalPrice: string;
  deliveryMethod: string;
  retrieveMethod: string;
  note: string;
}

export const FULL_ORDER_STATES = [
  "orderStateCreated", //------------Bestilling opprettet
  "orderStateCustomerDropOff", //====Bestilling levert
  "orderStateDeliverToTailor", //----Hos skredder
  "orderStateRepaired", //===========Ferdig fikset
  "orderStatePickedUpFromTailor", //På utleveringssted
  "orderStateDelivered", //============Utlevert
];

export const BRAND_ORDER_STATES = [
  "orderStateCreated",
  "orderStateDeliverToTailor",
  "orderStateRepaired",
  "orderStatePickedUpFromTailor",
  "orderStateDelivered",
];

export const LINKED_ORDER_STATES = ["orderStateCreated", "orderStatePickedUpFromTailor"];

export const STOP_ORDER_STATES = ["orderStateDeviation", "orderStateCancelled"];

export const STATES_TAILOR_CAN_UPDATE = [
  "orderStateCreated",
  "orderStateDeliverToTailor",
  "changesApproved",
];

export const STATES_TRANSPORTER_CAN_UPDATE = [
  "orderStateCustomerDropOff",
  "orderStateRepaired",
];

export const STATES_TAILOR_CANNOT_UNASSIGN = [
  "orderStatePickedUpFromTailor",
  "orderStateDelivered",
  ...STOP_ORDER_STATES,
];

export const STATES_COURIER_CAN_UPDATE = ["orderStateRepaired"];
