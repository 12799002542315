import React, { useEffect, useState } from "react";

import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import QRCode from "qrcode";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getCurrentUserEmail } from "../../auth";
// Add this import
import { app, db, storage } from "../../firebase";
import { ReactComponent as FikseCatLady } from "../../images/Illustration/FikseFigures/footer-woman-cat.svg";
import { ReactComponent as CheckIcon } from "../../images/icons-fikse/checkmark.svg";
import { ReactComponent as ChevronDownIcon } from "../../images/icons-fikse/chevronDown.svg";
import { BRAND_ORDER_STATES, FULL_ORDER_STATES, OrderInfo } from "../../types/Order";
import PhoneNumberInput from "../PhoneNumberInput";
import { getFormalName, sendSMS } from "../contact";

// Add TypeScript declaration for Google Analytics gtag
declare global {
  interface Window {
    gtag: (command: string, action: string, params?: any) => void;
    dataLayer: any[];
  }
}

// Oppdatert `OrderInfo`-type basert på `RecognitionResult`
/*interface OrderInfo {
  id: string;
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  work: string;
  placeholder: string;
  imageUrl: string;
  customerComment: string;
}*/

interface ContactInfo {
  department: string;
  name: string;
  phone: string;
  address: string;
}

interface ApiResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
}

interface CheckoutB2CProps {
  garments: OrderInfo[];
  contactInfo: ContactInfo;
  onClose: () => void;
  onBackToCart: () => void;
  emailPassedTrough?: string;
  customDropOff?: string[];
  attributes?: string[];
}

const PRICE_FOR_POSTAL_ROUND_TRIP = 170;

const CheckoutB2C: React.FC<CheckoutB2CProps> = ({
  garments,
  contactInfo,
  onClose,
  onBackToCart,
  emailPassedTrough,
  customDropOff,
  attributes,
}) => {
  // Add new state for retail connections
  const [retailConnections, setRetailConnections] = useState<string[]>([]);
  const [selectedRetailId, setSelectedRetailId] = useState<string>("");
  const [selectedRetailName, setSelectedRetailName] = useState<string>("");
  const [customSmsSender, setCustomSmsSender] = useState(false);

  // Add new state for retail names mapping
  const [retailNames, setRetailNames] = useState<{ [key: string]: string }>({});

  const [phoneNumber, setPhoneNumber] = useState(contactInfo.phone || "");
  const [deliveryMethod, setDeliveryMethod] = useState("DEFAULT");
  const [retrieveMethod, setRetrieveMethod] = useState("DEFAULT");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [receiveMarketing, setReceiveMarketing] = useState(false);
  const [marketingEmail, setMarketingEmail] = useState(localStorage.getItem("uid") || "");
  const [isFormValid, setIsFormValid] = useState(false);
  const [lookupData, setLookupData] = useState<ApiResponse | null>(null);
  const [lookupStatus, setLookupStatus] = useState("");
  const [usePostalService, setUsePostalService] = useState(false);
  const [usePostalServiceDelivery, setUsePostalServiceDelivery] = useState(false);
  const [usePostalServiceRetrieval, setUsePostalServiceRetrieval] = useState(false);
  const [retailPrice, setRetailPrice] = useState("");
  const [article, setArticle] = useState("");
  const [model, setModel] = useState("");
  //@andreas-dity hva er hensikt med å defoulte til contactInfo.name? :)
  //Slettes hvis ingen respons er gitt innen 1. Februar 2024
  //const [fullName, setFullName] = useState(contactInfo.name || "");
  const [fullName, setFullName] = useState("");

  const [address, setAddress] = useState(contactInfo.address || "");
  const [uploading, setUploading] = useState(false);
  const [claimStatus, setClaimStatus] = useState(false);
  const [discount, setDiscount] = useState<any>(null);
  const [discountCode, setDiscountCode] = useState("");
  const [discountStatus, setDiscountStatus] = useState<string | null>(null);
  const [claimNote, setClaimNote] = useState("");

  const [dropoffLocations, setDropoffLocations] = useState<string[]>([]);
  const [deliveryLocations, setDeliveryLocations] = useState<string[]>([]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  console.log("customPipeline", customDropOff, attributes, emailPassedTrough);

  const checkDiscountValidity = async () => {
    try {
      const discountQuery = query(
        collection(db, "discount"),
        where("name", "==", discountCode.toUpperCase())
      );
      const querySnapshot = await getDocs(discountQuery);

      if (querySnapshot.empty) {
        setDiscountStatus("Code does not exist.");
        return;
      }

      const discount = querySnapshot.docs[0].data();
      const now = new Date();

      const isTimedValid =
        discount.timed &&
        discount.validFrom &&
        discount.validTo &&
        now >= new Date(discount.validFrom.seconds * 1000) &&
        now <= new Date(discount.validTo.seconds * 1000);

      const hasRemaining = discount.count > 0;

      if (hasRemaining && (!discount.timed || isTimedValid)) {
        setDiscountStatus("Discount applied.");
        setDiscount(discount);
      } else {
        setDiscountStatus("Code is expired or out of uses.");
      }
    } catch (error) {
      console.error("Error checking discount code:", error);
      setDiscountStatus("An error occurred while checking the code.");
    }
  };

  useEffect(() => {
    const acceptTermStatus = Boolean(acceptTerms || attributes?.includes("Brands"));

    const phoneNumberStatus = Boolean(
      phoneNumber?.split(" ")?.[1]?.length === 8 ||
        !phoneNumber.includes("+47") ||
        attributes?.includes("Brands")
    );

    const nameStatus = Boolean(fullName !== "" || attributes?.includes("Brands"));

    const adressStatus = Boolean(address !== "" || attributes?.includes("Brands"));
    setIsFormValid(
      phoneNumberStatus &&
        nameStatus &&
        adressStatus &&
        acceptTermStatus &&
        garments.length > 0
    );
  }, [phoneNumber, acceptTerms, fullName, garments, address]);

  useEffect(() => {
    if (phoneNumber.length === 8) {
      handleLookupClick();
    } else {
      setLookupData(null);
    }
  }, [phoneNumber]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const docRef = doc(collection(db, "dynamicPages"), "locations");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const currentUrl = window.location.href;

          if (customDropOff) {
            setDropoffLocations(customDropOff);
            setDeliveryLocations(customDropOff);
          } else {
            console.log("Setting regular locations:", data.locationsList);
            setDropoffLocations(data.locationsList);
            setDeliveryLocations(data.locationsList);
          }
        } else {
          console.error("No such document in Firestore!");
        }
      } catch (error) {
        console.error("Error fetching locations: ", error);
      }
    };
    fetchLocations();
  }, []);

  // Second useEffect to handle setting initial delivery/retrieve methods
  // This will run whenever dropoffLocations or deliveryLocations change
  useEffect(() => {
    setDeliveryMethod(dropoffLocations[0]);
    setRetrieveMethod(dropoffLocations[0]);
  }, [dropoffLocations, deliveryLocations]);

  // Optional: Third useEffect to log state changes (for debugging)
  useEffect(() => {
    console.log("dropoffLocations updated:", dropoffLocations);
  }, [dropoffLocations]);
  useEffect(() => {
    console.log("deliveryLocations updated:", deliveryLocations);
  }, [deliveryLocations]);

  // Add new useEffect to update methods when postal service states change
  useEffect(() => {
    if (usePostalServiceDelivery) {
      setDeliveryMethod("postforsendelse");
    }
    if (usePostalServiceRetrieval) {
      setRetrieveMethod("postforsendelse");
    }
    // If both are now selected, set the full postal service flag
    if (usePostalServiceDelivery && usePostalServiceRetrieval) {
      setUsePostalService(true);
    } else {
      setUsePostalService(false);
    }
  }, [usePostalServiceDelivery, usePostalServiceRetrieval]);

  // Add new useEffect to handle retail connections
  useEffect(() => {
    const fetchRetailConnections = async () => {
      const connections = localStorage.getItem("retailConnections");
      if (connections) {
        const connectionArray = connections.split(",");
        setRetailConnections(connectionArray);
        if (connectionArray.length > 0) {
          setSelectedRetailId(connectionArray[0]);

          // Fetch all retail names
          const namesMap: { [key: string]: string } = {};
          await Promise.all(
            connectionArray.map(async (retailId) => {
              try {
                const retailDoc = await getDoc(doc(db, "retail", retailId));
                if (retailDoc.exists()) {
                  namesMap[retailId] = retailDoc.data().name;
                }
              } catch (error) {
                console.error("Error fetching retail name:", error);
              }
            })
          );
          setRetailNames(namesMap);
        }
      }
    };
    fetchRetailConnections();
  }, []);

  // Add new useEffect to fetch retail name when selectedRetailId changes
  useEffect(() => {
    const fetchRetailName = async () => {
      if (selectedRetailId) {
        try {
          const retailDoc = await getDoc(doc(db, "retail", selectedRetailId));
          if (retailDoc.exists()) {
            setSelectedRetailName(retailDoc.data().name);
            setCustomSmsSender(retailDoc.data().flags?.customSmsSender);
            console.log(
              "retailDoc.data().flags?.customSmsSender",
              retailDoc.data().flags?.customSmsSender
            );
          }
        } catch (error) {
          console.error("Error fetching retail name:", error);
        }
      }
    };
    fetchRetailName();
  }, [selectedRetailId]);

  const handlePaymentMethodClick = async (method: string, orderId: string) => {
    const functions = getFunctions(app, "europe-west1");

    try {
      console.log(`Processing payment with ${method} for order ID: ${orderId}`);

      if (method === "vipps") {
        const orderRef = doc(db, "orders", orderId);
        const orderSnap = await getDoc(orderRef);
        if (!orderSnap.exists()) {
          throw new Error("Order does not exist");
        }
        const orderData = orderSnap.data();
        console.log("Order data", orderData.contactInfo.phone);

        // ADDED: Track purchase event BEFORE the Vipps redirect
        if (window.gtag) {
          console.log("Sending GA purchase event before Vipps redirect");
          // Extract order info from orderData to build items array
          const items = orderData.orderInfo.map((item: any, index: number) => ({
            item_id: `${orderId}_${index}`,
            item_name: item.item,
            item_category: item.fix_type,
            price: item.price_nok,
            quantity: 1,
          }));

          // Extract total price from the orderData string (format: "100 kr")
          const totalPrice = parseInt(orderData.totalPrice.replace(/\D/g, ""));

          // Get shipping cost if postal service is used
          const shipping = orderData.usePostalService ? PRICE_FOR_POSTAL_ROUND_TRIP : 0;

          // Prepare analytics data
          const analyticsData = {
            transaction_id: orderId,
            value: totalPrice,
            currency: "NOK",
            tax: 0,
            shipping: shipping,
            items: items,
            // Additional data
            order_id: orderId,
            customer_email: orderData.email,
            delivery_method: orderData.deliveryMethod,
            retrieval_method: orderData.retrieveMethod,
            order_date: orderData.date,
            customer_name: orderData.contactInfo.name,
            customer_address: orderData.contactInfo.address,
            customer_phone: orderData.contactInfo.phone,
            has_discount: orderData.discount ? true : false,
            marketing_opt_in: orderData.receiveMarketing,
            order_source: orderData.orderCreationUrl,
            retail_id: orderData.retailId,
            debug_mode: true, // Force debug mode
            pathname: window.location.pathname,
            attributes: attributes?.length ? attributes.join(",") : "B2C",
          };

          console.log("XXXXX1analyticsData", analyticsData);

          console.log("Sending GA purchase event before Vipps redirect", analyticsData);
          window.gtag("event", "completed_order", analyticsData);

          // Push to dataLayer for Google Tag Manager
          window.dataLayer.push({
            event: "completed_order",
            ecommerce: analyticsData,
          });

          // Add a small delay to ensure event is sent
          await new Promise((resolve) => setTimeout(resolve, 500));
        }

        const createVippsCheckout = httpsCallable(
          functions,
          "createVippsCheckoutFiksePortal0225"
        );
        const result = await createVippsCheckout(orderData);

        // Ensure TypeScript understands the expected structure of the result
        if (result.data && typeof result.data === "object" && "url" in result.data) {
          const paymentUrl = (result.data as { url: string }).url;
          window.location.href = paymentUrl;
        } else {
          throw new Error("Unexpected response from Vipps checkout function");
        }
      } else {
        // Simulate a payment processing delay for other methods
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Update Firestore document to mark the order as paid
        const orderRef = doc(db, "orders", orderId);
        await updateDoc(orderRef, {
          paid: true,
        });

        console.log(`Order ${orderId} marked as paid using ${method}.`);
        console.log("Sending SMS to notify the customer...", phoneNumber);
        await sendSMS(
          phoneNumber,
          orderId,
          "Your order has been successfully paid for. We will notify you when it is ready for pickup."
        );
        // Trigger the payment success callback to update the UI
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("An error occurred while processing the payment. Please try again.");
    }
  };

  const functions = getFunctions(undefined, "europe-west1");
  const fetchPhoneNumberData = httpsCallable<{ phone: string }, ApiResponse>(
    functions,
    "fetchPhoneNumberData"
  );

  const handleLookupClick = async () => {
    if (phoneNumber.length === 8) {
      setLookupStatus("Looking up...");
      try {
        const response = await fetchPhoneNumberData({ phone: phoneNumber });

        if (response.data && Object.keys(response.data).length > 0) {
          const data: ApiResponse = response.data;
          setLookupData(data);
          setFullName(
            `${data.firstName}${data.middleName ? " " + data.middleName : ""}${
              data.lastName ? " " + data.lastName : ""
            }`
          );
          setAddress(`${data.address}, ${data.postalCode} ${data.city}`);
          setLookupStatus("Lookup successful");
        } else {
          setLookupData(null);
          setFullName(""); // Clear the fields to show placeholders if no data
          setAddress("");
          setLookupStatus("No information found");
        }
      } catch (error) {
        console.error("Error fetching lookup data: ", error);
        setLookupStatus("Lookup failed");
      }
    } else {
      setLookupStatus("Phone number must be 8 digits");
    }
  };

  const calculateTotalPrice = (orderInfo: OrderInfo[]) => {
    let total = orderInfo.reduce((sum, item) => sum + item.price_nok, 0);

    if (usePostalService) {
      total += PRICE_FOR_POSTAL_ROUND_TRIP;
    } else {
      // Add half the price for each postal service option selected
      if (usePostalServiceDelivery) {
        total += PRICE_FOR_POSTAL_ROUND_TRIP / 2;
      }
      if (usePostalServiceRetrieval) {
        total += PRICE_FOR_POSTAL_ROUND_TRIP / 2;
      }
    }

    if (discount !== null) {
      if (discount.value <= 1) {
        // Prosent-rabatt
        return Math.round(total - total * discount.value);
      } else if (discount.value > 1) {
        // Krone-rabatt
        return Math.round(Math.max(total - discount.value, 0)); // Sørg for at totalen ikke blir negativ
      }
    }

    return Math.round(total); // Ingen rabatt
  };

  const uploadImagesAndGetUrls = async (orderInfo: OrderInfo[]) => {
    const uploadPromises = orderInfo.map(async (item, index) => {
      if (item.imageUrl.startsWith("data:image")) {
        const storageRef = ref(storage, `orders/${Date.now()}_${index}.jpg`);
        await uploadString(storageRef, item.imageUrl, "data_url");
        const url = await getDownloadURL(storageRef);
        return { ...item, imageUrl: url };
      }
      return item;
    });

    return Promise.all(uploadPromises);
  };

  //Todo: Gjør denne her bedere, it can colide
  const generateCustomOrderId = () => {
    const letters = Array.from({ length: 5 }, () =>
      String.fromCharCode(65 + Math.floor(Math.random() * 26))
    ).join("");

    const numbers = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)).join(
      ""
    );
    return `${letters}`; // Using "B2C" as the suffix
  };

  const handlePaymentClick = async () => {
    if (isFormValid) {
      try {
        setUploading(true);

        // Get email based on priority: emailPassedTrough > Firebase Auth > null
        let userEmail = null;
        if (emailPassedTrough) {
          userEmail = emailPassedTrough;
        } else {
          const auth = getAuth();
          userEmail = auth.currentUser?.email || null;
        }

        const sanitizedOrderInfo = await uploadImagesAndGetUrls(garments);

        // Add eventPredictor to each order info item based on whether it's a brand order
        const orderInfoWithPredictor = sanitizedOrderInfo.map((item) => {
          const eventPredictor = attributes?.includes("Brands")
            ? BRAND_ORDER_STATES
            : FULL_ORDER_STATES;

          return {
            ...item,
            eventPredictor,
            event: [
              {
                date: new Date().toISOString(),
                status: eventPredictor[0],
                handler: getCurrentUserEmail(),
                linkedEvent: true,
              },
            ],
          };
        });

        const totalPrice = calculateTotalPrice(orderInfoWithPredictor);

        const initialActivity = {
          id: 1,
          content: "orderStateCreated",
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(),
          timestamp: new Date().toISOString(),
        };

        const customOrderId = generateCustomOrderId();

        // Check if this is an OtherTextiles order from /offices path
        const serviceCategory = localStorage.getItem("serviceCategory") || "";
        const isOtherTextilesFromOffices =
          (serviceCategory.includes("NO-OtherTextiles-offices-0325") ||
            serviceCategory.includes("EN-OtherTextiles-offices-0325")) &&
          window.location.pathname.includes("/offices");

        const orderData = {
          id: customOrderId,
          email: userEmail,
          orderInfo: orderInfoWithPredictor,
          contactInfo: {
            ...contactInfo,
            phone: phoneNumber,
            name: fullName,
            address,
          },
          date: new Date().toISOString(),
          totalPrice: `${totalPrice} kr`,
          deliveryMethod,
          retrieveMethod,
          receiveMarketing,
          marketingEmail: receiveMarketing ? marketingEmail : null,
          activity: [initialActivity],
          orderCreationUrl: window.location.href,
          discount: discount,
          // Set postal service flags
          ...(usePostalService || usePostalServiceDelivery || usePostalServiceRetrieval
            ? {
                usePostalService: usePostalService,
                ...(usePostalServiceDelivery && !usePostalService
                  ? { usePostalServiceDelivery: true }
                  : {}),
                ...(usePostalServiceRetrieval && !usePostalService
                  ? { usePostalServiceRetrieval: true }
                  : {}),
              }
            : {}),
          // Set paid to true if total price is 0 or if it's OtherTextiles from offices
          ...(totalPrice === 0 || isOtherTextilesFromOffices ? { paid: true } : {}),
        };

        const docRef = doc(db, "orders", customOrderId);

        if (attributes?.includes("Brands")) {
          //adding extra information just for Brands page

          const retailId = localStorage.getItem("retailConnections");
          let retailName = "";

          if (retailId) {
            try {
              const retailDoc = await getDoc(doc(db, "retail", retailId));
              if (retailDoc.exists()) {
                retailName = retailDoc.data().name;
              }
            } catch (error) {
              console.error("Error fetching retail name:", error);
            }
          }

          const brandOrderData = {
            ...orderData,
            paid: true,
            checkoutAttributes: attributes,
            ...(retailPrice && { retailPrice }), // Default value for retailPrice
            ...(claimStatus && {
              claim: {
                note: claimNote,
                ...(retailPrice && { retailPrice }), // Default value for retailPrice
                ...(article && { article }),
                ...(model && { model }),
              },
            }),
            brand: selectedRetailName, // Use the selected retail name
            retailId: selectedRetailId, // Add the selected retail ID
            ...(usePostalService || usePostalServiceDelivery || usePostalServiceRetrieval
              ? {
                  usePostalService: usePostalService,
                  ...(usePostalServiceDelivery && !usePostalService
                    ? { usePostalServiceDelivery: true }
                    : {}),
                  ...(usePostalServiceRetrieval && !usePostalService
                    ? { usePostalServiceRetrieval: true }
                    : {}),
                }
              : {}),

            ...(customSmsSender && {
              flags: {
                customSmsSender: true,
              },
            }),
          };
          await setDoc(docRef, brandOrderData);

          // For brand orders, we'll still track here since there's no redirect
          if (window.gtag) {
            const items = orderInfoWithPredictor.map((item, index) => ({
              item_id: `${customOrderId}_${index}`,
              item_name: item.item,
              item_category: item.fix_type,
              price: item.price_nok,
              quantity: 1,
            }));

            const analyticsData = {
              transaction_id: customOrderId,
              value: totalPrice,
              currency: "NOK",
              tax: 0,
              shipping: usePostalService
                ? PRICE_FOR_POSTAL_ROUND_TRIP
                : usePostalServiceDelivery || usePostalServiceRetrieval
                  ? usePostalServiceDelivery && usePostalServiceRetrieval
                    ? PRICE_FOR_POSTAL_ROUND_TRIP
                    : PRICE_FOR_POSTAL_ROUND_TRIP / 2
                  : 0,
              items: items,
              order_id: customOrderId,
              customer_email: userEmail,
              delivery_method: deliveryMethod,
              retrieval_method: retrieveMethod,
              order_date: new Date().toISOString(),
              customer_name: fullName,
              customer_address: address,
              customer_phone: phoneNumber,
              has_discount: discount ? true : false,
              discount_name: discount ? discount.name : null,
              discount_value: discount ? discount.value : null,
              marketing_opt_in: receiveMarketing,
              order_source: window.location.href,
              is_brand_order: true,
              is_claim: claimStatus || false,
              postal_service: usePostalService,
              postal_service_delivery: usePostalServiceDelivery && !usePostalService,
              postal_service_retrieval: usePostalServiceRetrieval && !usePostalService,
              pathname: window.location.pathname,
              attributes: attributes?.length ? attributes.join(",") : "B2C",
            };
            console.log("XXXXX2analyticsData", analyticsData);

            window.gtag("event", "completed_order", analyticsData);
            window.dataLayer.push({
              event: "completed_order",
              ecommerce: analyticsData,
            });
          }
        } else {
          await setDoc(docRef, orderData);
          const orderUrl = `https://fiksefirstapp.web.app/orders/b2c/${customOrderId}#scanned`;
          const qrCodeDataUrl = await QRCode.toDataURL(orderUrl);
          const qrCodeStorageRef = ref(storage, `qrcodes/${customOrderId}.png`);
          await uploadString(qrCodeStorageRef, qrCodeDataUrl, "data_url");
          const qrCodeUrl = await getDownloadURL(qrCodeStorageRef);
          await updateDoc(docRef, { qrCodeUrl });

          // Only proceed to payment if total price is not 0 and it's not OtherTextiles from offices
          if (totalPrice > 0 && !isOtherTextilesFromOffices) {
            // REMOVED: GA tracking code from here
            await handlePaymentMethodClick("vipps", customOrderId);
          } else {
            // For zero-price orders or OtherTextiles from offices, we'll still track here since there's no redirect
            if (window.gtag) {
              const items = orderInfoWithPredictor.map((item, index) => ({
                item_id: `${customOrderId}_${index}`,
                item_name: item.item,
                item_category: item.fix_type,
                price: item.price_nok,
                quantity: 1,
              }));

              const analyticsData = {
                transaction_id: customOrderId,
                value: totalPrice,
                currency: "NOK",
                tax: 0,
                shipping: usePostalService
                  ? PRICE_FOR_POSTAL_ROUND_TRIP
                  : usePostalServiceDelivery || usePostalServiceRetrieval
                    ? usePostalServiceDelivery && usePostalServiceRetrieval
                      ? PRICE_FOR_POSTAL_ROUND_TRIP
                      : PRICE_FOR_POSTAL_ROUND_TRIP / 2
                    : 0,
                items: items,
                order_id: customOrderId,
                customer_email: userEmail,
                delivery_method: deliveryMethod,
                retrieval_method: retrieveMethod,
                order_date: new Date().toISOString(),
                customer_name: fullName,
                customer_address: address,
                customer_phone: phoneNumber,
                has_discount: discount ? true : false,
                discount_name: discount ? discount.name : null,
                discount_value: discount ? discount.value : null,
                marketing_opt_in: receiveMarketing,
                order_source: window.location.href,
                pathname: window.location.pathname,
                attributes: attributes?.length ? attributes.join(",") : "B2C",

                is_brand_order: false,
                is_claim: false,
                postal_service: usePostalService,
                postal_service_delivery: usePostalServiceDelivery && !usePostalService,
                postal_service_retrieval: usePostalServiceRetrieval && !usePostalService,
              };

              console.log("XXXXX3analyticsData", analyticsData);

              window.gtag("event", "completed_order", analyticsData);
              window.dataLayer.push({
                event: "completed_order",
                ecommerce: analyticsData,
              });
            }
          }

          localStorage.setItem("activeOrder", `b2c/${customOrderId}`);
        }

        navigate(`/order/${customOrderId}`);
        onClose();
      } catch (error) {
        console.error("Error saving order: ", error);
        alert("An error occurred while saving the order.");
      } finally {
        setUploading(false);
      }
    }
  };

  const handlePostalServiceButton = () => {
    const newPostalServiceState = !usePostalService;
    setUsePostalService(newPostalServiceState);

    if (newPostalServiceState) {
      // When postal service is selected, set both methods to "postforsendelse"
      setDeliveryMethod("postforsendelse");
      setRetrieveMethod("postforsendelse");
      setUsePostalServiceDelivery(true);
      setUsePostalServiceRetrieval(true);
    } else {
      // When postal service is unselected, reset to first location in the list
      setDeliveryMethod(dropoffLocations[0]);
      setRetrieveMethod(dropoffLocations[0]);
      setUsePostalServiceDelivery(false);
      setUsePostalServiceRetrieval(false);
    }
  };

  const handlePostalServiceDelivery = () => {
    const newState = !usePostalServiceDelivery;
    setUsePostalServiceDelivery(newState);

    if (newState) {
      setDeliveryMethod("postforsendelse");
      // If both are now selected, set the full postal service flag
      if (usePostalServiceRetrieval) {
        setUsePostalService(true);
      } else {
        setUsePostalService(false);
      }
    } else {
      setDeliveryMethod(dropoffLocations[0]);
      setUsePostalService(false);
    }
  };

  const handlePostalServiceRetrieval = () => {
    const newState = !usePostalServiceRetrieval;
    setUsePostalServiceRetrieval(newState);

    if (newState) {
      setRetrieveMethod("postforsendelse");
      // If both are now selected, set the full postal service flag
      if (usePostalServiceDelivery) {
        setUsePostalService(true);
      } else {
        setUsePostalService(false);
      }
    } else {
      setRetrieveMethod(dropoffLocations[0]);
      setUsePostalService(false);
    }
  };

  return (
    <div className="flex h-full items-center justify-center bg-black bg-opacity-50">
      <div className="flex max-h-[calc(100vh)] w-full max-w-lg flex-col overflow-auto bg-white shadow-xl">
        <div className="z-10 flex items-center justify-between border-b border-black bg-white">
          <div className="flex items-center">
            <button
              type="button"
              className="p-4 text-2xl text-gray-500"
              onClick={onBackToCart}
            >
              &larr;
            </button>
            <h1 className="text-2xl">{t("checkout.orderTitle")}</h1>
          </div>
          <button type="button" className="p-4 text-2xl text-gray-500" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="border-b border-black">
            <label className="mt-2 block px-4 pt-4 text-sm font-medium text-gray-700">
              {!attributes?.includes("Brands")
                ? t("checkout.updatePrompt")
                : "Kundens informasjon (Valgfritt)"}
            </label>

            <label className="block px-4 pb-4 pt-1 text-sm font-medium text-gray-400">
              {t("checkout.forenNumber")}
            </label>

            <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} />
          </div>
          {phoneNumber.length >= 8 && (
            <>
              <div className="border-b border-black">
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder={t("checkout.namePlaceholder")}
                  className="text-md block w-full border-b border-black bg-[#f7f6eb] p-4"
                />
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder={t("checkout.address")}
                  className="text-md block w-full border-black bg-[#f7f6eb] p-4"
                />
              </div>
            </>
          )}
          {/*localStorage.getItem("retailConnections")?.includes(",") checks if there are mutiple retail connections */}
          {/*attributes?.includes("Brands") && localStorage.getItem("retailConnections")?.includes(",")
          This is the start of enabling emails with mutiple retail connection from here have a dropdown menue that can swtich between the brands*/}

          {emailPassedTrough && (
            <div className="border-b border-black bg-fikseDisabled p-4">
              <p className="text-gray-800">{emailPassedTrough}</p>
            </div>
          )}

          {/* Move retail selection here, before dropoff section */}
          {attributes?.includes("Brands") && retailConnections.length > 1 && (
            <div className="border-b border-black">
              <label className="block px-4 pt-4 text-sm font-medium text-gray-700">
                Velg merke
              </label>
              <div className="relative">
                <select
                  value={selectedRetailId}
                  onChange={async (e) => {
                    const newRetailId = e.target.value;
                    setSelectedRetailId(newRetailId);
                  }}
                  className="text-md block w-full appearance-none rounded-none border-t border-black bg-[#f7f6eb] p-4"
                >
                  {retailConnections.map((retailId) => (
                    <option key={retailId} value={retailId}>
                      {retailNames[retailId] || retailId}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
                </div>
              </div>
            </div>
          )}

          {/* DELIVERY COMPONENT - TODO: DECOMPOSE*/}
          <>
            <div className="pb-4">
              <label className="mt-8 block p-4 text-sm font-medium text-gray-700">
                {t("checkout.deliverySectionTitle")}{" "}
              </label>
              <p className="border-b border-black p-4 pb-8 text-sm text-gray-500">
                {t("checkout.deliveryDescription")}
              </p>
              <div className="relative">
                <select
                  value={deliveryMethod}
                  onChange={(e) => {
                    const value = e.target.value;
                    setDeliveryMethod(value);
                    // Only synchronize if the other method is a drop-off location
                    if (
                      dropoffLocations.includes(value) &&
                      dropoffLocations.includes(retrieveMethod)
                    ) {
                      setRetrieveMethod(value);
                      setDeliveryMethod(value);
                    }
                    setUsePostalService(false);
                    setUsePostalServiceDelivery(false);
                  }}
                  className={`text-md block w-full appearance-none rounded-none border-b border-black p-4 ${
                    !usePostalService && !usePostalServiceDelivery
                      ? "bg-[#8cd7ff]"
                      : "bg-white"
                  }`}
                >
                  {dropoffLocations.map((location, index) => (
                    <option className="p-4" key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  {dropoffLocations.length > 1 && (
                    <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
                  )}{" "}
                </div>
              </div>
              {/*POSTAL SERVICE BUTTON*/}
              {(attributes?.includes("enablePostalService") ||
                window.location.href.includes("/consumer?ellaandil")) && (
                <>
                  <button
                    type="button"
                    onClick={handlePostalServiceDelivery}
                    className={`text-md block w-full rounded-none border-b border-black p-4 text-left ${
                      usePostalServiceDelivery ? "bg-[#8cd7ff]" : "bg-white"
                    }`}
                  >
                    Levere med posten
                  </button>
                </>
              )}
            </div>
            <div className="">
              <label className="mt-8 block p-4 text-sm font-medium text-gray-700">
                {t("checkout.retrievalPrompt")}
              </label>
              <div className="relative">
                <select
                  value={retrieveMethod}
                  onChange={(e) => {
                    const value = e.target.value;
                    setRetrieveMethod(value);
                    // Only synchronize if the other method is a drop-off location
                    if (
                      dropoffLocations.includes(value) &&
                      dropoffLocations.includes(deliveryMethod)
                    ) {
                      setDeliveryMethod(value);
                    }
                    // Reset postal service retrieval when selecting from dropdown
                    setUsePostalServiceRetrieval(false);
                    if (usePostalServiceDelivery) {
                      setUsePostalService(false);
                    }
                  }}
                  className={`text-md mt-4 block w-full appearance-none rounded-none border-b border-t border-black p-4 ${
                    !usePostalService && !usePostalServiceRetrieval
                      ? "bg-[#8cd7ff]"
                      : "bg-white"
                  }`}
                >
                  {dropoffLocations.map((location, index) => (
                    <option className="p-4" key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  {dropoffLocations.length > 1 && (
                    <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
                  )}
                </div>
              </div>

              {(attributes?.includes("enablePostalService") ||
                window.location.href.includes("/consumer?ellaandil")) && (
                <button
                  type="button"
                  onClick={handlePostalServiceRetrieval}
                  className={`text-md block w-full rounded-none border-b border-black p-4 text-left ${
                    usePostalServiceRetrieval ? "bg-[#8cd7ff]" : "bg-white"
                  }`}
                >
                  Hente med posten
                </button>
              )}
            </div>
          </>

          {attributes?.includes("Brands") && (
            <div
              className={`text-md black mt-16 flex cursor-pointer items-center justify-between border-t border-black p-4 ${claimStatus ? `border-b border-b-gray-300` : `border-b`} `}
              onClick={() => setClaimStatus(!claimStatus)}
            >
              <label htmlFor="acceptTerms" className="text-md mr-4 w-2/3 text-gray-900">
                {t("checkout.claimLabel")}
              </label>
              <div className="relative h-12 w-12">
                <input
                  id="acceptTerms"
                  type="checkbox"
                  checked={claimStatus}
                  readOnly
                  className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                />
                <div
                  className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                    claimStatus ? "bg-[#8cd7ff]" : ""
                  }`}
                >
                  {claimStatus && <CheckIcon className="h-5 w-5" />}
                </div>
              </div>
            </div>
          )}
          {claimStatus && (
            <>
              <input
                type="number"
                className="w-full border-y border-black bg-fikseDisabled p-4 text-left"
                placeholder={t("checkout.purchasePriceLabel")}
                value={retailPrice}
                onChange={(e) => setRetailPrice(e.target.value)}
              />
              <div className="flex flex-col border-b border-black">
                <input
                  type="text"
                  className="w-full border-b border-black p-4 text-left"
                  placeholder={t("checkout.articleNumberLabel")}
                  value={article}
                  onChange={(e) => setArticle(e.target.value)}
                />
                <input
                  type="text"
                  className="w-full p-4 text-left"
                  placeholder={t("checkout.modelNumberLabel")}
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                />
              </div>
              <textarea
                className="max-h-40 min-h-5 w-full resize-y overflow-y-auto border-b border-black p-4 text-left transition-[height] duration-300 ease-in-out"
                placeholder={t("checkout.claimNoteLabel")}
                value={claimNote}
                onChange={(e) => setClaimNote(e.target.value)}
              />
            </>
          )}

          {!attributes?.includes("Brands") && (
            <>
              <div className="border-b border-black">
                <h2 className="mt-8 p-4 text-lg font-medium text-gray-900">
                  {t("checkout.termsAndConditions")}{" "}
                </h2>
                <div
                  className="text-md flex cursor-pointer items-center justify-between border-t border-black p-4"
                  onClick={() => setAcceptTerms(!acceptTerms)}
                >
                  <label
                    htmlFor="acceptTerms"
                    className="text-md mr-4 w-2/3 text-gray-900"
                  >
                    {t("checkout.acceptTerms1")}
                    <a href="/kjopsvilkar" className="text-[#5c6ef8] underline">
                      {t("checkout.acceptTerms2")}
                    </a>
                    {t("checkout.acceptTerms3")}
                  </label>
                  <div className="relative h-12 w-12">
                    <input
                      id="acceptTerms"
                      type="checkbox"
                      checked={acceptTerms}
                      readOnly
                      className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                    />
                    <div
                      className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                        acceptTerms ? "bg-[#8cd7ff]" : ""
                      }`}
                    >
                      {acceptTerms && <CheckIcon className="h-5 w-5" />}
                    </div>
                  </div>
                </div>

                <div
                  className="text-md flex cursor-pointer items-center justify-between border-t border-black p-4"
                  onClick={() => setReceiveMarketing(!receiveMarketing)}
                >
                  <label
                    htmlFor="receiveMarketing"
                    className="text-md mr-4 w-2/3 text-gray-900"
                  >
                    {t("checkout.marketingOptIn")}
                  </label>
                  <div className="relative h-12 w-12">
                    <input
                      id="receiveMarketing"
                      type="checkbox"
                      checked={receiveMarketing}
                      readOnly
                      className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                    />
                    <div
                      className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                        receiveMarketing ? "bg-[#8cd7ff]" : ""
                      }`}
                    >
                      {receiveMarketing && <CheckIcon className="h-5 w-5" />}
                    </div>
                  </div>
                </div>

                {receiveMarketing && (
                  <div className="border-t border-black">
                    <input
                      type="email"
                      value={marketingEmail}
                      onChange={(e) => setMarketingEmail(e.target.value)}
                      placeholder="What is your email?"
                      className="text-md block w-full border-b border-black bg-[#f7f6eb] p-4"
                    />
                  </div>
                )}
              </div>
            </>
          )}

          <div className="border-b border-black pb-4">
            <h2 className="mt-4 p-4 text-lg font-medium text-gray-900">
              {t("checkout.orderTitle")}
            </h2>
            {garments.map((item, index) => (
              <div key={index} className="mt-2 flex items-center justify-between p-4">
                <span>
                  {item.item} - {item.problem} ({item.fix_type})
                </span>
                <span>{item.price_nok} kr</span>
              </div>
            ))}

            {usePostalService && (
              <div className="mt-2 flex items-center justify-between p-4">
                <span>Frakt tur, retur</span>
                <span>{PRICE_FOR_POSTAL_ROUND_TRIP} kr</span>
              </div>
            )}
            {!usePostalService && usePostalServiceDelivery && (
              <div className="mt-2 flex items-center justify-between p-4">
                <span>Frakt levering</span>
                <span>{PRICE_FOR_POSTAL_ROUND_TRIP / 2} kr</span>
              </div>
            )}
            {!usePostalService && usePostalServiceRetrieval && (
              <div className="mt-2 flex items-center justify-between p-4">
                <span>Frakt retur</span>
                <span>{PRICE_FOR_POSTAL_ROUND_TRIP / 2} kr</span>
              </div>
            )}
            {discount && (
              <div className="mt-2 flex items-center justify-between bg-green-200">
                <span className="p-4"> {discount.name} aktivert</span>
                <span className="p-4">
                  -{" "}
                  {discount.value <= 1
                    ? discount.value * 100 + "%"
                    : discount.value + " kr"}
                </span>
              </div>
            )}
            <div className="mt-2 flex items-center justify-between">
              <span className="p-4"> {t("checkout.total")}</span>
              <span className="p-4">{calculateTotalPrice(garments)} kr</span>
            </div>
          </div>

          <div className="flex border-b border-black">
            <input
              type="text"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              placeholder="Har du en rabattkode?"
              className="flex-grow p-4 sm:text-sm"
            />
            <button
              onClick={checkDiscountValidity}
              className="border-grey-500 flex-none whitespace-nowrap border-l bg-fikseBeige px-4"
            >
              Bruk kode
            </button>
          </div>
          {discountStatus && discountStatus != "Discount applied." && (
            <p
              className={`bg-pink-200 p-4 text-sm ${
                discountStatus.startsWith("Discount applied")
                  ? "bg-green-200"
                  : "text-black"
              }`}
            >
              {discountStatus}
            </p>
          )}

          <div className="my-2 mt-6 flex justify-center pb-4">
            <FikseCatLady className="h-auto w-24" />
          </div>
          <div className="border-black px-4 pb-4">
            <p className="text-center text-sm text-black">{t("checkout.deliveryTime")}</p>
          </div>
          <button
            type="button"
            onClick={handlePaymentClick}
            className={`mb-12 mt-auto w-full rounded-none border-b border-t border-black py-4 pl-4 text-left text-lg ${
              isFormValid
                ? "bg-[#D3FF8C] text-black"
                : "cursor-not-allowed bg-[#e0ffd0] text-gray-500"
            }`}
            disabled={!isFormValid || uploading}
          >
            {uploading
              ? "Uploading..."
              : !attributes?.includes("Brands")
                ? t("checkout.proceedToPayment")
                : "Send inn bestilling"}
          </button>
        </div>

        <div className="my-2 text-center text-xs">
          <span>fikse.co ©</span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutB2C;
