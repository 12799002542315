import React from "react";

import { Order } from "@type/Order";
import { useTranslation } from "react-i18next";

interface OrderCardProps {
  order: Order;
  onChange: (index: number) => void;
  activeTab: number;
}

const OrderTabs: React.FC<OrderCardProps> = ({ order, onChange, activeTab }) => {
  const { t } = useTranslation();

  if (!order || order.orderInfo.length === 1) return <></>;

  return (
    <div className="mx-5 mb-5">
      <div className="flex flex-wrap gap-0">
        <div
          key={-1}
          className={`flex min-h-14 cursor-pointer items-center border border-black hover:bg-fikseBeigeHover ${
            activeTab === -1 ? "" : "bg-fikseBeige"
          }`}
          onClick={() => {
            onChange(-1);
          }}
        >
          <button className="h-full px-4 py-2">{t("all")}</button>
        </div>
        {order.orderInfo.map((item, index) => (
          <div
            key={index}
            className={`flex min-h-14 cursor-pointer items-center border border-black hover:bg-fikseBeigeHover ${
              activeTab === index ? "" : "bg-fikseBeige"
            }`}
            onClick={() => {
              onChange(index);
            }}
          >
            <button className="h-full px-4 py-2 text-left">
              {index + 1}: {item.item}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderTabs;
