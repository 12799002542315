import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

import { db } from "../../firebase";
import { ReactComponent as PlusIcon } from "../../images/icons-fikse/add.svg";
import { ReactComponent as BackIcon } from "../../images/icons-fikse/back.svg";
import { ReactComponent as CameraIcon } from "../../images/icons-fikse/camera.svg";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import { ReactComponent as PhotoIcon } from "../../images/icons-fikse/media.svg";
import CameraCapture from "../camera/CameraCapture";
import CheckoutB2C from "./CheckoutB2C";
import CheckoutWorkwear from "./CheckoutWorkwear";

// Import translation hook

interface ServiceCategory {
  id: string;
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  work: string;
  placeholder: string;
  carbonFootprint?: number;
}

interface OrderModalProps {
  open: boolean;
  onClose: () => void;
  discount?: string;
  emailPassedTrough?: string;
  customDropOff?: string[];
  attributes?: string[];
}

type RecognitionResult = {
  id: string;
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  work: string;
  placeholder: string;
  imageUrl: string;
  customerComment: string;
  count: number;
  eventPredictor: string[];
  analytics?: {
    carbonFootprint?: number;
  };
};

export const fallbackImageUrl =
  "https://t3.ftcdn.net/jpg/03/76/74/78/360_F_376747823_L8il80K6c2CM1lnPYJhhJZQNl6ynX1yj.jpg";

const OrderModal: React.FC<OrderModalProps> = ({
  open,
  onClose,
  discount,
  emailPassedTrough,
  customDropOff,
  attributes,
}) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [reqResult, setReqResult] = useState<RecognitionResult | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [garments, setGarments] = useState<RecognitionResult[]>([]);
  const [contactInfo, setContactInfo] = useState({
    department: localStorage.getItem("department") || "",
    name:
      localStorage
        .getItem("uid")
        ?.split("@")[0]
        .split(".")
        .map((part) => part.toUpperCase())
        .join(" ") || "",
    phone: localStorage.getItem("phone") || "",
    address: localStorage.getItem("address") || "",
  });
  const [showManualCategories, setShowManualCategories] = useState(false);
  const [manualInput, setManualInput] = useState<RecognitionResult>({
    id: "",
    item: "",
    problem: "",
    fix_type: "",
    description: "",
    price_nok: 0,
    work: "",
    placeholder: "",
    imageUrl: fallbackImageUrl,
    customerComment: "",
    count: 1,
    eventPredictor: [],
  });
  const [selectedProblem, setSelectedProblem] = useState<string | null>(null);
  const [selectedItem, setselectedItem] = useState<string | null>(null);
  const [selectedWork, setSelectedWork] = useState<string | null>(null);
  const [selectedFixType, setSelectedFixType] = useState<string | null>(null);
  const [categories, setCategories] = useState<ServiceCategory[]>([]);
  const [showCameraCapture, setShowCameraCapture] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCartDetails, setShowCartDetails] = useState<boolean[]>([]);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const categoryMapping: { [key: string]: string } = {
    "EN-Clothes-0924": t("category.garment"),
    "NO-Clothes-0924": t("category.garment"),
    "EN-Shoes-0924": t("category.shoes"),
    "NO-Shoes-0924": t("category.shoes"),
    "EN-Accessories-0924": t("category.item"),
    "NO-Accessories-0924": t("category.item"),
    "EN-Bunad-0924": t("category.bunad"),
    "NO-Bunad-0924": t("category.bunad"),
    "EN-Workwear/Ahlsell-0924": t("category.garment"),
    "NO-Workwear/Ahlsell-0924": t("category.garment"),
    "EN-OtherTextiles-1124": t("category.item"),
    "NO-OtherTextiles-1124": t("category.item"),
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const q = query(
        collection(db, "serviceCategories"),
        where("name", "==", localStorage.getItem("serviceCategory"))
      );
      const querySnapshot = await getDocs(q);
      const categoriesData: ServiceCategory[] = [];
      querySnapshot.forEach((doc) => {
        categoriesData.push(...doc.data().categories);
      });
      console.log("Categories fetched:", categoriesData); // Add this line to debug
      setCategories(categoriesData);
    };
    fetchCategories();
  }, [localStorage.getItem("serviceCategory")]);

  const callImageRecognition = async (imageUrl: string) => {
    console.log(imageUrl);
    const functions = getFunctions(undefined, "europe-west1");
    const imageRecognition = httpsCallable<
      { imageUrl: string; categoryName: string },
      { result: string }
    >(functions, "imageRecognition0924");

    try {
      const result = await imageRecognition({
        imageUrl,
        categoryName: localStorage.getItem("serviceCategory") || "EN-Clothes-0924",
      });
      console.log("Result from image recognition:", result.data);
      let parsedResult: RecognitionResult;

      try {
        // Forsøk å parse JSON-resultatet direkte
        parsedResult = JSON.parse(result.data.result);
      } catch (e) {
        // Hvis JSON-parsingen feiler, rens resultatet for uønskede markører
        const cleanedResult = result.data.result
          .replace(/```json/g, "")
          .replace(/```/g, "");
        parsedResult = JSON.parse(cleanedResult);
      }

      // Sjekk om alle nødvendige verdier finnes i det parsete resultatet
      if (
        parsedResult.id &&
        parsedResult.item &&
        parsedResult.problem &&
        parsedResult.fix_type &&
        parsedResult.description &&
        parsedResult.price_nok &&
        parsedResult.work
      ) {
        // Legg til imageUrl i resultatet
        parsedResult.imageUrl = imageUrl;
        setReqResult(parsedResult);
        console.log(parsedResult);
        setErrorMessage(null);
      } else {
        throw new Error(t("errors.unexpectedResponse"));
      }
    } catch (error: any) {
      console.error(t("errors.callError"), error);
      setErrorMessage(t("errors.unexpectedResult"));
    } finally {
      setUploading(false);
      setLoading(false); // Set loading to false after the process is complete
      setShowCameraCapture(false); // Close the camera capture component
    }
  };

  const handleCameraCaptureClose = (capturedImage?: string) => {
    if (capturedImage) {
      setUploading(true);
      setLoading(true);
      setImageUrl(capturedImage);
      callImageRecognition(capturedImage);
    } else {
      setShowCameraCapture(false);
    }
  };

  const handleAddGarment = () => {
    if (reqResult) {
      const trimmedComment = reqResult.customerComment.trim();

      if (trimmedComment !== "") {
        setGarments((prevGarments) => {
          const existingGarment = prevGarments.find(
            (item) =>
              item.item === reqResult.item &&
              item.problem === reqResult.problem &&
              item.fix_type === reqResult.fix_type &&
              item.price_nok === reqResult.price_nok &&
              item.customerComment === trimmedComment
          );

          // Find the carbon footprint value from the selected category
          const category = categories.find((cat) => cat.id === reqResult.id);

          // Create analytics object with carbon footprint if available
          const analytics = {
            carbonFootprint: category?.carbonFootprint || 0,
          };

          if (existingGarment) {
            // Oppdater count for eksisterende garment
            return prevGarments.map((item) =>
              item === existingGarment
                ? {
                    ...item,
                    count: item.count + 1,
                    analytics: {
                      ...item.analytics,
                      carbonFootprint:
                        (item.analytics?.carbonFootprint || 0) +
                        (analytics.carbonFootprint || 0),
                    },
                  }
                : item
            );
          } else {
            // Legg til nytt garment
            return [
              ...prevGarments,
              {
                ...reqResult,
                customerComment: trimmedComment,
                count: 1,
                analytics: analytics,
              },
            ];
          }
        });
        setReqResult(null);
        setShowCart(true);
        setShowCartDetails((prev) => [...prev, false]);
        setErrorMessage(null);
      } else {
        setErrorMessage(null);
        setReqResult({
          ...reqResult,
          customerComment: t("errors.requiredField"),
        });
      }
    }
  };

  const handleAddManualGarment = () => {
    if (
      manualInput.id &&
      manualInput.item &&
      manualInput.problem &&
      manualInput.fix_type &&
      manualInput.description &&
      manualInput.work &&
      manualInput.price_nok > 0 &&
      manualInput.customerComment.trim() !== ""
    ) {
      setGarments((prevGarments) => {
        const existingGarment = prevGarments.find(
          (item) =>
            item.item === manualInput.item &&
            item.problem === manualInput.problem &&
            item.fix_type === manualInput.fix_type &&
            item.price_nok === manualInput.price_nok &&
            item.customerComment === manualInput.customerComment
        );

        // Find the carbon footprint value from the selected category
        const category = categories.find((cat) => cat.id === manualInput.id);

        // Create analytics object with carbon footprint if available
        const analytics = {
          carbonFootprint: category?.carbonFootprint || 0,
        };

        // Multiple by count
        const totalCarbonFootprint = analytics.carbonFootprint * tempCount;

        if (existingGarment) {
          return prevGarments.map((item) =>
            item === existingGarment
              ? {
                  ...item,
                  count: item.count + tempCount,
                  price_nok: finalPriceCalculation(
                    ((item.price_nok * (100 / (100 - parseInt(discount ?? "0", 10)))) /
                      item.count) *
                      (item.count + tempCount)
                  ),
                  analytics: {
                    ...item.analytics,
                    carbonFootprint:
                      (item.analytics?.carbonFootprint || 0) + totalCarbonFootprint,
                  },
                }
              : item
          );
        } else {
          return [
            ...prevGarments,
            {
              ...manualInput,
              customerComment: manualInput.customerComment.trim(),
              count: tempCount,
              price_nok: finalPriceCalculation(manualInput.price_nok),
              description: manualInput.description,
              analytics: {
                carbonFootprint: totalCarbonFootprint,
              },
            },
          ];
        }
      });

      setManualInput({
        id: "",
        item: "",
        problem: "",
        fix_type: "",
        description: "",
        price_nok: 0,
        work: "",
        placeholder: "",
        imageUrl: fallbackImageUrl,
        customerComment: "",
        count: 1,
        eventPredictor: [],
      });

      setTempCount(1); // Tilbakestill tempCount etter at varen er lagt til

      setReqResult(null);
      setShowManualCategories(false);
      setShowCart(true);
      resetManualSelection();
      setShowCartDetails((prev) => [...prev, false]);
      setErrorMessage(null);
    } else {
      setErrorMessage("Alle felt må være utfylt for å legge til i handlekurven.");
    }
  };

  const handleDuplicateGarment = (index: number) => {
    setGarments((prevGarments) =>
      prevGarments.map((garment, i) => {
        if (i === index) {
          // Calculate the original base price per item (before discount)
          const basePerItem = Math.round(
            (garment.price_nok * (100 / (100 - parseInt(discount ?? "0", 10)))) /
              garment.count
          );
          const newCount = garment.count + 1;

          // Calculate new total base price
          const newBasePrice = basePerItem * newCount;

          // Apply discount to the new total
          const finalPrice = finalPriceCalculation(newBasePrice);

          return {
            ...garment,
            count: newCount,
            price_nok: finalPrice,
          };
        }
        return garment;
      })
    );
  };

  const handleRemoveGarment = (index: number) => {
    const updatedGarments = garments.filter((_, i) => i !== index);
    const updatedShowCartDetails = showCartDetails.filter((_, i) => i !== index);
    setGarments(updatedGarments);
    setShowCartDetails(updatedShowCartDetails);
    if (updatedGarments.length === 0) {
      setReqResult(null);
    }
    setShowCart(false);
  };

  const sendOrderConfirmationEmail = async () => {
    setUploading(true);
    const functions = getFunctions(undefined, "europe-west1");
    const sendEmail = httpsCallable<
      {
        email: string;
        department: string;
        orderInfo: RecognitionResult[];
        contactInfo: any;
      },
      void
    >(functions, "sendOrderConfirmationEmail");

    const email = localStorage.getItem("uid");

    if (!email) {
      setErrorMessage(t("errors.emailMissing"));
      return;
    }

    try {
      await sendEmail({
        email,
        department: contactInfo.department,
        orderInfo: garments,
        contactInfo,
      });
      alert(t("orderSent"));
      setUploading(false);
      onClose();
    } catch (error: any) {
      setErrorMessage(t("errors.orderError") + error.message);
    }
  };
  // Hent unike items fra kategorier
  const uniqueItems = Array.from(new Set(categories.map((c) => c.item)));

  // Hent problemer knyttet til valgt item
  const problemsForSelectedItem = categories
    .filter((category) => category.item === selectedItem)
    .map((category) => category.problem)
    .filter((problem, index, self) => self.indexOf(problem) === index);

  const uniqueProblems = Array.from(new Set(categories.map((c) => c.problem)));
  const typesForSelectedProblem = categories
    .filter((category) => category.problem === selectedProblem)
    .map((category) => category.item)
    .filter((value, index, self) => self.indexOf(value) === index);

  const worksForSelectedProblemAndItem = categories
    .filter(
      (category) =>
        category.item === selectedItem &&
        category.problem === selectedProblem &&
        category.work // sørg for at work finnes
    )
    .map((category) => category.work)
    .filter((value, index, self) => value && self.indexOf(value) === index);

  // Hent fix types for valgt problem og work med description
  const fixTypesForSelectedProblemAndWork = categories
    .filter(
      (category) =>
        category.item === selectedItem &&
        category.problem === selectedProblem &&
        category.work === selectedWork
    )
    .map((category) => ({
      fix_type: category.fix_type,
      description: category.description,
      price_nok: category.price_nok,
    }))
    .filter(
      (fixTypeObj, index, self) =>
        index === self.findIndex((t) => t.fix_type === fixTypeObj.fix_type)
    );

  const resetManualSelection = () => {
    setSelectedProblem(null);
    setselectedItem(null);
    setSelectedWork(null);
    setSelectedFixType(null);
  };

  useEffect(() => {
    if (uniqueProblems.length === 1) {
      setSelectedProblem(uniqueProblems[0]);
    }
  }, [uniqueProblems, selectedProblem]);

  useEffect(() => {
    if (typesForSelectedProblem.length === 1) {
      setselectedItem(typesForSelectedProblem[0]);
    }
  }, [typesForSelectedProblem]);

  /* TROR IKKE VI TRENGER DENNE KODEN?

  useEffect(() => {
    if (worksForSelectedProblemAndItem.length === 1) {
      const work = worksForSelectedProblemAndItem[0];
      const category = categories.find(
        (c) =>
          c.problem === selectedProblem &&
          c.fix_type === selectedFixType &&
          c.work === work &&
          c.item == selectedItem
      );


      console.log(worksForSelectedProblemAndItem)



      // Filtrering basert på FixType etter at Problem og Work er valgt
      const finalFilteredCategory = categories.find(
        (category) =>
          category.item === selectedItem &&
          category.problem === selectedProblem &&
          (category.work === selectedWork || !category.work) && // Hvis work er tom, går den videre
          category.fix_type === selectedFixType
      );

      // Hvis en kategori finnes, sett opp input-verdiene basert på resultatet
      if (finalFilteredCategory) {
        setManualInput({
          ...manualInput,
          id: finalFilteredCategory.id,
          item: selectedItem!,
          problem: selectedProblem!,
          work: selectedWork!, // Hvis work ikke er valgt, settes den til tom streng
          fix_type: selectedFixType!,
          description: finalFilteredCategory.description,
          price_nok: finalPriceCalculation(finalFilteredCategory.price_nok),
          placeholder: finalFilteredCategory.placeholder,
        });
      } else {
        console.warn("No matching category found.");
      }

      if (category) {
        setManualInput((prev) => ({
          ...prev,
          id: category.id,
          item: selectedItem!,
          problem: selectedProblem!,
          fix_type: selectedFixType!,
          description: category.description,
          price_nok: finalPriceCalculation(category.price_nok),
          work: selectedWork!,
          placeholder: category.placeholder, // Set placeholder from category
          customerComment: prev.customerComment,
        }));
      } else {
        console.warn("No matching category found!"); // Log if no category is found
      }
    }
  }, [
    worksForSelectedProblemAndItem,
    selectedProblem,
    selectedItem,
    selectedFixType,
    categories,
  ]);

  */

  useEffect(() => {
    if (selectedProblem && selectedItem) {
      if (
        worksForSelectedProblemAndItem.length === 1 &&
        worksForSelectedProblemAndItem[0] === ""
      ) {
        // Hvis det ikke finnes noen work-verdier, setter vi selectedWork til tom streng
        setSelectedWork("N/A");
      } else if (worksForSelectedProblemAndItem.length === 1) {
        // Hvis det er bare én work-verdi, sett den som valgt
        setSelectedWork(worksForSelectedProblemAndItem[0]);
      }
    }
  }, [worksForSelectedProblemAndItem, selectedProblem, selectedItem]);

  useEffect(() => {
    if (selectedProblem && selectedItem && selectedWork && selectedFixType) {
      // Fortsett med filtreringen bare hvis alle verdiene er satt
      const category = categories.find(
        (c) =>
          c.problem === selectedProblem &&
          c.item === selectedItem &&
          c.work === selectedWork &&
          c.fix_type === selectedFixType
      );

      if (category) {
        console.log("Final Category:", category); // Sjekk om du finner riktig kategori

        setManualInput((prev) => ({
          ...prev,
          id: category.id,
          item: selectedItem!,
          problem: selectedProblem!,
          fix_type: selectedFixType!,
          description: category.description,
          price_nok: category.price_nok,
          work: category.work,
          placeholder: category.placeholder,
        }));
      } else {
        console.log("Category not found with the selected filters");
      }
    } else {
      console.log("Selected Problem:", selectedProblem);
      console.log("Selected Type:", selectedItem);
      console.log("Selected Work:", selectedWork);
      console.log("Selected Fixtype:", selectedFixType);
      console.log("Not all values are selected");
    }
  }, [categories, selectedProblem, selectedItem, selectedFixType, selectedWork]);

  useEffect(() => {
    if (uniqueItems.length === 1 && !selectedItem) {
      setselectedItem(uniqueItems[0]);
    }
  }, [uniqueItems, selectedItem]);

  useEffect(() => {
    if (problemsForSelectedItem.length === 1 && !selectedProblem) {
      setSelectedProblem(problemsForSelectedItem[0]);
    }
  }, [problemsForSelectedItem]);

  useEffect(() => {
    if (
      worksForSelectedProblemAndItem &&
      worksForSelectedProblemAndItem.length === 1 &&
      !selectedWork
    ) {
      setSelectedWork(worksForSelectedProblemAndItem[0]);
    }
  }, [worksForSelectedProblemAndItem, selectedWork]);

  useEffect(() => {
    if (fixTypesForSelectedProblemAndWork.length === 1 && !selectedFixType) {
      setSelectedFixType(fixTypesForSelectedProblemAndWork[0].fix_type);
    }
  }, [fixTypesForSelectedProblemAndWork, selectedFixType]);

  const [uniqueGarments, setUniqueGarments] = useState(garments);

  // Oppdatér uniqueGarments når garments endres
  useEffect(() => {
    const groupedGarments = garments.reduce<(typeof garments)[0][]>((acc, garment) => {
      const existingGarment = acc.find(
        (item) =>
          item.item === garment.item &&
          item.problem === garment.problem &&
          item.fix_type === garment.fix_type &&
          item.price_nok === garment.price_nok &&
          item.customerComment === garment.customerComment
      );

      if (existingGarment) {
        existingGarment.count = (existingGarment.count || 1) + 1; // Øk `count` dersom den allerede finnes
      } else {
        acc.push({ ...garment, count: 1 }); // Legg til `count` på 1 for nye objekter
      }
      return acc;
    }, []);
    setUniqueGarments(groupedGarments);
  }, [garments]);

  // Funksjon for å oppdatere det midlertidige antallet direkte på garment-objektet
  const handleTempCountChange = (index: number, newCount: number) => {
    setUniqueGarments((prevGarments) =>
      prevGarments.map((item, i) =>
        i === index ? { ...item, count: Math.max(newCount, 1) } : item
      )
    );
  };

  // Funksjon for å oppdatere `count`-verdien (direkte på garment-objektet)
  const handleUpdateCount = (index: number) => {
    setUniqueGarments((prevGarments) =>
      prevGarments.map((item, i) => (i === index ? { ...item, count: item.count } : item))
    );
  };

  //Count fra cart
  const [newCounts, setNewCounts] = useState(garments.map((garment) => garment.count));

  // Oppdater `newCounts` når `garments` endres
  useEffect(() => {
    setNewCounts(garments.map((garment) => garment.count));
  }, [garments]);

  //Count fra tilstand før cart
  const [tempCount, setTempCount] = useState(1);

  const finalPriceCalculation = (input: number) => {
    // Ensure input is a positive number
    const basePrice = Math.max(0, input);
    const discountPercent = parseInt(discount ?? "0", 10);
    // Calculate discounted price and round to nearest integer
    return Math.max(0, Math.round(basePrice * (1 - discountPercent / 100)));
  };

  const updatePriceWithCount = (newCount: number) => {
    // Ensure count is at least 1
    const safeCount = Math.max(1, newCount);

    // Calculate the original base price per item (before discount)
    const basePerItem = Math.round(
      (manualInput.price_nok * (100 / (100 - parseInt(discount ?? "0", 10)))) / tempCount
    );

    // Calculate new total base price
    const newBasePrice = basePerItem * safeCount;

    // Apply discount to the new total
    const finalPrice = finalPriceCalculation(newBasePrice);

    setManualInput((prev) => ({
      ...prev,
      count: safeCount,
      price_nok: finalPrice,
    }));
    setTempCount(safeCount);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="fixed inset-0 z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter={`transform transition ease-out duration-500 ${
              isMobile ? "translate-y-full" : "translate-y-0"
            }`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave={`transform transition ease-in duration-500 ${
              isMobile ? "translate-y-0" : "translate-y-full"
            }`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 flex items-center justify-center sm:pb-0">
            <div className="flex h-full max-h-screen items-center justify-center overflow-y-auto sm:h-auto sm:w-1/3">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel
                  className={`relative transform overflow-auto bg-white text-left shadow-xl transition-all sm:my-0 ${
                    showCameraCapture
                      ? "h-full w-full"
                      : "h-full w-screen sm:h-auto sm:w-full sm:max-w-lg"
                  }`}
                  style={
                    showCameraCapture && isMobile
                      ? {
                          width: "100vw",
                          height: "100vh",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }
                      : {}
                  }
                  onClick={(e: any) => e.stopPropagation()}
                >
                  <div className="absolute right-4 top-4">
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={onClose}
                    >
                      <span className="sr-only">{t("close")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  {showCheckout ? (
                    localStorage.getItem("serviceCategory") ===
                      "EN-Workwear/Ahlsell-0924" ||
                    localStorage.getItem("serviceCategory") ===
                      "NO-Workwear/Ahlsell-0924" ? (
                      <Transition.Child
                        enter="ease-out duration-500"
                        enterFrom="translate-y-full opacity-0"
                        enterTo="translate-y-0 opacity-100"
                        leave="ease-in duration-500"
                        leaveFrom="translate-y-0 opacity-100"
                        leaveTo="translate-y-full opacity-0"
                      >
                        <CheckoutWorkwear
                          garments={garments}
                          contactInfo={contactInfo}
                          onClose={onClose}
                          onBackToCart={() => {
                            setShowCheckout(false);
                            setShowCart(true);
                          }}
                        />
                      </Transition.Child>
                    ) : (
                      <Transition.Child
                        enter="ease-out duration-500"
                        enterFrom="translate-y-full opacity-0"
                        enterTo="translate-y-0 opacity-100"
                        leave="ease-in duration-500"
                        leaveFrom="translate-y-0 opacity-100"
                        leaveTo="translate-y-full opacity-0"
                      >
                        <CheckoutB2C
                          garments={garments}
                          contactInfo={contactInfo}
                          onClose={onClose}
                          onBackToCart={() => {
                            setShowCheckout(false);
                            setShowCart(true);
                          }}
                          emailPassedTrough={emailPassedTrough ?? undefined}
                          customDropOff={customDropOff ?? undefined}
                          attributes={attributes ?? undefined}
                        />
                      </Transition.Child>
                    )
                  ) : (
                    <>
                      {!reqResult && garments.length === 0 && (
                        <Dialog.Title
                          as="h3"
                          className="flex text-base leading-6 text-black"
                        >
                          <div className="text-md m-4 flex-grow pr-16 font-light">
                            {localStorage.getItem("serviceCategory") ===
                              "EN-Workwear/Ahlsell-0924" &&
                              !imageUrl &&
                              garments.length === 0 &&
                              !showManualCategories && (
                                <div className="mb-4">
                                  {t("greeting.hi")}{" "}
                                  {`${(() => {
                                    const uid = localStorage.getItem("uid") || "";
                                    const firstName = uid.split(".")[0];
                                    return (
                                      firstName.charAt(0).toUpperCase() +
                                      firstName.slice(1).toLowerCase()
                                    );
                                  })()}`}
                                  ! {t("message.osloMunicipality")}
                                </div>
                              )}
                            {t("message.register")}{" "}
                            {
                              categoryMapping[
                                localStorage.getItem("serviceCategory") || "NO - Clothes"
                              ]
                            }
                            ?
                          </div>
                          <a
                            onClick={() => {
                              resetManualSelection();
                              setShowManualCategories(false);
                            }}
                            className="text-md m-4 mr-12 flex-shrink-0 font-light"
                          ></a>
                        </Dialog.Title>
                      )}
                      {!showCart && (
                        <div className="flex justify-between">
                          {!uploading && (
                            <div className="w-full">
                              {showManualCategories ? (
                                <></>
                              ) : (
                                <button
                                  type="button"
                                  className={`relative flex cursor-pointer ${
                                    reqResult ? "" : "border-b border-t border-black"
                                  } ${
                                    imageUrl
                                      ? "bg-white"
                                      : isMobile
                                        ? "bg-fikseGreen"
                                        : "bg-fikseGreen"
                                  } text-md w-full p-4 text-black hover:text-gray-700`}
                                  onClick={() => {
                                    resetManualSelection();
                                    setShowManualCategories(false);
                                    setShowCameraCapture(true);
                                  }}
                                >
                                  {imageUrl ? (
                                    <>
                                      {t("imageScanned")}
                                      <BackIcon className="absolute right-8 m-auto h-6 w-6" />
                                    </>
                                  ) : (
                                    <>
                                      <div className="my-auto mr-auto">
                                        {window.innerWidth <= 768
                                          ? t("takePicture")
                                          : t("uploadPicture")}
                                      </div>
                                      {isMobile ? (
                                        <CameraIcon className="absolute right-8 m-auto h-6 w-6" />
                                      ) : (
                                        <PhotoIcon className="absolute right-8 m-auto h-6 w-6" />
                                      )}
                                    </>
                                  )}
                                </button>
                              )}
                              <button
                                type="button"
                                className={`text-md relative mr-auto inline-flex w-full items-center border-b border-black bg-[#f7f6eb] p-4 pr-20 text-black hover:bg-gray-50 ${
                                  showManualCategories
                                    ? "border-b border-t border-black"
                                    : ""
                                }`}
                                disabled={uploading}
                                onClick={() => {
                                  setErrorMessage(null);
                                  if (showManualCategories) {
                                    resetManualSelection();
                                    setShowManualCategories(false);
                                    return;
                                  }
                                  if (typeof window !== "undefined" && window.sa_event) {
                                    window.sa_event("button_click", {
                                      choice: "manual_selection",
                                    });
                                  }
                                  setShowManualCategories(true);
                                  setReqResult(null);
                                }}
                              >
                                {showManualCategories ? (
                                  <>
                                    <span className="text-md mr-2" aria-hidden="true">
                                      <BackIcon
                                        className="h-5 w-5 text-black"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span className="flex items-center">
                                      {t("resetManualCategories") + " "}
                                    </span>
                                    {selectedItem && (
                                      <>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setReqResult(null);
                                            setselectedItem(null);
                                            setSelectedProblem(null); // Fjern avhengig variabel
                                            setSelectedWork(null); // Fjern avhengig variabel
                                            setSelectedFixType(null);
                                            setTempCount(1);
                                          }}
                                          className="text-md mr-2 cursor-pointer"
                                          aria-hidden="true"
                                        >
                                          <BackIcon
                                            className="ml-2 h-5 w-5 text-black"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setReqResult(null);
                                            setselectedItem(null);
                                            setSelectedProblem(null);
                                            setSelectedWork(null);
                                            setSelectedFixType(null);
                                            setTempCount(1);
                                          }}
                                          className="flex cursor-pointer items-center"
                                        >
                                          {selectedItem}
                                        </span>
                                      </>
                                    )}
                                    {selectedProblem && (
                                      <>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setReqResult(null);
                                            setSelectedProblem(null);
                                            setSelectedWork(null); // Fjern avhengig variabel
                                            setSelectedFixType(null);
                                            setTempCount(1);
                                          }}
                                          className="text-md mr-2 cursor-pointer"
                                          aria-hidden="true"
                                        >
                                          <BackIcon
                                            className="ml-2 h-5 w-5 text-black"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setReqResult(null);
                                            setSelectedProblem(null);
                                            setSelectedWork(null);
                                            setSelectedFixType(null);
                                            setTempCount(1);
                                          }}
                                          className="flex cursor-pointer items-center"
                                        >
                                          {selectedProblem}
                                        </span>
                                      </>
                                    )}
                                    {selectedWork && selectedWork != "N/A" && (
                                      <>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedWork(null);
                                            setSelectedFixType(null);
                                            setTempCount(1);
                                          }}
                                          className="text-md mr-2 cursor-pointer"
                                          aria-hidden="true"
                                        >
                                          <BackIcon
                                            className="ml-2 h-5 w-5 text-black"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedWork(null);
                                            setSelectedFixType(null);
                                            setTempCount(1);
                                          }}
                                          className="flex cursor-pointer items-center"
                                        >
                                          {selectedWork}
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span className="flex items-center">
                                      {t("addManually")}
                                    </span>
                                    <span
                                      className="text-md absolute right-8"
                                      aria-hidden="true"
                                    >
                                      <ChevronRightIcon
                                        className="h-5 w-5 text-black"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </>
                                )}
                              </button>

                              {garments.length > 0 &&
                                !reqResult &&
                                !showManualCategories &&
                                !showCart && (
                                  <button
                                    type="button"
                                    className="text-md relative mr-auto inline-flex w-full border-b border-black p-4 text-black hover:bg-gray-50"
                                    onClick={() => setShowCart(true)}
                                  >
                                    <span className="flex w-full items-center justify-center">
                                      <BackIcon
                                        className="h-6 w-6 text-black"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </button>
                                )}
                            </div>
                          )}
                          {uploading && (
                            <>
                              <p className="my-2 ml-4 text-sm text-black">
                                {t("uploading")}...
                                <p className="font-light">{t("rememberGarments")}</p>
                              </p>
                              <div className="mr-2">
                                <default-loader
                                  size="37"
                                  stroke="5"
                                  stroke-length="0.15"
                                  bg-opacity="0.1"
                                  speed="1"
                                  color="#8cd7ff"
                                ></default-loader>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {errorMessage && !showCart && (
                        <div className="border-b border-black bg-fikseRed p-4 text-black">
                          {errorMessage}
                        </div>
                      )}
                      {reqResult && !errorMessage && !showManualCategories && (
                        <>
                          <div className="">
                            <img
                              src={imageUrl || fallbackImageUrl}
                              alt={t("capturedImage")}
                              className="h-auto w-full"
                            />
                            <div className="items-center">
                              <div className="border-b border-t border-black p-4 text-black">
                                <span className="">
                                  {reqResult.problem +
                                    t("in") +
                                    reqResult.item.toLowerCase()}
                                </span>
                              </div>
                            </div>
                            {reqResult.work != "N/A" && (
                              <div className="items-center">
                                <div className="border-b border-black p-4 text-black">
                                  <span className=" ">{reqResult.work}</span>
                                </div>
                              </div>
                            )}
                            <div className="items-center">
                              <div className="border-b border-black p-4 text-black">
                                <span className=" ">{reqResult.fix_type}</span>
                                {t("estimatedPrice")}
                                {(localStorage.getItem("serviceCategory") ==
                                  "NO-Workwear/Ahlsell-0924" ||
                                  localStorage.getItem("serviceCategory") ==
                                    "EN-Workwear/Ahlsell-0924") &&
                                  t("exclVAT")}{" "}
                                <span className=" ">{reqResult.price_nok} kr</span>
                              </div>
                            </div>
                            <div className="items-center">
                              <input
                                type="text"
                                id="customerComment"
                                className={`w-full border-b border-black p-4 ${
                                  reqResult.customerComment === t("errors.requiredField")
                                    ? "bg-[#ffa7dd] text-black"
                                    : "bg-[#f7f6eb]"
                                }`}
                                value={reqResult?.customerComment || ""}
                                onChange={(e) => {
                                  const updatedComment = e.target.value;
                                  setReqResult({
                                    ...reqResult,
                                    customerComment: updatedComment,
                                  });
                                }}
                                placeholder={
                                  reqResult?.placeholder
                                    ? reqResult?.placeholder
                                    : t("requiredComment")
                                }
                              />
                            </div>
                          </div>

                          <div className="gap-8">
                            <button
                              type="button"
                              className="text-md my-auto mr-auto inline-flex w-full bg-[#D3FF8C] p-4 text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-100 hover:text-gray-700 sm:col-start-1 sm:mt-0"
                              disabled={uploading}
                              onClick={handleAddGarment}
                            >
                              {t("addToCart")}
                            </button>
                          </div>
                        </>
                      )}
                      {showManualCategories && (
                        <>
                          <div className="max-h-96 overflow-y-auto">
                            {/* Første steg: Velg Item */}
                            {!selectedItem && (
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-500"
                                enterFrom="translate-y-full opacity-0"
                                enterTo="translate-y-0 opacity-100"
                                leave="ease-in duration-500"
                                leaveFrom="translate-y-0 opacity-100"
                                leaveTo="translate-y-full opacity-0"
                              >
                                <div>
                                  <ul className="">
                                    {uniqueItems.map((item) => (
                                      <li
                                        key={item}
                                        className="cursor-pointer border-b border-black p-4 hover:bg-gray-100"
                                        onClick={() => setselectedItem(item)}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Transition.Child>
                            )}

                            {/* Andre steg: Velg Problem */}
                            {selectedItem && !selectedProblem && (
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-500"
                                enterFrom="translate-y-full opacity-0"
                                enterTo="translate-y-0 opacity-100"
                                leave="ease-in duration-500"
                                leaveFrom="translate-y-0 opacity-100"
                                leaveTo="translate-y-full opacity-0"
                              >
                                <div>
                                  <ul className="">
                                    {problemsForSelectedItem.map((problem) => (
                                      <li
                                        key={problem}
                                        className="cursor-pointer border-b border-black p-4 hover:bg-gray-100"
                                        onClick={() => setSelectedProblem(problem)}
                                      >
                                        {problem}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Transition.Child>
                            )}

                            {/* Tredje steg: Valgfritt Work (hvis det finnes) */}
                            {selectedItem &&
                              selectedProblem &&
                              !selectedWork &&
                              worksForSelectedProblemAndItem.length > 0 && (
                                <Transition.Child
                                  as={Fragment}
                                  enter="ease-out duration-500"
                                  enterFrom="translate-y-full opacity-0"
                                  enterTo="translate-y-0 opacity-100"
                                  leave="ease-in duration-500"
                                  leaveFrom="translate-y-0 opacity-100"
                                  leaveTo="translate-y-full opacity-0"
                                >
                                  <div>
                                    <ul className="">
                                      {worksForSelectedProblemAndItem.map((work) => (
                                        <li
                                          key={work}
                                          className="cursor-pointer border-b border-black p-4 hover:bg-gray-100"
                                          onClick={() => setSelectedWork(work)}
                                        >
                                          {work}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </Transition.Child>
                              )}

                            {/* Fjerde steg: Velg FixType */}
                            {selectedItem &&
                              selectedProblem &&
                              (selectedWork ||
                                worksForSelectedProblemAndItem.length == 0) &&
                              !selectedFixType && (
                                <Transition.Child
                                  as={Fragment}
                                  enter="ease-out duration-500"
                                  enterFrom="translate-y-full opacity-0"
                                  enterTo="translate-y-0 opacity-100"
                                  leave="ease-in duration-500"
                                  leaveFrom="translate-y-0 opacity-100"
                                  leaveTo="translate-y-full opacity-0"
                                >
                                  <div className="">
                                    <ul className="">
                                      {fixTypesForSelectedProblemAndWork.map(
                                        (fixTypeObj) => (
                                          <li
                                            key={fixTypeObj.fix_type}
                                            className="flex cursor-pointer items-center justify-between border-b border-black p-4 hover:bg-gray-100"
                                            onClick={() =>
                                              setSelectedFixType(fixTypeObj.fix_type)
                                            }
                                          >
                                            <div>
                                              {fixTypeObj.fix_type}
                                              <span className="mt-1 block text-sm text-gray-500">
                                                {fixTypeObj.description}
                                              </span>
                                            </div>
                                            <span className="text-md ml-4 text-base text-black">
                                              {finalPriceCalculation(
                                                fixTypeObj.price_nok
                                              )}
                                              .-
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </Transition.Child>
                              )}
                          </div>

                          {/* Hvis alle valg er gjort, vis oppsummering og mulighet for å legge til i handlekurv */}
                          {selectedItem &&
                            selectedProblem &&
                            selectedWork &&
                            selectedFixType && (
                              <div className="">
                                <div className="border-b border-black p-4 text-black">
                                  <div>
                                    {manualInput.problem} {t("in")}{" "}
                                    {manualInput.item.toLowerCase()}
                                  </div>
                                  <div>
                                    {selectedWork !== "N/A"
                                      ? `${manualInput.work} - ${manualInput.fix_type}`
                                      : manualInput.fix_type}
                                  </div>
                                </div>

                                <div className="border-b border-black text-black">
                                  <input
                                    type="text"
                                    id="customerComment"
                                    className="w-full border-black bg-[#f7f6eb] p-4"
                                    value={manualInput.customerComment}
                                    onChange={(e) =>
                                      setManualInput({
                                        ...manualInput,
                                        customerComment: e.target.value,
                                      })
                                    }
                                    placeholder={
                                      manualInput.placeholder || t("requiredComment")
                                    }
                                  />
                                </div>

                                <div className="flex items-center justify-between p-4 text-black">
                                  <span className="mr-2">{t("garmentQty")}</span>
                                  <div className="flex items-center">
                                    <span className="px-4">{tempCount + " stk"}</span>
                                    <button
                                      onClick={() => {
                                        const newCount = Math.max(tempCount - 1, 1);
                                        updatePriceWithCount(newCount);
                                      }}
                                      className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-black text-sm font-bold text-black"
                                      disabled={tempCount <= 1}
                                    >
                                      -
                                    </button>
                                    <button
                                      onClick={() => {
                                        const newCount = tempCount + 1;
                                        updatePriceWithCount(newCount);
                                      }}
                                      className="ml-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-black text-sm font-bold text-black"
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>

                                <div className="mb-8 border-t border-black p-4 text-gray-400">
                                  {t("adjustQty")}
                                </div>

                                <div className="flex justify-between px-4 pt-4 text-black">
                                  <span>
                                    {t("estimatedPrice")}
                                    {(localStorage.getItem("serviceCategory") ==
                                      "NO-Workwear/Ahlsell-0924" ||
                                      localStorage.getItem("serviceCategory") ==
                                        "EN-Workwear/Ahlsell-0924") &&
                                      t("exclVAT")}{" "}
                                  </span>
                                  <span>
                                    {finalPriceCalculation(manualInput.price_nok)} kr
                                  </span>
                                </div>
                                <div className="border-b border-black px-4 pb-2 text-gray-400">
                                  {t("onlyFixOrder")}
                                </div>

                                <div className="gap-8">
                                  <button
                                    type="button"
                                    className="text-md my-auto mr-auto inline-flex w-full bg-[#D3FF8C] p-4 text-black shadow-sm hover:bg-green-100 hover:text-gray-700"
                                    onClick={handleAddManualGarment}
                                  >
                                    {t("addToCart")}
                                  </button>
                                </div>
                                <div className="border-t border-black px-4 pb-4 pt-2 text-center text-xs text-gray-500">
                                  {"fikse.co"}
                                </div>
                              </div>
                            )}
                        </>
                      )}

                      <Transition.Child
                        enter="ease-out duration-500"
                        enterFrom="translate-y-full opacity-0"
                        enterTo="translate-y-0 opacity-100"
                        leave="ease-in duration-500"
                        leaveFrom="translate-y-0 opacity-100"
                        leaveTo="translate-y-full opacity-0"
                      >
                        {garments.length > 0 &&
                          !reqResult &&
                          !showManualCategories &&
                          showCart && (
                            <div className="">
                              <h4 className="m-4 text-xl text-black">{t("cart")}</h4>

                              {garments.map((garment, index) => (
                                <div key={index} className="border-t border-black p-4">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      setShowCartDetails(
                                        showCartDetails.map((show, i) =>
                                          i === index ? !show : show
                                        )
                                      )
                                    }
                                  >
                                    <div className="flex items-center justify-between">
                                      <div className="flex flex-col text-black">
                                        <div className="lowercase">{`${garment.count} ${garment.problem} på ${garment.item}`}</div>
                                        <div className="mt-1 text-sm text-gray-500">
                                          {t("estimatedPrice")}
                                          {(localStorage.getItem("serviceCategory") ==
                                            "NO-Workwear/Ahlsell-0924" ||
                                            localStorage.getItem("serviceCategory") ==
                                              "EN-Workwear/Ahlsell-0924") &&
                                            t("exclVAT")}{" "}
                                          {`${garment.price_nok} kr`}
                                        </div>
                                      </div>
                                      {showCartDetails[index] ? (
                                        <ChevronDownIcon
                                          className="h-5 w-5 text-gray-500"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ChevronRightIcon
                                          className="h-5 w-5 text-gray-500"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {showCartDetails[index] && (
                                    <div className="mt-2 text-black">
                                      <div className="-mx-4 mb-4 flex items-center justify-between border-b border-t border-black bg-[#F7F6EB] p-4">
                                        <span className="mr-2">
                                          {"Hvor mange plagg?"}
                                        </span>
                                        <div className="flex items-center">
                                          {/* Lagre-knapp som oppdaterer count og pris */}
                                          {newCounts[index] !== garment.count && (
                                            <button
                                              onClick={() => {
                                                setGarments((prevGarments) =>
                                                  prevGarments.map((item, i) => {
                                                    if (i === index) {
                                                      // Calculate the original base price per item (before discount)
                                                      const basePerItem = Math.round(
                                                        (item.price_nok *
                                                          (100 /
                                                            (100 -
                                                              parseInt(
                                                                discount ?? "0",
                                                                10
                                                              )))) /
                                                          item.count
                                                      );

                                                      // Calculate new total base price
                                                      const newBasePrice =
                                                        basePerItem * newCounts[index];

                                                      // Apply discount to the new total
                                                      const finalPrice =
                                                        finalPriceCalculation(
                                                          newBasePrice
                                                        );

                                                      return {
                                                        ...item,
                                                        count: newCounts[index],
                                                        price_nok: finalPrice,
                                                      };
                                                    }
                                                    return item;
                                                  })
                                                );
                                              }}
                                              className="mr-4 text-sm text-blue-500 hover:underline"
                                            >
                                              {t("save")}
                                            </button>
                                          )}
                                          <input
                                            type="number"
                                            min="1"
                                            value={newCounts[index]}
                                            onChange={(e) => {
                                              let inputValue = e.target.value;

                                              // If there are two or more digits, remove all leading zeros
                                              if (
                                                inputValue.length >= 2 &&
                                                inputValue.startsWith("0")
                                              ) {
                                                inputValue = inputValue.replace(
                                                  /^0+/,
                                                  ""
                                                );
                                                // Directly update the input field with the new value
                                                e.target.value = inputValue;
                                              }

                                              // Allow empty or zero values during typing
                                              const newValue =
                                                inputValue === ""
                                                  ? 0
                                                  : parseInt(inputValue);

                                              setNewCounts((prevCounts) =>
                                                prevCounts.map((count, i) =>
                                                  i === index ? newValue : count
                                                )
                                              );
                                            }}
                                            onBlur={() => {
                                              // When focus leaves the input, ensure the value is at least 1
                                              setNewCounts((prevCounts) =>
                                                prevCounts.map((count, i) =>
                                                  i === index
                                                    ? Math.max(count || 0, 1)
                                                    : count
                                                )
                                              );
                                            }}
                                            className="w-16 border p-1 text-sm text-gray-500"
                                          />
                                          <span className="px-4">{" stk"}</span>
                                        </div>
                                      </div>

                                      <div className="space-y-2">
                                        <div className="flex items-center justify-between text-black">
                                          <span className="">
                                            {garment.item + " - " + garment.fix_type}
                                          </span>
                                          <div className="flex text-black">
                                            <TrashIcon
                                              className="ml-8 mr-4 mt-2 h-5 w-5 cursor-pointer text-gray-500"
                                              onClick={() => handleRemoveGarment(index)}
                                              aria-hidden="true"
                                            />
                                          </div>
                                        </div>

                                        <div className="text-black">
                                          <span className="">
                                            {"Fiks: " + garment.description}
                                          </span>
                                        </div>
                                        <div className="text-black">
                                          <div className="mr-4 flex justify-between">
                                            <span>Pris per stk</span>
                                            <span>
                                              {garment.price_nok / garment.count} kr
                                            </span>
                                          </div>
                                          {garment.count > 1 && (
                                            <div className="mr-4 flex justify-between">
                                              <span>Pris for {garment.count} stk</span>
                                              <span>{garment.price_nok} kr</span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="text-black">
                                          <span className="">
                                            {garment.customerComment}
                                          </span>
                                        </div>
                                      </div>

                                      {garment.imageUrl &&
                                        garment.imageUrl != fallbackImageUrl && (
                                          <div className="-ml-4 -mr-4 mt-4 flex justify-center">
                                            <img
                                              src={garment.imageUrl || fallbackImageUrl}
                                              alt={`${t("garment")} ${index + 1}`}
                                              className="object-cover"
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </div>
                              ))}
                              <div className="mb-8 border-t border-black p-4 text-gray-400">
                                {"Klikk deg inn på bestillingen for å endre antall."}
                              </div>
                              <div className="items-center justify-between pt-4">
                                <div className="mb-4 ml-4 text-black">
                                  {t("estimatedTotal")}{" "}
                                  {(localStorage.getItem("serviceCategory") ==
                                    "NO-Workwear/Ahlsell-0924" ||
                                    localStorage.getItem("serviceCategory") ==
                                      "EN-Workwear/Ahlsell-0924") &&
                                    t("exclVAT")}{" "}
                                  {garments.reduce((total, e) => total + e.price_nok, 0)}{" "}
                                  kr
                                  <p className="my-auto text-sm font-light">
                                    {t("onlyFixOrder")}
                                  </p>
                                </div>
                                <button
                                  type="button"
                                  className="text-md my-auto mr-auto inline-flex w-full border-t border-black bg-[#8cd7ff] p-4 text-black hover:bg-[#8cd7ff] hover:bg-opacity-90"
                                  onClick={() => {
                                    setShowCart(false);
                                    setImageUrl("");
                                  }}
                                >
                                  {t("addNewGarment")}
                                  <PlusIcon
                                    className="ml-auto h-6 w-6 text-black"
                                    aria-hidden="true"
                                  />
                                </button>
                                {uploading ? (
                                  ""
                                ) : (
                                  <button
                                    type="button"
                                    className="text-md my-auto mr-auto inline-flex w-full border-t border-black bg-[#D3FF8C] p-4 text-left text-black hover:bg-[#DCFCE6]"
                                    onClick={() => {
                                      setShowCheckout(true);
                                      setShowCart(false);
                                    }}
                                  >
                                    {localStorage.getItem("serviceCategory") ===
                                      "EN-Workwear/Ahlsell-0924" ||
                                    localStorage.getItem("serviceCategory") ===
                                      "NO-Workwear/Ahlsell-0924"
                                      ? t("proceedToPaymentWorkWare")
                                      : t("proceedToPayment")}
                                  </button>
                                )}
                              </div>
                              <div className="border-t border-black px-4 pb-4 pt-2 text-center text-xs text-gray-500">
                                {"fikse.co"}
                              </div>
                            </div>
                          )}
                      </Transition.Child>
                      {showCameraCapture && (
                        <>
                          {isMobile ? (
                            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
                              <CameraCapture
                                onClose={handleCameraCaptureClose}
                                loading={loading}
                              />
                            </div>
                          ) : (
                            <div>
                              <CameraCapture
                                onClose={handleCameraCaptureClose}
                                loading={loading}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default OrderModal;
