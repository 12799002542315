import React from "react";

import Lottie from "react-lottie";

import animationData from "../images/FikseAnimation.json";

interface LoadingSpinnerProps {
  className?: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className = "" }) => (
  <div className={`flex items-center justify-center py-10 ${className}`}>
    <Lottie options={defaultOptions} />
  </div>
);

export default LoadingSpinner;
