import React, { useEffect, useState } from "react";

import { onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate, useParams } from "react-router-dom";

import { auth } from "../firebase";
import { Button } from "../ui/button";

interface TailorInfo {
  tailorId: string;
  tailorName: string;
  inviteValidUntil: string;
}

export default function TailorJoinPage() {
  const { link } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [fetchingTailorInfo, setFetchingTailorInfo] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [tailorInfo, setTailorInfo] = useState<TailorInfo | null>(null);
  const [joining, setJoining] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchTailorInfo = async () => {
      if (!link) return;

      try {
        setFetchingTailorInfo(true);
        const functions = getFunctions();
        const getTailorInfoFromInvite = httpsCallable(
          functions,
          "getTailorInfoFromInvite"
        );

        const result = await getTailorInfoFromInvite({ inviteId: link });
        const data = result.data as any;

        if (data.success) {
          setTailorInfo({
            tailorId: data.tailorId || "",
            tailorName: data.tailorName,
            inviteValidUntil: new Date().toISOString(), // Default validity if not provided
          });
        }
      } catch (err: any) {
        console.error("Error fetching tailor info:", err);
        setError(err.message || "Failed to fetch tailor information");
      } finally {
        setFetchingTailorInfo(false);
      }
    };

    fetchTailorInfo();
  }, [link]);

  const handleJoinTailor = async () => {
    if (!user || !link) return;

    try {
      setJoining(true);
      const functions = getFunctions();
      const joinTailorByLink = httpsCallable(functions, "joinTailorByLink");

      const result = await joinTailorByLink({ inviteId: link });
      const data = result.data as any;

      if (data.success) {
        setSuccess(data.message);
        if (!tailorInfo && data.tailorName) {
          setTailorInfo({
            tailorId: data.tailorId || "",
            tailorName: data.tailorName,
            inviteValidUntil: new Date().toISOString(), // Default validity if not provided
          });
        }
      } else {
        setError("Failed to join tailor. Please try again.");
      }
    } catch (err: any) {
      setError(err.message || "An error occurred while joining the tailor");
    } finally {
      setJoining(false);
    }
  };

  if (loading || fetchingTailorInfo) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
        <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
          <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
            Loading...
          </h1>
        </div>
      </div>
    );
  }

  if (error && !tailorInfo) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
        <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
          <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">Error</h1>
          <div className="rounded-md bg-red-50 p-4 text-sm text-red-700">
            <p>{error}</p>
          </div>
          <div className="mt-4 flex justify-center">
            <Button
              onClick={() => navigate("/")}
              className="w-full bg-blue-600 py-2 font-medium text-white hover:bg-blue-700"
            >
              Return to Home
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    // User is not logged in
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
        <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
          <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
            {tailorInfo ? `Join ${tailorInfo.tailorName}` : "Tailor Invitation"}
          </h1>
          {tailorInfo && (
            <div className="mb-4 rounded-md bg-blue-50 p-4 text-sm text-blue-700">
              <p>
                You've been invited to join{" "}
                <span className="font-bold">{tailorInfo.tailorName}</span> as an employee.
              </p>
              <p className="mt-2">
                This invitation is valid until{" "}
                {new Date(tailorInfo.inviteValidUntil).toLocaleDateString()}.
              </p>
            </div>
          )}
          <p className="mb-4 text-center text-gray-600">
            You need to log in to join this tailor as an employee.
          </p>
          <div className="flex justify-center">
            <Button
              onClick={() =>
                navigate(`/login?redirect=${encodeURIComponent(`/tailor/join/${link}`)}`)
              }
              className="w-full bg-blue-600 py-2 font-medium text-white hover:bg-blue-700"
            >
              Log in to continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
          {success
            ? "Success!"
            : tailorInfo
              ? `Join ${tailorInfo.tailorName}`
              : "Join Tailor as Employee"}
        </h1>

        {error && (
          <div className="mb-4 rounded-md bg-red-50 p-4 text-sm text-red-700">
            <p>{error}</p>
          </div>
        )}

        {success && (
          <div className="mb-4 rounded-md bg-green-50 p-4 text-sm text-green-700">
            <p>{success}</p>
            {tailorInfo && <p className="mt-2">Tailor: {tailorInfo.tailorName}</p>}
          </div>
        )}

        {!success && !error && (
          <>
            {tailorInfo && (
              <div className="mb-4 rounded-md bg-blue-50 p-4 text-sm text-blue-700">
                <p>
                  You've been invited to join{" "}
                  <span className="font-bold">{tailorInfo.tailorName}</span> as an
                  employee.
                </p>
                <p className="mt-2">
                  This invitation is valid until{" "}
                  {new Date(tailorInfo.inviteValidUntil).toLocaleDateString()}.
                </p>
              </div>
            )}
            <p className="mb-6 text-center text-gray-600">
              You are logged in as <span className="font-medium">{user.email}</span>
            </p>
            <p className="mb-6 text-center text-gray-600">
              Click the button below to join {tailorInfo?.tailorName || "this tailor"} as
              an employee.
            </p>
            <div className="flex justify-center">
              <Button
                onClick={handleJoinTailor}
                disabled={joining}
                className="w-full bg-blue-600 py-2 font-medium text-white hover:bg-blue-700"
              >
                {joining ? "Joining..." : `Join ${tailorInfo?.tailorName || "Tailor"}`}
              </Button>
            </div>
          </>
        )}

        {success && (
          <div className="mt-4 flex justify-center">
            <Button
              onClick={() => navigate("/dashboard")}
              className="w-full bg-blue-600 py-2 font-medium text-white hover:bg-blue-700"
            >
              Go to Dashboard
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
