import { useEffect, useState } from "react";

import { getRetailElementsFromRetailUUIDstring } from "../../components/databaseHelper";
import Retail from "../../types/retail";

interface RetailSelectorProps {
  selectedRetailID: Retail | null;
  onRetailSelect: (retail: Retail | null) => void;
}

const retailsInLocalStorage = async (): Promise<Retail[]> => {
  const localRetails = localStorage.getItem("retailConnections");
  if (!localRetails) return [];
  const retailIds = localRetails.split(",").filter((id) => id.length > 0);
  const retails = await getRetailElementsFromRetailUUIDstring(retailIds);
  return retails;
};

const RetailSelector: React.FC<RetailSelectorProps> = ({
  selectedRetailID,
  onRetailSelect,
}) => {
  const [availableRetails, setAvailableRetails] = useState<Retail[]>([]);

  useEffect(() => {
    const loadRetails = async () => {
      try {
        const retails = await retailsInLocalStorage();
        setAvailableRetails(retails);
        // Set the first retail as default if any exists and no retail is selected
        if (retails.length > 0 && !selectedRetailID) {
          onRetailSelect(retails[0]);
        }
      } catch (error) {
        console.error("Error loading retails:", error);
      }
    };

    loadRetails();
  }, []);

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Select Retail</label>
      <select
        value={selectedRetailID?.id || ""}
        onChange={(e) => {
          const selected = availableRetails.find(
            (retail) => retail.id === e.target.value
          );
          onRetailSelect(selected || null);
        }}
        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      >
        {availableRetails.map((retail) => (
          <option key={retail.id} value={retail.id}>
            {retail.name || retail.id}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RetailSelector;
