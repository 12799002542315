import { useEffect, useState } from "react";

import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { getCurrentUserEmail } from "../auth";
import ModalTile from "../components/ModalTile";
import LoadingSpinner from "../components/fikseLoadingLetter";
import { db } from "../firebase";
import { Button } from "../ui/button";

interface Tailor {
  id: string;
  name: string;
  orgNumber: string;
  address: string;
  contactPerson: {
    name: string;
    phone: string;
    email: string;
  };
  cut: {
    agreeBefore: boolean;
    cleaning: number | null;
    repairs: number | null;
  };
  preVerifiedEmails: string[];
  moderatorEmails: string[];
}

type ModalType =
  | "addPreverified"
  | "removePreverified"
  | "addModerator"
  | "removeModerator"
  | "generateJoinLink"
  | null;

interface ModalData {
  tailorId: string;
  value?: string;
}

export default function TailorModerator() {
  const [tailors, setTailors] = useState<Tailor[]>([]);
  const [userEmail, setUserEmail] = useState("");
  const [modalType, setModalType] = useState<ModalType>(null);
  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [newValue, setNewValue] = useState("");
  const [generatingLink, setGeneratingLink] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");

  useEffect(() => {
    const fetchTailors = async () => {
      const email = getCurrentUserEmail();
      setUserEmail(email);

      const querySnapshot = await getDocs(collection(db, "tailors"));
      const tailorList: Tailor[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Tailor[];

      // Filter to show tailors where user is either contact person or in moderatorEmails
      const filteredTailors = tailorList.filter(
        (tailor) =>
          tailor.contactPerson?.email === email || tailor.moderatorEmails?.includes(email)
      );

      setTailors(filteredTailors);
    };

    fetchTailors();
  }, []);

  const handleUpdateTailor = async (tailorId: string, updates: Partial<Tailor>) => {
    const tailorRef = doc(db, "tailors", tailorId);

    // Remove contactPerson from updates if it exists
    const { contactPerson, ...safeUpdates } = updates;

    try {
      await updateDoc(tailorRef, safeUpdates);
      setTailors(tailors.map((t) => (t.id === tailorId ? { ...t, ...safeUpdates } : t)));
    } catch (error) {
      console.error("Error updating tailor:", error);
    }
  };

  const handleRemoveEmail = async (tailorId: string, emailToRemove: string) => {
    const tailor = tailors.find((t) => t.id === tailorId);
    if (!tailor) return;

    setModalType("removePreverified");
    setModalData({ tailorId: tailorId, value: emailToRemove });
  };

  const handleRemoveModeratorEmail = async (tailorId: string, emailToRemove: string) => {
    const tailor = tailors.find((t) => t.id === tailorId);
    if (!tailor) return;

    setModalType("removeModerator");
    setModalData({ tailorId: tailorId, value: emailToRemove });
  };

  const handleConfirmRemove = async () => {
    if (!modalData) return;

    const tailor = tailors.find((t) => t.id === modalData.tailorId);
    if (!tailor) return;

    switch (modalType) {
      case "removePreverified":
        await handleUpdateTailor(modalData.tailorId, {
          preVerifiedEmails: tailor.preVerifiedEmails.filter(
            (email) => email !== modalData.value
          ),
        });
        break;
      case "removeModerator":
        await handleUpdateTailor(modalData.tailorId, {
          moderatorEmails: tailor.moderatorEmails.filter(
            (email) => email !== modalData.value
          ),
        });
        break;
    }

    setModalType(null);
    setModalData(null);
    setNewValue("");
  };

  const handleConfirmAdd = async () => {
    if (!modalData || !newValue) return;

    const tailor = tailors.find((t) => t.id === modalData.tailorId);
    if (!tailor) return;

    const valueToAdd = newValue.toLowerCase();

    switch (modalType) {
      case "addPreverified":
        await handleUpdateTailor(modalData.tailorId, {
          preVerifiedEmails: [...tailor.preVerifiedEmails, valueToAdd],
        });
        break;
      case "addModerator":
        await handleUpdateTailor(modalData.tailorId, {
          moderatorEmails: [...(tailor.moderatorEmails || []), valueToAdd],
        });
        break;
    }

    setModalType(null);
    setModalData(null);
    setNewValue("");
  };

  const handleGenerateJoinLink = async () => {
    console.log("Generating join link");
    if (!modalData?.tailorId) return;

    try {
      setGeneratingLink(true);
      const functions = getFunctions();
      const createTailorEmployeeJoinLink = httpsCallable(
        functions,
        "createTailorEmployeeJoinLink"
      );

      const result = await createTailorEmployeeJoinLink({ tailorId: modalData.tailorId });
      const data = result.data as any;

      if (data.success) {
        const fullLink = `${window.location.origin}/tailor/join/${data.inviteId}`;
        setGeneratedLink(fullLink);
      } else {
        alert("Failed to generate employee join link");
        setModalType(null);
      }
    } catch (error: any) {
      console.error("Error generating join link:", error);
      alert(`Error: ${error.message}`);
      setModalType(null);
    } finally {
      setGeneratingLink(false);
    }
  };

  const copyToClipboard = (text: string) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Link copied to clipboard!\nThis link will be valid for 5 days.");
  };

  const closeModal = () => {
    setModalType(null);
    setModalData(null);
    setNewValue("");
    setGeneratedLink("");
  };

  const renderModalContent = () => {
    if (!modalData) return null;

    const tailor = tailors.find((t) => t.id === modalData.tailorId);
    if (!tailor) return null;

    switch (modalType) {
      case "removePreverified":
      case "removeModerator":
        return (
          <div className="p-4">
            <p className="mb-4">Are you sure you want to remove this email?</p>
            <p className="mb-4 font-medium">{modalData.value}</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setModalType(null)}
                className="rounded px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmRemove}
                className="rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700"
              >
                Remove
              </button>
            </div>
          </div>
        );

      case "addPreverified":
      case "addModerator":
        return (
          <div className="p-4">
            <label className="block">
              <span className="text-gray-700">New Email:</span>
              <input
                type="email"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
              />
            </label>
            <div className="mt-4 flex justify-end gap-4">
              <button
                onClick={() => setModalType(null)}
                className="rounded px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmAdd}
                className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
              >
                Add
              </button>
            </div>
          </div>
        );

      case "generateJoinLink":
        return (
          <div className="p-4">
            {generatingLink ? (
              <div className="text-center">
                <p className="mb-4 text-gray-600">Generating invitation link...</p>
                <LoadingSpinner className="mx-auto" />
              </div>
            ) : generatedLink ? (
              <div className="space-y-4">
                <p className="text-gray-600">
                  Share this link with the employee. It will be valid for 5 days:
                </p>
                <div className="flex items-center rounded-md border border-gray-300 bg-gray-50 p-2">
                  <input
                    type="text"
                    value={generatedLink}
                    readOnly
                    className="w-full bg-transparent px-2 py-1 focus:outline-none"
                  />
                  <button
                    onClick={() => copyToClipboard(generatedLink)}
                    className="ml-2 rounded-md bg-blue-600 px-3 py-1 text-white hover:bg-blue-700"
                  >
                    Copy
                  </button>
                </div>
                <div className="flex justify-end">
                  <Button
                    onClick={closeModal}
                    className="mt-4 bg-gray-200 px-4 py-2 text-gray-800 hover:bg-gray-300"
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <p className="text-gray-600">
                  Generate a new employee invitation link for {tailor.name}. This link
                  will be valid for 5 days.
                </p>
                <div className="flex justify-end space-x-4">
                  <Button
                    onClick={closeModal}
                    className="bg-gray-200 px-4 py-2 text-gray-800 hover:bg-gray-300"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleGenerateJoinLink}
                    className="bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
                  >
                    Generate Link
                  </Button>
                </div>
              </div>
            )}
          </div>
        );
    }

    return null;
  };

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Tailor Management</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {tailors.map((tailor) => (
          <div
            key={tailor.id}
            className="flex flex-col rounded-lg border border-gray-200 bg-white shadow transition-shadow hover:shadow-lg"
          >
            <div className="p-6">
              <h3 className="text-lg font-bold">{tailor.name}</h3>
              <p className="text-sm text-gray-600">Org number: {tailor.orgNumber}</p>
              <p className="text-sm text-gray-600">Address: {tailor.address}</p>
              <div className="mt-2 border-t border-gray-100 pt-2">
                <div className="text-sm text-gray-500">
                  <p>Contact: {tailor.contactPerson.name}</p>
                  <p>Phone: {tailor.contactPerson.phone}</p>
                  <p>Email: {tailor.contactPerson.email}</p>
                </div>
              </div>
              <details className="mt-4">
                <summary className="cursor-pointer font-medium">Employee Emails</summary>
                <div className="mt-2 pl-2">
                  <ul className="space-y-1">
                    {tailor.preVerifiedEmails.map((email, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-between text-sm"
                      >
                        <span className="break-all">{email}</span>
                        <button
                          onClick={() => handleRemoveEmail(tailor.id, email)}
                          className="ml-2 shrink-0 text-red-600 hover:text-red-800"
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-2 flex justify-end gap-4">
                    <button
                      className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                      onClick={() => {
                        setModalType("addPreverified");
                        setModalData({ tailorId: tailor.id });
                      }}
                    >
                      + Add Pre-verified Email
                    </button>
                    <button
                      className="ml-4 mt-2 text-sm text-blue-600 hover:text-blue-800"
                      onClick={() => {
                        setModalType("generateJoinLink");
                        setModalData({ tailorId: tailor.id });
                      }}
                    >
                      🔗 Generate Join Link
                    </button>
                  </div>
                </div>
              </details>
              <details className="mt-4">
                <summary className="cursor-pointer font-medium">Moderator Emails</summary>
                <div className="mt-2 pl-2">
                  <ul className="space-y-1">
                    {tailor.moderatorEmails?.map((email, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-between text-sm"
                      >
                        <span className="break-all">{email}</span>
                        <button
                          onClick={() => handleRemoveModeratorEmail(tailor.id, email)}
                          className="ml-2 shrink-0 text-red-600 hover:text-red-800"
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                  <button
                    className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                    onClick={() => {
                      setModalType("addModerator");
                      setModalData({ tailorId: tailor.id });
                    }}
                  >
                    + Add Moderator Email
                  </button>
                </div>
              </details>
            </div>
            <div className="mt-auto flex items-end">
              <Button
                className="w-full border-x border-y border-black"
                onClick={handleGenerateJoinLink}
              >
                Generate Employee Join Link
              </Button>
            </div>
          </div>
        ))}
      </div>
      {modalType && (
        <ModalTile
          open={true}
          onClose={() => {
            setModalType(null);
            setModalData(null);
            setNewValue("");
            setGeneratedLink("");
          }}
          title={
            modalType === "generateJoinLink"
              ? "Generate Employee Join Link"
              : modalType?.includes("remove")
                ? "Confirm Removal"
                : "Add New Item"
          }
        >
          {renderModalContent()}
        </ModalTile>
      )}
    </div>
  );
}
