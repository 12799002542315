// components/OrderCard.tsx
import React, { useState } from "react";

import { data } from "@tensorflow/tfjs";
import { Event, Order, OrderInfo } from "@type/Order";
// Adjust the path to your firebase config
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import { getCurrentUserEmail } from "../../auth";
import ModalTile from "../../components/ModalTile";
import {
  cancelOrderNoticeToCustomer,
  deviateNoticeToCustomer,
} from "../../components/contact";
import { fallbackImageUrl } from "../../components/newOrder/OrderModal";
import { db } from "../../firebase";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import CustomerPrompt from "./CustomerPrompt";
import DisplayOrderImage from "./DisplayOrderImage";
import OrderCardDetails from "./OrderCardDetailsV2";

export const brandPriceVisibility = (retailId?: string): boolean => {
  const roles = localStorage.getItem("roles") || "";
  return (
    !retailId ||
    (localStorage.getItem("retailConnections") || "").includes(retailId) ||
    roles.includes("tailor")
  );
};

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  const now = new Date();

  const isToday = date.toDateString() === now.toDateString();
  const isYesterday =
    date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

  const formattedDate = isToday
    ? "i dag"
    : isYesterday
      ? "i går"
      : `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}`;

  const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  return `Oppdatert ${formattedDate} ${formattedTime}`;
};

interface OrderCardProps {
  order: Order;
  activity: any[]; // Consider creating a proper type for activity if possible
  index?: number;
  database: string;
}

export const addOrderEvent = async (
  orderId: string,
  handler: string,
  description: string,
  changes: string[],
  status: string,
  orderInfoIndex: number,
  database: string,
  oldPrice?: number,
  newPrice?: number
) => {
  try {
    const orderRef = doc(db, database, orderId);

    const newEvent: Event = {
      date: new Date().toISOString(),
      status: status,
      description: description + "FLAG " + changes.join(", "),
      handler: handler,
      oldPrice: oldPrice,
      newPrice: newPrice,
    };

    // Get the current document using getDoc
    const orderDoc = await getDoc(orderRef);
    const orderData = orderDoc.data();

    if (orderData) {
      const newOrderInfo = [...orderData.orderInfo];

      // Initialize events array if it doesn't exist
      if (!newOrderInfo[orderInfoIndex].event) {
        newOrderInfo[orderInfoIndex].event = [];
      }

      // Add the new event
      newOrderInfo[orderInfoIndex].event.push(newEvent);

      // Update eventPredictor if the status is not in it
      if (
        newOrderInfo[orderInfoIndex].eventPredictor &&
        !newOrderInfo[orderInfoIndex].eventPredictor.includes(status)
      ) {
        // Find the last event status that matches with eventPredictor
        let lastMatchedIndex = -1;
        const events = newOrderInfo[orderInfoIndex].event || [];

        for (let i = events.length - 2; i >= 0; i--) {
          const prevStatus = events[i]?.status;
          if (
            prevStatus &&
            newOrderInfo[orderInfoIndex].eventPredictor.includes(prevStatus)
          ) {
            lastMatchedIndex =
              newOrderInfo[orderInfoIndex].eventPredictor.indexOf(prevStatus);
            break;
          }
        }

        // Insert the new status right after the last matched status in eventPredictor
        if (lastMatchedIndex !== -1) {
          const newEventPredictor = [...newOrderInfo[orderInfoIndex].eventPredictor];
          newEventPredictor.splice(lastMatchedIndex + 1, 0, status);
          newOrderInfo[orderInfoIndex].eventPredictor = newEventPredictor;
        }
      }

      // Update the document
      await updateDoc(orderRef, {
        orderInfo: newOrderInfo,
      });
    }
  } catch (error) {
    console.error("Error adding event:", error);
    throw error;
  }
};

const addOrderEventSimple = async (
  orderId: string,
  status: string,
  orderInfoIndex: number,
  database: string
) => {
  try {
    const orderRef = doc(db, database, orderId);

    const newEvent: Event = {
      date: new Date().toISOString(),
      status: status,
      handler: getCurrentUserEmail() || "CRITICAL ERROR: NO USER",
    };

    // Get the current document using getDoc
    const orderDoc = await getDoc(orderRef);
    const orderData = orderDoc.data();

    if (orderData) {
      const newOrderInfo = [...orderData.orderInfo];

      // Initialize events array if it doesn't exist
      if (!newOrderInfo[orderInfoIndex].event) {
        newOrderInfo[orderInfoIndex].event = [];
      }

      // Add the new event
      newOrderInfo[orderInfoIndex].event.push(newEvent);

      // Update eventPredictor if the status is not in it
      if (
        newOrderInfo[orderInfoIndex].eventPredictor &&
        !newOrderInfo[orderInfoIndex].eventPredictor.includes(status)
      ) {
        // Find the last event status that matches with eventPredictor
        let lastMatchedIndex = -1;
        const events = newOrderInfo[orderInfoIndex].event || [];

        for (let i = events.length - 2; i >= 0; i--) {
          const prevStatus = events[i]?.status;
          if (
            prevStatus &&
            newOrderInfo[orderInfoIndex].eventPredictor.includes(prevStatus)
          ) {
            lastMatchedIndex =
              newOrderInfo[orderInfoIndex].eventPredictor.indexOf(prevStatus);
            break;
          }
        }

        // Insert the new status right after the last matched status in eventPredictor
        if (lastMatchedIndex !== -1) {
          const newEventPredictor = [...newOrderInfo[orderInfoIndex].eventPredictor];
          newEventPredictor.splice(lastMatchedIndex + 1, 0, status);
          newOrderInfo[orderInfoIndex].eventPredictor = newEventPredictor;
        }
      }

      // Update the document
      await updateDoc(orderRef, {
        orderInfo: newOrderInfo,
      });
    }
  } catch (error) {
    console.error("Error adding event:", error);
    throw error;
  }
};

const updateOrderInfo = async (
  price: number,
  note: string,
  order: Order,
  index: number,
  database: string
) => {
  try {
    const orderRef = doc(db, database, order.id);
    const newOrderInfo = [...order.orderInfo];
    const currentItem = newOrderInfo[index];
    const handler = getCurrentUserEmail() || "CRITICAL ERROR: NO USER";

    newOrderInfo[index] = {
      ...currentItem,
      tailorNote: note,
    };

    await updateDoc(orderRef, {
      orderInfo: newOrderInfo,
      updatedAt: new Date().toISOString(),
    });

    const changes: string[] = [];
    if (price !== currentItem.price_nok) {
      changes.push(`\n\nPrice changed from ${currentItem.price_nok} to ${price}`);
    }
    if (note !== currentItem.tailorNote) {
      changes.push("Note updated");
    }

    // Add event if there were changes
    if (changes.length > 0) {
      await addOrderEvent(
        order.id,
        handler,
        currentItem.tailorNote || "",
        changes,
        "deviation",
        index,
        database,
        currentItem.price_nok,
        price
      );
    }
  } catch (error) {
    console.error("Error updating orderInfo:", error);
    throw error;
  }
};

// sendToNewTailor er for framtidig utvikling. brukes ikke i dag.
export const finalEventsStateTailor = ["finished", "sendToNewTailor", "customerAproves"];

export const finalEventsStateCustomer = ["customerAproves", "finished"];

export const finalEventsCanceldState = [
  "cancelledByTailor",
  "cancelled",
  "cancelledByCustomer",
];

const externalInteractions = ["deviation"];

const OrderCard: React.FC<OrderCardProps> = ({ order, activity, index, database }) => {
  const { t } = useTranslation();
  const [avvikModal, setAvvikModal] = useState(false);
  const [changeOrderModal, setChangeOrderModal] = useState(false);
  const [cancelledModal, setCancelledModal] = useState(false);
  const [activeOrderInfo, setActiveOrderInfo] = useState<number>(-1);

  const [localTailorNotes, setLocalTailorNotes] = useState<string[]>(
    order.orderInfo.map((item) => item.tailorNote || "")
  );
  const [localNewPrice, setLocalNewPrice] = useState<number[]>(
    order.orderInfo.map((item) => item.price_nok)
  );

  return (
    <div className="mb-16 bg-white">
      {order.orderInfo.map((item, indexInTile) => (
        <>
          {(indexInTile === index || index === -1) && (
            <div key={indexInTile} className="bg-white pb-10">
              {item.imageUrl !== fallbackImageUrl ? (
                <DisplayOrderImage imageUrl={item.imageUrl} />
              ) : (
                <p className="border-black pb-4 pl-4 text-base">
                  <span className="ml-1">{t("workwearOrderPage.orderInformation")}</span>
                </p>
              )}
              {item.event &&
                item.event.length > 0 &&
                finalEventsCanceldState.includes(
                  item.event[item.event.length - 1].status
                ) && (
                  <p className="border-t border-black bg-fikseRed p-4 text-base">
                    <span>{t("orderItemCanceld")}</span>
                  </p>
                )}
              <p className="border-b border-t border-black p-4 text-base">
                <span className="ml-1">{item.problem}</span>
                <span className="ml-1">{t("workwearOrderPage.on")}</span>
                <span className="ml-1">{item.item.toLocaleLowerCase()}</span>
              </p>
              {item.fix_type && (
                <p className="border-b border-black p-4 text-base">
                  <span>{t("workwearOrderPage.fixType")}:</span>
                  <span className="ml-1">{item.fix_type}</span>
                </p>
              )}
              {item.work && item.work !== "N/A" && (
                <p className="border-b border-black p-4 text-base">
                  <span>{t("workwearOrderPage.work")}:</span>
                  <span className="ml-1">{item.work}</span>
                </p>
              )}
              {item.count !== 1 && (
                <p className="border-b border-black p-4 text-base">
                  <span>{t("workwearOrderPage.amount")}:</span>
                  <span className="ml-1">{item.count}</span>
                </p>
              )}
              {order.activity && order.activity.length > 0 && (
                <div className="flex justify-between border-b border-black p-4 text-base">
                  <span className="ml-1">
                    {activity[activity.length - 1].message ||
                      t(`workwearOrderPage.${activity[activity.length - 1].content}`)}
                  </span>
                  <span className="ml-1 text-fikseGrey">
                    {formatTimestamp(
                      activity[activity.length - 1].timestamp ||
                        activity[activity.length - 1].dateTime
                    )}{" "}
                  </span>
                </div>
              )}
              {item.description && item.description !== "N/A" && (
                <p className="border-b border-black bg-fikseBeige p-4 text-base">
                  <span className="ml-1">{item.description}</span>
                </p>
              )}
              {item.customerComment && (
                <p className="border-b border-black bg-fikseBeige p-4 text-base">
                  <span className="ml-1">{item.customerComment}</span>
                </p>
              )}
              {brandPriceVisibility(order.retailId) && (
                <p className="border-b border-black bg-fikseBeige p-4 text-base">
                  {(item.event?.[item.event.length - 1]?.oldPrice &&
                    item.event?.[item.event.length - 1]?.oldPrice) !== item.price_nok ? (
                    <span>{t("workwearOrderPage.estimatedPrice")}:</span>
                  ) : (
                    <span>{t("workwearOrderPage.oldPrice")}:</span>
                  )}

                  {item.event?.map(
                    (event, indexInTile) =>
                      event.oldPrice &&
                      event.oldPrice !== item.price_nok && (
                        <>
                          <span> </span>
                          <span key={indexInTile} className="line-through">
                            {event.oldPrice}
                          </span>
                          <span> {"->"} </span>
                        </>
                      )
                  )}

                  {(item.event?.[item.event.length - 1]?.oldPrice &&
                    item.event?.[item.event.length - 1]?.oldPrice) !== item.price_nok ? (
                    <span className="ml-1">{item.price_nok} kr</span>
                  ) : (
                    <span className="ml-1 line-through">{item.price_nok} kr</span>
                  )}
                </p>
              )}

              {item.tailorNote && (
                <p className="border-b border-black p-4 text-base">
                  <span>{t("tailornote")}:</span>
                  <span className="ml-1">{item.tailorNote}</span>
                </p>
              )}
              {/*Tile som kommer når det er avvik kunden må godta*/}
              <CustomerPrompt
                orderInfo={item}
                index={indexInTile}
                orderID={order.id}
                database={database} // Add this line
                retailId={order.retailId}
              />
              {index >= 0 &&
                order.orderInfo[index].event?.at(-1)?.status ===
                  "orderStateDeliverToTailor" &&
                localStorage.getItem("roles")?.includes("tailor") && (
                  <>
                    <div className="flex w-full border-b border-black bg-fikseBlue p-4 text-base">
                      <button
                        className="border- w-full border-black text-center"
                        onClick={() => {
                          setActiveOrderInfo(indexInTile);
                          setAvvikModal(true);
                        }}
                      >
                        {t("deviationButton")}
                      </button>
                      <ChevronRightIcon aria-hidden="true" />
                    </div>
                    {finalEventsStateTailor.includes(
                      item?.event?.[item.event?.length - 1]?.status ?? ""
                    ) &&
                      item?.event?.[item.event?.length - 1]?.status !==
                        "subOrderFinishedAtTailor" && (
                        <div className="flex w-full border-b border-black bg-fikseGreen p-4 text-base">
                          <button
                            className="border- w-full border-black text-center"
                            onClick={async () => {
                              await addOrderEvent(
                                order.id,
                                localStorage.getItem("verifiedUid") || "unknown",
                                "subOrderFinishedAtTailor",
                                ["finished"],
                                "finished",
                                activeOrderInfo,
                                database
                              );
                              window.location.reload();
                            }}
                          >
                            {t("finishedArticeButton¢")}
                          </button>
                          <ChevronRightIcon aria-hidden="true" />
                        </div>
                      )}
                  </>
                )}
            </div>
          )}
        </>
      ))}
      {/*Aving endring modal*/}
      <ModalTile
        open={avvikModal}
        onClose={() => setAvvikModal(false)}
        title={t("deviationButton")}
      >
        <div className="flex w-full border-y border-black text-base">
          <button
            className="border- w-full border-black bg-fikseBlue p-4 text-center"
            onClick={() => {
              setAvvikModal(false);

              setChangeOrderModal(true);
            }}
          >
            {t("changeOrderButton")}
          </button>
        </div>{" "}
        <div className="w-ful flex text-base">
          <button
            className="w-full bg-fikseRed p-4 text-center"
            onClick={() => {
              setAvvikModal(false);
              setCancelledModal(true);
            }}
          >
            {t("cancelOrderButton")}
          </button>
        </div>{" "}
      </ModalTile>
      {/*avvik kanselering modal*/}
      <ModalTile
        open={changeOrderModal}
        onClose={() => setChangeOrderModal(false)}
        title={t("deviationButton")}
      >
        <div className="pb-5 pl-5 text-base">Beskrive av avviket synlig for kunde</div>
        {order.orderInfo.map((item, indexInModal) => (
          <>
            {activeOrderInfo === indexInModal && (
              <div key={indexInModal}>
                <div className="border-t border-black p-5 text-left text-base">
                  {t("deviationModalNote")}:
                </div>
                <textarea
                  className="w-full p-4 text-left"
                  placeholder="Skriv beskrivelse her"
                  rows={1}
                  value={localTailorNotes[indexInModal]}
                  onChange={(e) => {
                    const newNotes = [...localTailorNotes];
                    newNotes[indexInModal] = e.target.value;
                    setLocalTailorNotes(newNotes);
                  }}
                />
                <div className="border-t border-black p-5 text-left text-base">
                  {t("newPrice")}:
                </div>
                <input
                  type="number"
                  className="w-full p-4 text-left"
                  placeholder="Skriv ny pris her"
                  value={localNewPrice[indexInModal]}
                  onChange={(e) => {
                    const newNotes = [...localNewPrice];
                    newNotes[indexInModal] = e.target.valueAsNumber;
                    setLocalNewPrice(newNotes);
                  }}
                />
              </div>
            )}
          </>
        ))}
        <div className="border-y border-black p-4 text-center">
          Ved å markere avvik, får kunden mulighet til å godta eller avslå endringen som
          er gjort. Kunden vil få varsel om endringen og mulighet til å godta eller avslå.
          Avlsått endring vil bli kanselert.
        </div>
        <div
          className="full-w border-black bg-fikseGreen p-4 text-center text-base"
          onClick={async () => {
            setChangeOrderModal(false);
            await updateOrderInfo(
              localNewPrice[activeOrderInfo],
              localTailorNotes[activeOrderInfo],
              order,
              activeOrderInfo,
              database
            );
            await deviateNoticeToCustomer(database, order.id, t);
            window.location.reload();
          }}
        >
          {t("Lagre endringer")}
        </div>{" "}
      </ModalTile>
      <ModalTile
        open={cancelledModal}
        onClose={() => setCancelledModal(false)}
        title={t("CancelOrderTempTitle")}
      >
        <div className="pb-5 pl-5 text-base">Beskrive av avviket synlig for kunde</div>
        {order.orderInfo.map((item, indexInModal) => (
          <>
            {activeOrderInfo === indexInModal && (
              <div key={indexInModal}>
                <div className="border-t border-black p-5 text-left text-base">
                  {t("Notat til artikkel, skriv begrunnelse på kanselering¢")}:
                </div>
                <textarea
                  className="w-full p-4 text-left"
                  placeholder="Skriv beskrivelse her¢"
                  rows={1}
                  value={localTailorNotes[indexInModal]}
                  onChange={(e) => {
                    const newNotes = [...localTailorNotes];
                    newNotes[indexInModal] = e.target.value;
                    setLocalTailorNotes(newNotes);
                  }}
                />
              </div>
            )}
          </>
        ))}
        <div className="border-y border-black p-4 text-left">
          Ved å markere kanselere artikkel, får kunden IKKE mulighet til å godta eller
          avslå endringen som er gjort. Kunden vil bli varslet om at artikkelen er
          kanselert.
        </div>
        <div
          className="full-w border-black bg-fikseRed p-4 text-center text-base"
          onClick={async () => {
            setChangeOrderModal(false);
            setCancelledModal(false);

            await addOrderEventSimple(
              order.id,
              "cancelledByTailor",
              activeOrderInfo,
              database
            );
            console.log("OC1");
            await cancelOrderNoticeToCustomer(database, order.id, t);
            window.location.reload();
          }}
        >
          {t("CancelOrderTempTitle")}
        </div>{" "}
      </ModalTile>
      <div className="block md:hidden">
        <OrderCardDetails order={order} />
      </div>

      {order.qrCodeUrl && false && (
        <div
          className={`grid grid-cols-1 ${
            order.orderInfo.some((info) => info.imageUrl !== fallbackImageUrl)
              ? ""
              : "md:pt-[7rem]"
          }`}
        >
          <p className="p-4 pb-0 text-base">
            <span>{t("workwearOrderPage.scanQrCode")}</span>
          </p>
          <img src={order.qrCodeUrl} alt="QR Code" className="h-48 w-48 object-contain" />
        </div>
      )}
    </div>
  );
};

export default OrderCard;
