import React, { useState } from "react";

import SignUpComercial from "../components/SignUpComercial";
import TailorPageBackground from "../images/TailorPageBackground.jpg";
import { ReactComponent as FikseLogo } from "../images/fikse-logo-White.svg";

const TailorPage: React.FC = () => {
  const [openSignUpComercial, setOpenSignUpComercial] = useState(false);

  return (
    <div
      style={{
        backgroundImage: `url(${TailorPageBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <div className="flex h-[60%] flex-col justify-center pl-8">
        <FikseLogo className="w-1/4" />

        <div className="mt-4">
          <button
            className="border border-black bg-white p-4 text-black hover:bg-gray-100"
            onClick={() => setOpenSignUpComercial(true)}
          >
            Register as a tailor
          </button>
        </div>
      </div>
      {openSignUpComercial && (
        <SignUpComercial
          open={openSignUpComercial}
          onClose={() => setOpenSignUpComercial(false)}
        />
      )}
    </div>
  );
};

export default TailorPage;
