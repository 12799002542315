import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { app, db } from "../../firebase";

export const initiateLateCheckout = async (
  orderId: string,
  database: string = "b2cOrders"
) => {
  const functions = getFunctions(app, "europe-west1");
  console.log("Initiating late checkout for order", orderId);

  try {
    const orderRef = doc(db, database, orderId);
    const orderSnap = await getDoc(orderRef);

    if (!orderSnap.exists()) {
      throw new Error("Order does not exist");
    }

    const orderData = orderSnap.data();
    console.log("Order data", orderData.contactInfo.phone);

    const createVippsCheckout = httpsCallable(
      functions,
      "createVippsCheckoutFiksePortal0225"
    );

    const result = await createVippsCheckout(orderData);

    if (result.data && typeof result.data === "object" && "url" in result.data) {
      const paymentUrl = (result.data as { url: string }).url;
      window.location.href = paymentUrl;
    } else {
      throw new Error("Unexpected response from Vipps checkout function");
    }
  } catch (error) {
    console.error("Error initiating late checkout:", error);
    throw error;
  }
};

/* Slett under 28. Mars 2025
export const initiateLateCheckout = async (
  orderId: string,
  database: string = "b2cOrders"
) => {
  const functions = getFunctions(app, "europe-west1");
  console.log("Initiating late checkout for order", orderId);

  try {
    const orderRef = doc(db, database, orderId);
    const orderSnap = await getDoc(orderRef);

    if (!orderSnap.exists()) {
      throw new Error("Order does not exist");
    }

    const orderData = orderSnap.data();

    // More detailed validation with better error messages
    if (!orderData) {
      throw new Error("Order data is empty");
    }

    if (!orderData.contactInfo?.phone) {
      throw new Error("Phone number is missing from order");
    }

    if (!orderData.totalPrice && orderData.totalPrice !== 0) {
      throw new Error("Total price is missing from order");
    }

    const createVippsCheckout = httpsCallable(
      functions,
      "createVippsCheckoutFiksePortal"
    );

    // Ensure we're sending the complete data structure expected by the Cloud Function
    const checkoutData = {
      ...orderData,
      id: orderId, // The Cloud Function expects this
      contactInfo: {
        ...orderData.contactInfo,
        phone: orderData.contactInfo.phone.replace(/\s+/g, ""), // Remove any whitespace from phone number
      },
      totalPrice: Number(orderData.totalPrice), // Ensure it's a number
      database, // Include the database parameter
    };

    console.log("Calling Vipps checkout with data:", checkoutData);

    const result = await createVippsCheckout(checkoutData);

    console.log("Received response from Vipps checkout:", result);

    if (!result.data || typeof result.data !== "object") {
      throw new Error("Invalid response from Vipps checkout function");
    }

    if (!("url" in result.data)) {
      throw new Error("Missing payment URL in response");
    }

    const paymentUrl = (result.data as { url: string }).url;
    window.location.href = paymentUrl;
  } catch (error) {
    console.error("Error initiating late checkout:", error);

    // More detailed error handling
    if (error instanceof Error) {
      throw new Error(
        `Vipps payment failed: ${error.message}. Please try again or contact support.`
      );
    }

    throw new Error(
      "Unable to create Vipps payment. Please try again or contact support."
    );
  }
};
*/
