import React, { ReactNode } from "react";

interface HeaderProps {
  text?: string;
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ text, className, level = 1, children }) => {
  const HeaderTag = `h${level}` as keyof JSX.IntrinsicElements;

  // Default className that will be applied to all headers
  const defaultClassName = "text-4xl p-4";

  // Combine default with custom className if provided
  const finalClassName = className
    ? `${defaultClassName} ${className}`
    : defaultClassName;

  return <HeaderTag className={finalClassName}>{children || text}</HeaderTag>;
};

export default Header;
