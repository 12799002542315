import { Fragment, useEffect, useState } from "react";

import { Disclosure } from "@headlessui/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Import i18n hook
import { useData } from "../DataContext";
import { signOutUser } from "../auth";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import { ReactComponent as XMarkIcon } from "../images/icons-fikse/close.svg";
import { ReactComponent as HamburgerIcon } from "../images/icons-fikse/hamburger.svg";
import {
  accountHasAccessToDashboard,
  checkIfUserIsAdmin,
  checkIfUserIsRetailEmployee,
  checkIfUserIsRetailModerator,
  getUserRetailConnections,
} from "../pages/DashboardPage";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Button } from "../ui/button";
import useAuth from "../useAuth";
import ModalTile from "./ModalTile";
import LoggInModal from "./SignInModal";
import SignInWorkaround from "./SignInWorkaround";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { userData, setUserId } = useData();
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const { i18n } = useTranslation(); // Destructure i18n
  const [hasDashboardAccess, setHasDashboardAccess] = useState(false);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  // Add state for retail connections
  const [retailConnections, setRetailConnections] = useState<
    { id: string; name: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  // Add state for user permissions
  const [userPermissions, setUserPermissions] = useState({
    canAccessOverview: false,
    canAccessOrders: false,
    canAccessRequests: false,
    canAccessSale: false,
    canAccessPeople: false,
    canAccessInvoicing: false,
  });

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogoutOrLogin = () => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("uid", "");
      localStorage.setItem("verifiedUid", "");
      localStorage.setItem("department", "");
      signOutUser();
      window.location.reload();
    } else {
      setShowSignIn(true);
    }
    setMenuVisible(false);
  };

  const user = useAuth();
  const { t } = useTranslation();

  const handleLogoClick = () => {
    // Redirect to the root of the current domain or subdomain
    if (window.location.pathname.includes("/dashboard")) {
      window.location.href = `${window.location.origin}/dashboard`;
    } else {
      window.location.href = `${window.location.origin}/`;
    }
  };

  function getFormattedEmail(): string {
    // Retrieve the email from localStorage
    const email = localStorage.getItem("verifiedUid");

    if (!email) {
      return "";
    }
    // Strip the @ and everything to the right
    const strippedEmail = email.split("@")[0];

    // Replace "." with spaces
    const formattedEmail = strippedEmail.replace(/\./g, " ");

    return formattedEmail;
  }

  const urlPath = useLocation().pathname;

  console.log("urlPath", urlPath);
  const hideFikseLogo =
    urlPath !== "/" && (urlPath === "/dashboard" || urlPath.startsWith("/dashboard"));
  console.log("hideFikseLogo", hideFikseLogo);

  // Add useEffect to check dashboard access when user changes
  useEffect(() => {
    const checkDashboardAccess = async () => {
      const email = localStorage.getItem("uid");
      if (email) {
        const hasAccess = await accountHasAccessToDashboard(email);
        setHasDashboardAccess(hasAccess);
      } else {
        setHasDashboardAccess(false);
      }
    };

    checkDashboardAccess();
  }, [user]);

  // Add useEffect to fetch retail connections
  useEffect(() => {
    const fetchRetailConnections = async () => {
      const email = localStorage.getItem("uid");
      if (email) {
        setIsLoading(true);
        try {
          const connections = await getUserRetailConnections(email);
          setRetailConnections(connections);

          // Also fetch user permissions
          const permissions = await getDashboardPermissions(email);
          setUserPermissions(permissions);
        } catch (error) {
          console.error("Error fetching retail connections:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchRetailConnections();
  }, [user]);

  // Determine what dashboard sections a user has access to
  const getDashboardPermissions = async (email: string) => {
    if (!email)
      return {
        canAccessOverview: false,
        canAccessOrders: false,
        canAccessRequests: false,
        canAccessSale: false,
        canAccessPeople: false,
        canAccessInvoicing: false,
      };

    try {
      const isAdmin = await checkIfUserIsAdmin(email);
      const isRetailModerator = await checkIfUserIsRetailModerator(email);
      const isRetailEmployee = await checkIfUserIsRetailEmployee(email);

      return {
        canAccessOverview: isAdmin || isRetailModerator || isRetailEmployee,
        canAccessOrders: isAdmin || isRetailModerator || isRetailEmployee,
        canAccessRequests: isAdmin || isRetailModerator,
        canAccessSale: isAdmin || isRetailModerator,
        canAccessPeople: isAdmin || isRetailModerator,
        canAccessInvoicing: isAdmin || isRetailModerator,
      };
    } catch (error) {
      console.error("Error checking dashboard permissions:", error);
      return {
        canAccessOverview: false,
        canAccessOrders: false,
        canAccessRequests: false,
        canAccessSale: false,
        canAccessPeople: false,
        canAccessInvoicing: false,
      };
    }
  };

  const menuItems = [
    {
      label: t("navBar.dashboard"),
      href: "/dashboard",
      show:
        hasDashboardAccess ||
        localStorage.getItem("uid")?.endsWith("mari.guttormsen@uke.oslo.kommune.no"),
    },
    {
      label: t("navBar.aboutUs"),
      href: "#about",
      show: true,
    },
    {
      label: t("navBar.ourLocations"),
      href: "/locations",
      show: true,
    },
    {
      label: t("navBar.pricing"),
      href: "#pricing",
      show: true,
    },
  ];

  return (
    <Disclosure as="nav" className="z-20 bg-white">
      {({ open }: any) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center justify-between">
                {!hideFikseLogo && (
                  <div
                    className="flex cursor-pointer md:justify-start"
                    onClick={handleLogoClick}
                  >
                    <FikseLogo className="scale- transform" height="25px" />
                  </div>
                )}
                {user && (
                  <p className="text-s pl-4 sm:pl-7 sm:text-base">
                    {t("mobileView.greeting")} {getFormattedEmail()}.
                  </p>
                )}
              </div>

              <div className="flex items-center">
                <div className="md:hidden">
                  <button
                    onClick={() => {
                      setMenuVisible(!menuVisible);
                      setIsMenuModalOpen(true);
                    }}
                    className="inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#8CD7FF]"
                  >
                    <span className="sr-only">Open main menu</span>
                    {menuVisible ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <HamburgerIcon aria-hidden="true" />
                    )}
                  </button>
                </div>
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  {localStorage.getItem("uid") ? (
                    <>
                      {/*<button
                      type="button"
                      className="gap-x-1. relative inline-flex items-center border border-black px-3 py-2 text-sm shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8CD7FF]"
                      onClick={handleLogoutOrLogin}
                    >
                      {localStorage.getItem("uid")}
                    </button>*/}
                    </>
                  ) : (
                    <button
                      type="button"
                      className="gap-x-1. relative inline-flex items-center border border-black px-3 py-2 text-sm shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8CD7FF]"
                      onClick={handleLogoutOrLogin}
                    >
                      {t("navBar.logIn")}
                    </button>
                  )}
                  <div className="relative ml-3">
                    <button
                      onClick={() => setIsMenuModalOpen(true)}
                      className="relative flex bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#8CD7FF] focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <HamburgerIcon aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalTile
            open={isMenuModalOpen}
            onClose={() => {
              setIsMenuModalOpen(false);
              setMenuVisible(false);
            }}
            title={
              t("hello") +
              ", " +
              getFormattedEmail().charAt(0).toUpperCase() +
              getFormattedEmail().slice(1).toLowerCase()
            }
          >
            <div className="z-[200] flex flex-col border-t border-black">
              {/* Add Retail Connections Accordion */}
              {isLoading ? (
                <div className="flex justify-center py-4">
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
                </div>
              ) : retailConnections.length > 0 ? (
                <Accordion type="single" collapsible className="w-full text-base">
                  {retailConnections.map((retail) => (
                    <AccordionItem key={retail.id} value={retail.id}>
                      <AccordionTrigger className="pr-8 text-base">
                        {retail.name}
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="flex flex-col">
                          {userPermissions.canAccessOverview && (
                            <Button
                              variant="outline"
                              className="w-full justify-between py-4 pl-14 pr-6"
                              onClick={() => {
                                window.location.href = `/dashboard/retail/overview?retailId=${retail.id}`;
                                setIsMenuModalOpen(false);
                              }}
                            >
                              {t("navBar.overview")}
                              <ChevronRightIcon className="!size-auto h-12 w-12" />
                            </Button>
                          )}

                          {userPermissions.canAccessOrders && (
                            <Button
                              variant="outline"
                              className="w-full justify-between py-4 pl-14 pr-6"
                              onClick={() => {
                                window.location.href = `/dashboard/retail/orders?retailId=${retail.id}`;
                                setIsMenuModalOpen(false);
                              }}
                            >
                              {t("navBar.orders")}
                              <ChevronRightIcon className="!size-auto h-12 w-12" />
                            </Button>
                          )}

                          {userPermissions.canAccessRequests && (
                            <Button
                              variant="outline"
                              className="w-full justify-between py-4 pl-14 pr-6"
                              onClick={() => {
                                window.location.href = `/dashboard/retail/requests?retailId=${retail.id}`;
                                setIsMenuModalOpen(false);
                              }}
                            >
                              {t("navBar.requests")}
                              <ChevronRightIcon className="!size-auto h-12 w-12" />
                            </Button>
                          )}

                          {userPermissions.canAccessSale && (
                            <Button
                              variant="outline"
                              className="w-full justify-between py-4 pl-14 pr-6"
                              onClick={() => {
                                window.location.href = `/dashboard/retail/sale?retailId=${retail.id}`;
                                setIsMenuModalOpen(false);
                              }}
                            >
                              {t("navBar.sale")}
                              <ChevronRightIcon className="!size-auto h-12 w-12" />
                            </Button>
                          )}

                          {userPermissions.canAccessPeople && (
                            <Button
                              variant="outline"
                              className="w-full justify-between py-4 pl-14 pr-6"
                              onClick={() => {
                                window.location.href = `/dashboard/retail/people?retailId=${retail.id}`;
                                setIsMenuModalOpen(false);
                              }}
                            >
                              {t("navBar.people")}
                              <ChevronRightIcon className="!size-auto h-12 w-12" />
                            </Button>
                          )}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : null}

              {/* Existing Menu Items */}
              {menuItems.map(
                (item) =>
                  item.show && (
                    <Button
                      key={item.label}
                      className="w-full justify-between border-b border-black"
                      onClick={() => {
                        window.location.href = item.href;
                        setIsMenuModalOpen(false);
                      }}
                    >
                      {item.label} <ChevronRightIcon className="!size-auto h-12 w-12" />
                    </Button>
                  )
              )}
              <Button
                variant="outline"
                className="w-full justify-start px-6 py-4"
                onClick={() => {
                  handleLogoutOrLogin();
                  setIsMenuModalOpen(false);
                }}
              >
                {localStorage.getItem("uid") ? t("navBar.logOut") : t("navBar.logIn")}
              </Button>
            </div>
          </ModalTile>

          {/* commenting this out 3.03.2025 */}
          {/* {isOrderModalOpen && (
            <OrderModal
              open={isOrderModalOpen}
              onClose={() => setOrderModalOpen(false)}
            />
          )} */}
          {showSignIn && (
            <LoggInModal open={showSignIn} onClose={() => setShowSignIn(false)} />
          )}
        </>
      )}
    </Disclosure>
  );
}
