import React, { useEffect, useState } from "react";

import {
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { getCurrentUserEmail, getCurrentUserEmailAsync } from "../auth";
import { ExpandedOrderDetails } from "../components/ExpandedOrderDetails";
import ModalTile from "../components/ModalTile";
import { deviateNoticeToCustomer } from "../components/contact";
import { getOrder } from "../components/databaseHelper";
import LoadingSpinner from "../components/fikseLoadingLetter";
import { auth, db } from "../firebase";
import { ReactComponent as backpack_rabit } from "../images/Illustration/FikseFigures/backpack_rabit.svg";
import { ReactComponent as boy_with_phone } from "../images/Illustration/FikseFigures/boy_with_phone.svg";
import { ReactComponent as bunad_lady } from "../images/Illustration/FikseFigures/bunad_lady.svg";
import { ReactComponent as christian_waaler } from "../images/Illustration/FikseFigures/christian_waaler.svg";
import { ReactComponent as dog_boy_with_glasses } from "../images/Illustration/FikseFigures/dog_boy_with_glasses.svg";
import { ReactComponent as footer_woman_cat } from "../images/Illustration/FikseFigures/footer-woman-cat.svg";
import { ReactComponent as mouse_in_pants } from "../images/Illustration/FikseFigures/mouse_in_pants.svg";
import { ReactComponent as PlusIcon } from "../images/icons-fikse/add.svg";
import { ReactComponent as ChevronIcon } from "../images/icons-fikse/chevron_clean_size.svg";
import { ReactComponent as InfoIcon } from "../images/icons-fikse/info.svg";
import {
  Order,
  OrderInfo,
  STATES_TAILOR_CANNOT_UNASSIGN,
  STATES_TAILOR_CAN_UPDATE,
} from "../types/Order";
import { TailorInfo, tailorPersonDisplayName } from "../types/tailor";
import { Button } from "../ui/button";
import Header from "../ui/header";
import { ResizableHandle, ResizablePanel } from "../ui/resizeablePanelGroup";
import { ResizablePanelGroup } from "../ui/resizeablePanelGroup";
import Text from "../ui/text";

//Picks one of the icons.
const FikseHashSumLogo = ({ orderId }: { orderId: string }) => {
  // Array of all available Fikse illustrations
  const illustrations = [
    backpack_rabit,
    boy_with_phone,
    bunad_lady,
    christian_waaler,
    dog_boy_with_glasses,
    footer_woman_cat,
    mouse_in_pants,
  ];

  // Create a numeric hash from the orderId string
  const hashCode = orderId.split("").reduce((acc, char) => {
    return acc + char.charCodeAt(0);
  }, 0);

  // Use modulo to select an illustration based on the hash
  const selectedIndex = hashCode % illustrations.length;
  let SelectedIllustration = illustrations[selectedIndex];

  if (orderId === "WONKA") {
    SelectedIllustration = illustrations[4];
  }

  return <SelectedIllustration className="mt-10 flex h-full w-full items-center p-20" />;
};

async function getTailorHouseId(): Promise<{
  tailorHouseId: string | null;
  tailorHouseName: string | null;
}> {
  try {
    const currentUserEmail = (await getCurrentUserEmailAsync()) || "";
    if (!currentUserEmail) {
      console.error("Failed to get current user email");
      return { tailorHouseId: null, tailorHouseName: null };
    }

    // Get all tailors from the collection
    const tailorsRef = collection(db, "tailors");

    // First check if user's email matches contactPerson.email
    let tailorQueryContactPerson = query(
      tailorsRef,
      where("contactPerson.email", "==", currentUserEmail)
    );
    let tailorSnapshot = await getDocs(tailorQueryContactPerson);

    // If no match, check if email is in preVerifiedEmails array
    if (tailorSnapshot.empty) {
      let tailorQueryPreVerified = query(
        tailorsRef,
        where("preVerifiedEmails", "array-contains", currentUserEmail)
      );
      tailorSnapshot = await getDocs(tailorQueryPreVerified);
    }

    // If still no match, check if email is in moderatorEmails array
    if (tailorSnapshot.empty) {
      let tailorQueryModerator = query(
        tailorsRef,
        where("moderatorEmails", "array-contains", currentUserEmail)
      );
      tailorSnapshot = await getDocs(tailorQueryModerator);
    }

    // If we found a match in any of the queries, return the tailorHouseId
    if (!tailorSnapshot.empty) {
      const tailorDoc = tailorSnapshot.docs[0];
      return {
        tailorHouseId: tailorDoc.id,
        tailorHouseName: tailorDoc.data().name || "",
      };
    }

    console.error("No tailor found for email:", currentUserEmail);
    return { tailorHouseId: "", tailorHouseName: "" };
  } catch (error) {
    console.error("Error getting tailor house ID:", error);
    return { tailorHouseId: "", tailorHouseName: "" };
  }
}

async function removeDesignatedTailor(orderId: string, focusedItemNonZeroIndex: number) {
  const orderInfoIndex = focusedItemNonZeroIndex - 1;
  const tailorInfoDocRef = doc(
    db,
    "orders",
    orderId,
    "tailorInfo",
    orderInfoIndex.toString()
  );

  try {
    const tailorInfoSnap = await getDoc(tailorInfoDocRef);

    if (!tailorInfoSnap.exists()) {
      throw new Error("Tailor info document not found");
    }

    // Remove tailor person fields while keeping other fields
    await updateDoc(tailorInfoDocRef, {
      tailorPersonEmail: deleteField(),
      tailorPersonPhone: deleteField(),
      tailorPersonName: deleteField(),
      tailorPersonId: deleteField(),
      timestamp: new Date().toISOString(),
    });

    console.log("Successfully removed designated tailor");
    return true;
  } catch (error) {
    console.error("Error removing designated tailor:", error);
    throw error;
  }
}

async function setYourselfAsDesignatedTailor(
  orderId: string,
  focusedItemNonZeroIndex: number
) {
  const { tailorHouseId, tailorHouseName } = await getTailorHouseId();

  if (!tailorHouseId) throw new Error("Tailor house ID not found");
  if (!tailorHouseName) throw new Error("Tailor house name not found");

  const orderInfoIndex = focusedItemNonZeroIndex - 1;

  try {
    if (orderInfoIndex < 0) {
      throw new Error(`Invalid orderInfoIndex: ${orderInfoIndex}`);
    }

    // Create reference to the tailorInfo collection
    const tailorInfoCollectionRef = collection(db, "orders", orderId, "tailorInfo");

    // Use the orderInfoIndex as the document name
    const documentId = orderInfoIndex.toString();

    // Get the tailor house information for the current user
    const tailorDocumentInfo: TailorInfo = {
      orderId: orderId,
      tailorPersonEmail:
        (await getCurrentUserEmailAsync()) ||
        "Error pulling getCurrentUserEmailAsync in TailorOrderView.tsx",
      tailorPersonId:
        auth.currentUser?.uid ||
        "Error pulling auth.currentUser?.uid in TailorOrderView.tsx",
      timestamp: new Date().toISOString(),
      tailorHouseId: tailorHouseId,
      tailorHouseName: tailorHouseName,
    };

    // Create the document with orderInfoIndex as the document ID
    const tailorDocumentRef = doc(tailorInfoCollectionRef, documentId);
    await setDoc(tailorDocumentRef, tailorDocumentInfo);

    console.log(
      `Successfully set tailor document in tailorInfo collection with ID: ${documentId}`
    );
  } catch (error) {
    console.error("Error updating tailorInfo document:", error);
    throw error;
  }
}

// Format the date to a readable format
const formatDate = (dateValue: any): string => {
  if (!dateValue) return "No estimated finish date";

  try {
    // Handle Firebase Timestamp format
    let date;

    // Check if it's a Firebase Timestamp (has seconds and nanoseconds or toDate method)
    if (dateValue.toDate && typeof dateValue.toDate === "function") {
      // Use the toDate() method if available
      date = dateValue.toDate();
    } else if (dateValue.seconds !== undefined && dateValue.nanoseconds !== undefined) {
      // Create date from seconds and nanoseconds
      date = new Date(dateValue.seconds * 1000 + dateValue.nanoseconds / 1000000);
    } else {
      // Try as regular date string/object
      date = new Date(dateValue);
    }

    if (isNaN(date.getTime())) return "Invalid date";

    // Format: DD.MM.YYYY HH:MM
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  } catch (error) {
    console.error("Error formatting date:", error, dateValue);
    return "Error formatting date";
  }
};

export const TailorOrderView = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [noOrder, setNoOrder] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const [tailorInfo, setTailorInfo] = useState<TailorInfo | null>(null);
  const [thisOrderInfo, setThisOrderInfo] = useState<OrderInfo | null>(null);
  const [tailorName, setTailorName] = useState<string | null>(null);
  const [showUnAssignBox, setShowUnAssignBox] = useState(false);
  const [showExpandedOrderDetails, setShowExpandedOrderDetails] = useState(false);

  // New state variables for deviation handling
  const [showDeviationModal, setShowDeviationModal] = useState(false);
  const [showChangeOrderModal, setShowChangeOrderModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [tailorNote, setTailorNote] = useState("");
  const [newPrice, setNewPrice] = useState<number>(0);

  const [searchParams] = useSearchParams();
  const { orderId } = useParams<{ orderId: string }>();
  const itemInOrder = searchParams.get("item");

  const orderInfoIndex = Number(itemInOrder) - 1;

  const fetchTailorInfo = async () => {
    try {
      if (!orderId) return;
      const tailorInfoRef = doc(
        db,
        "orders",
        orderId,
        "tailorInfo",
        orderInfoIndex.toString()
      );
      const tailorInfoSnap = await getDoc(tailorInfoRef);
      const tailorInfoFromFB = tailorInfoSnap.data() as TailorInfo;
      setTailorInfo(tailorInfoFromFB);
      console.log("tailorInfo", order);
    } catch (error) {
      console.error("Error fetching tailor info:", error);
    }
  };

  const fetchOrder = async () => {
    try {
      if (!orderId) return;
      const orderRef = doc(db, "orders", orderId);
      const orderSnap = await getDoc(orderRef);
      const order = orderSnap.data() as Order;
      const bufferThisOrderInfo = order.orderInfo[orderInfoIndex];
      console.log("bufferThisOrderInfo", bufferThisOrderInfo);
      setOrder(order);
      setThisOrderInfo(bufferThisOrderInfo);
      // Initialize deviation form values from the order info
      if (bufferThisOrderInfo) {
        setNewPrice(bufferThisOrderInfo.price_nok);
        setTailorNote(bufferThisOrderInfo.tailorNote || "");
      }
      setLoading(false);
    } catch (error) {
      setNoOrder(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAndSetTailorName = async () => {
      setTailorName(await tailorPersonDisplayName(tailorInfo?.tailorPersonId || ""));
    };
    if (tailorInfo?.tailorPersonId) {
      fetchAndSetTailorName();
    }
  }, [tailorInfo]);

  useEffect(() => {
    console.log("TailorOrderView");
    fetchTailorInfo();

    if (!orderId) {
      setNoOrder(true);
      setLoading(false);
      return;
    }

    console.log("order222", order);

    fetchOrder();
  }, []);

  {
    /*Fetching data*/
  }
  if (loading) return <LoadingSpinner />;

  if (noOrder || orderId === undefined || !order) {
    console.log("noOrder", noOrder);
    console.log("orderId", orderId);
    console.log("order", order);
    console.log("thisOrderInfo", thisOrderInfo);
    return <Button variant="warning">{t("tailorView.noOrderFound")}</Button>;
  }

  const AssignButton = () => {
    return (
      <>
        {tailorInfo?.tailorPersonId ? (
          <Button
            onClick={async () => {
              console.log("Setting yourself as designated tailor");
              setShowUnAssignBox(true);
            }}
            variant="warning"
            className="w-full justify-between border-b-0 bg-fikseRed pl-6 text-xl"
          >
            {t("tailorView.deAssign")}
          </Button>
        ) : (
          <Button
            variant="warning"
            onClick={async () => {
              console.log("Setting yourself as designated tailor");
              await setYourselfAsDesignatedTailor(orderId, Number(itemInOrder));
              await fetchTailorInfo();
            }}
            className="w-full justify-between border-b-0 bg-fikseGreen pl-6 text-xl"
          >
            {t("tailorView.assign")}
            <PlusIcon className="!size-auto h-10 w-10" />
          </Button>
        )}
      </>
    );
  };

  const NextEventPredictorState = () => {
    if (!thisOrderInfo?.event?.length) return null;

    const updateOrderStatus = async () => {
      try {
        if (!orderId) return;

        await runTransaction(db, async (transaction) => {
          // Get the latest version of the order document
          const orderRef = doc(db, "orders", orderId);
          const orderSnap = await transaction.get(orderRef);

          if (!orderSnap.exists()) {
            throw new Error("Order document does not exist");
          }

          // Get the current order data
          const orderData = orderSnap.data() as Order;
          const orderInfoIndex = Number(itemInOrder) - 1;

          // Make a copy of the orderInfo array from the document
          const updatedOrderInfo = [...orderData.orderInfo];

          // Get the current item
          const currentItem = updatedOrderInfo[orderInfoIndex];
          if (!currentItem?.eventPredictor) return;

          // Determine current status and next status
          const lastEvent = currentItem.event?.[currentItem.event.length - 1];
          const currentStatus = lastEvent?.status || currentItem.eventPredictor[0];
          const currentIndex = currentItem.eventPredictor.indexOf(currentStatus);
          const newStatus = currentItem.eventPredictor[currentIndex + 1];

          if (!newStatus) return;

          // Update the current item with the new event
          updatedOrderInfo[orderInfoIndex] = {
            ...currentItem,
            event: [
              ...(currentItem.event || []),
              {
                date: new Date().toISOString(),
                status: newStatus,
                handler: getCurrentUserEmail() || "unknown",
              },
            ],
          };

          // Update the document atomically with the transaction
          transaction.update(orderRef, {
            orderInfo: updatedOrderInfo,
            updatedAt: serverTimestamp(),
          });
        });

        // Refresh the order data after the transaction
        await fetchOrder();
      } catch (error) {
        console.error("Failed to update order status:", error);
        // You could add UI error handling here
      }
    };

    return (
      <Button
        className="w-full justify-between border-t border-black bg-fikseGreen text-xl"
        onClick={updateOrderStatus}
      >
        {t(
          `swipeBottomBar.${thisOrderInfo?.eventPredictor[thisOrderInfo?.event?.length]}`
        )}
      </Button>
    );
  };

  if (!thisOrderInfo) {
    return (
      <div className="flex flex-col border-t border-black">
        {order.orderInfo.map((orderInfoInOrder, index) => {
          return (
            <Button
              key={index}
              variant="blue"
              onClick={() => {
                window.location.href = `?item=${index + 1}`;
              }}
            >
              {orderInfoInOrder.item}
            </Button>
          );
        })}
      </div>
    );
  }

  const UnAssignBox = () => {
    return (
      <div className="absolute bottom-0 left-0 right-0 h-40 w-full overflow-hidden border border-gray-300">
        {/* Top section (3/5 or 60%) */}
        <div className="flex h-3/5 w-full items-center justify-center border-y border-black bg-fikseBlue p-6">
          <Text className="whitespace-break-spaces text-center text-base">
            {t("tailorView.unassignConfirm")}
          </Text>
        </div>

        {/* Bottom section (2/5 or 40%) with two equal columns */}
        <div className="flex h-2/5 w-full">
          {/* Bottom Left */}
          <div
            className="flex h-full w-1/2 items-center justify-center border-r border-black bg-fikseGreen p-6"
            onClick={async () => {
              await removeDesignatedTailor(orderId, Number(itemInOrder));
              await fetchTailorInfo();
              setShowUnAssignBox(false);
            }}
          >
            <span className="text-base">{t("tailorView.yes")}</span>
          </div>

          {/* Bottom Right */}
          <div
            className="flex h-full w-1/2 items-center justify-center bg-fikseRed p-6"
            onClick={() => {
              setShowUnAssignBox(false);
            }}
          >
            <span className="text-base">{t("tailorView.no")}</span>
          </div>
        </div>
      </div>
    );
  };

  // Function to update order with deviation information
  const updateOrderWithDeviation = async () => {
    try {
      if (!orderId || !order) return;

      // Create references
      const orderRef = doc(db, "orders", orderId);

      // Create copy of the order info
      const updatedOrderInfo = [...order.orderInfo];
      const currentItem = updatedOrderInfo[orderInfoIndex];

      // Update the tailorNote
      updatedOrderInfo[orderInfoIndex] = {
        ...currentItem,
        tailorNote: tailorNote,
      };

      // Record changes for event tracking
      const changes: string[] = [];
      if (newPrice !== currentItem.price_nok) {
        changes.push(`Price changed from ${currentItem.price_nok} to ${newPrice}`);
      }
      if (tailorNote !== (currentItem.tailorNote || "")) {
        changes.push("Note updated");
      }

      // Update the document
      await updateDoc(orderRef, {
        orderInfo: updatedOrderInfo,
        updatedAt: new Date().toISOString(),
      });

      // Add event if there were changes
      if (changes.length > 0) {
        // Add event for price change
        await addOrderEvent(
          orderId,
          getCurrentUserEmail() || "unknown",
          tailorNote,
          changes,
          "deviation",
          orderInfoIndex
        );

        // Send notification to customer
        await deviateNoticeToCustomer("orders", orderId, t);
      }

      // Refresh order data
      await fetchOrder();
      await fetchTailorInfo();

      // Close modals
      setShowChangeOrderModal(false);
      setShowDeviationModal(false);
    } catch (error) {
      console.error("Error updating order with deviation:", error);
    }
  };

  // Function to add an order event
  const addOrderEvent = async (
    orderId: string,
    handler: string,
    description: string,
    changes: string[],
    status: string,
    orderInfoIndex: number,
    oldPrice?: number,
    newPrice?: number
  ) => {
    try {
      const orderRef = doc(db, "orders", orderId);

      const newEvent = {
        date: new Date().toISOString(),
        status: status,
        description: description + " " + changes.join(", "),
        handler: handler,
        oldPrice: oldPrice,
        newPrice: newPrice,
      };

      // Get the current document
      const orderDoc = await getDoc(orderRef);
      const orderData = orderDoc.data() as Order;

      if (orderData) {
        const newOrderInfo = [...orderData.orderInfo];

        // Initialize events array if it doesn't exist
        if (!newOrderInfo[orderInfoIndex].event) {
          newOrderInfo[orderInfoIndex].event = [];
        }

        // Add the new event
        // We already initialized the event array above if it doesn't exist,
        // so we can safely add the new event
        if (newOrderInfo[orderInfoIndex].event) {
          if (Array.isArray(newOrderInfo[orderInfoIndex].event)) {
            newOrderInfo[orderInfoIndex].event!.push(newEvent);
          } else {
            newOrderInfo[orderInfoIndex].event = [newEvent];
          }
        }

        // Update the document
        await updateDoc(orderRef, {
          orderInfo: newOrderInfo,
        });
      }
    } catch (error) {
      console.error("Error adding event:", error);
      throw error;
    }
  };

  // Component for the deviation button
  const DeviationButton = () => {
    return (
      <Button
        className="w-full justify-between border-b border-black bg-white text-xl"
        onClick={() => setShowDeviationModal(true)}
      >
        {t("tailorView.deviation")}
        <ChevronIcon className="h-14 w-14" />
      </Button>
    );
  };

  return (
    <div>
      {/*  <div className="flex justify-between py-2">
        <Text className="text-2xl">Hello, {tailorName}</Text>
        <Text className="text-2xl">{formatDate(thisOrderInfo.estimatedFinishDate)}</Text>
      </div>*/}

      <Header className="px-8"> {orderId} </Header>
      {true && Number(itemInOrder) > 0 && (
        <div className="mb-2 flex justify-between py-2 pl-4 pr-6">
          <Text className="text-2xl">
            {t("tailorView.orderNumber")}{" "}
            {Number(order.orderInfo.indexOf(thisOrderInfo) + +1) < 10
              ? `0${order.orderInfo.indexOf(thisOrderInfo) + +1}`
              : order.orderInfo.indexOf(thisOrderInfo) + +1}
          </Text>
          <InfoIcon className="h-6 w-6" />
        </div>
      )}

      <div className="border-b border-black px-4 pb-6">
        <div className="mb-4 justify-between">
          <Text className="text-2xl">{thisOrderInfo.item}</Text>
          <Text className="text-2xl">{thisOrderInfo.price_nok + ",-"}</Text>
          <Text className="text-2xl">
            {formatDate(thisOrderInfo.estimatedFinishDate)}
          </Text>
        </div>
        {/*Navnet på skredder som har tatt bestillingen*/}
        {tailorInfo?.tailorPersonId && (
          <Text className="mt-2 text-2xl">{tailorName}</Text>
        )}
      </div>
      <div className="flex justify-between"></div>
      <div className="border-b border-black">
        <FikseHashSumLogo orderId={orderId || ""} />
      </div>

      {/*GRAGMENTERT UNDER BILDE LIGGER HER */}
      {showExpandedOrderDetails && (
        <>
          <ExpandedOrderDetails order={order} thisOrderInfo={thisOrderInfo} />
          {thisOrderInfo.event?.at(-1)?.status === "orderStateDeliverToTailor" && (
            <DeviationButton />
          )}
          {STATES_TAILOR_CANNOT_UNASSIGN.includes(
            thisOrderInfo.event?.at(-1)?.status || ""
          ) && <AssignButton />}
          {STATES_TAILOR_CAN_UPDATE.includes(
            thisOrderInfo.event?.at(-1)?.status || ""
          ) && <NextEventPredictorState />}
        </>
      )}

      {/* Deviation Modal */}
      <ModalTile
        open={showDeviationModal}
        onClose={() => setShowDeviationModal(false)}
        title={t("tailorView.deviationButton")}
      >
        <div className="flex w-full border-y border-black text-base">
          <button
            className="w-full bg-fikseBlue p-4 text-center"
            onClick={() => {
              setShowDeviationModal(false);
              setShowChangeOrderModal(true);
            }}
          >
            {t("tailorView.changeOrder")}
          </button>
        </div>
        <div className="flex w-full text-base">
          <button
            className="w-full bg-fikseRed p-4 text-center"
            onClick={() => {
              setShowDeviationModal(false);
              setShowCancelOrderModal(true);
            }}
          >
            {t("tailorView.cancelOrder")}
          </button>
        </div>
      </ModalTile>

      {/* Change Order Modal */}
      <ModalTile
        open={showChangeOrderModal}
        onClose={() => setShowChangeOrderModal(false)}
        title={t("tailorView.deviationButton")}
      >
        <div className="pb-5 pl-5 text-base">{t("tailorView.deviationDescription")}</div>

        <div className="border-t border-black p-5 text-left text-base">
          {t("deviationModalNote")}:
        </div>
        <textarea
          className="w-full p-4 text-left"
          placeholder={t("tailorView.enterNote")}
          rows={3}
          value={tailorNote}
          onChange={(e) => setTailorNote(e.target.value)}
        />

        <div className="border-t border-black p-5 text-left text-base">
          {t("newPrice")}:
        </div>
        <input
          type="number"
          className="w-full p-4 text-left"
          placeholder={t("tailorView.enterPrice")}
          value={newPrice}
          onChange={(e) => setNewPrice(Number(e.target.value))}
        />

        <div className="border-y border-black p-4 text-center">{t("deviationPromt")}</div>

        <div
          className="w-full cursor-pointer bg-fikseGreen p-4 text-center text-base"
          onClick={updateOrderWithDeviation}
        >
          {t("tailorView.saveChanges")}
        </div>
      </ModalTile>

      {/* Cancel Order Modal */}
      <ModalTile
        open={showCancelOrderModal}
        onClose={() => setShowCancelOrderModal(false)}
        title={t("CancelOrderTempTitle")}
      >
        <div className="pb-5 pl-5 text-base">{t("tailorView.deviationDescription")}</div>

        <div className="border-t border-black p-5 text-left text-base">
          {t("noteFromTailor")}:
        </div>
        <textarea
          className="w-full p-4 text-left"
          placeholder={t("tailorView.enterNote")}
          rows={3}
          value={tailorNote}
          onChange={(e) => setTailorNote(e.target.value)}
        />

        <div className="border-y border-black p-4 text-left">
          {t("tailorView.cancelConfirmation")}
        </div>

        <div
          className="w-full cursor-pointer bg-fikseRed p-4 text-center text-base"
          onClick={async () => {
            // Implement cancel order functionality here
            setShowCancelOrderModal(false);
            // Similar to the OrderCardV2 implementation
          }}
        >
          {t("tailorView.cancelOrder")}
        </div>
      </ModalTile>

      <div className="fixed bottom-0 left-0 right-0 bg-white text-xl">
        {/*Sticky button under block*/}
        {showUnAssignBox && <UnAssignBox />}
        {!showExpandedOrderDetails && (
          <>
            <Button
              variant="warning"
              className="w-full justify-between border-y bg-white text-xl"
              onClick={() => {
                setShowExpandedOrderDetails(true);
              }}
            >
              {t("tailorView.viewAllDetails")}
            </Button>
            <AssignButton />
          </>
        )}
      </div>
    </div>
  );
};
