import React from "react";

import { useTranslation } from "react-i18next";

import i18n from "../i18n";
import { Order, OrderInfo } from "../types/Order";
import Text from "../ui/text";

interface ExpandedOrderDetailsProps {
  order: Order;
  thisOrderInfo: OrderInfo;
}

export const ExpandedOrderDetails = ({
  order,
  thisOrderInfo,
}: ExpandedOrderDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col border-b border-black py-6 pl-4">
        <Text className="text-2xl">{`${t("tailorView.problem")}: ${thisOrderInfo.problem}`}</Text>
        {thisOrderInfo.work !== "N/A" && (
          <Text className="text-2xl">{`${t("tailorView.work")}: ${thisOrderInfo.work}`}</Text>
        )}
        {thisOrderInfo.customerComment && (
          <Text className="text-base text-gray-500">{thisOrderInfo.customerComment}</Text>
        )}
      </div>

      <div className="flex flex-col border-b border-black py-6 pl-4">
        <Text className="text-2xl">{t("tailorView.orderDate")}</Text>
        <Text className="text-base text-gray-500">
          {new Date(order.date).toLocaleDateString(i18n.language, {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </Text>
        {thisOrderInfo.estimatedFinishDate && (
          <>
            <Text className="pt-6 text-2xl">{t("tailorView.deliveryDate")}</Text>
            <Text className="text-base text-gray-500">
              {thisOrderInfo.estimatedFinishDate
                .toDate()
                .toLocaleDateString(i18n.language, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </Text>
          </>
        )}
      </div>

      <div className="flex flex-col border-b border-black py-6 pl-4">
        <Text className="text-2xl">
          {"Status : " + t(`workwearOrderPage.${thisOrderInfo.event?.at(-1)?.status}`)}
        </Text>
      </div>
    </div>
  );
};
