import { useEffect, useState } from "react";

import { Order } from "@type/Order";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { retailOrderAsStringFromLocalStorage } from "../components/databaseHelper";
import LoadingSpinner from "../components/fikseLoadingLetter";
import { db } from "../firebase";
import Retail from "../types/retail";
import OrderVisualizer from "./dashBComponents/OrderVisualizer";

interface SelectOption {
  value: string;
  label: string;
}

const RetailOrdersV3: React.FC = () => {
  const { t } = useTranslation();
  const [visibleOrders, setVisibleOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfOrders, setNumberOfOrders] = useState(9999); //Utelizing this we can limit the number of orders that are fetched from the database
  const retailConnectionsArray = retailOrderAsStringFromLocalStorage();
  const [startDate, setStartDate] = useState<Date>(new Date("2025-01-01"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [sortField, setSortField] = useState<string>("date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [allOrders, setAllOrders] = useState<Order[]>([]);
  const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState<string[]>([]);
  const [selectedRetails, setSelectedRetails] = useState<string[]>([]);
  const [retailOptions, setRetailOptions] = useState<SelectOption[]>([]);
  const [showStepper, setShowStepper] = useState<boolean>(true);
  const [showOnlyWithClaims, setShowOnlyWithClaims] = useState<boolean>(false);

  useEffect(() => {
    fetchAllOrders();
    fetchRetailOptions();
  }, []);

  // Helper function to get the latest status from orderInfo events
  const getLatestStatuses = (order: Order): string[] => {
    if (!order.orderInfo || !Array.isArray(order.orderInfo)) return [];

    return order.orderInfo
      .map((info) => {
        if (!info.event || !Array.isArray(info.event) || info.event.length === 0) {
          return "";
        }
        // Get the last event's status
        const lastEvent = info.event[info.event.length - 1];
        return lastEvent.status || "";
      })
      .filter((status) => status !== ""); // Remove empty statuses
  };

  // Helper function to translate status
  const getTranslatedStatus = (status: string): string => {
    return t(`workwearOrderPage.${status}`) || status;
  };

  // Get unique statuses for the filter options
  const getUniqueStatuses = (): string[] => {
    const statusSet = new Set<string>();
    allOrders.forEach((order) => {
      const statuses = getLatestStatuses(order);
      statuses.forEach((status) => {
        if (status) statusSet.add(status);
      });
    });
    return Array.from(statusSet);
  };

  // Get unique delivery and retrieval methods
  const getUniqueDeliveryMethods = (): SelectOption[] => {
    const methodsSet = new Set<string>();

    allOrders.forEach((order) => {
      if (order.deliveryMethod) methodsSet.add(order.deliveryMethod);
      if (order.retrieveMethod) methodsSet.add(order.retrieveMethod);
    });

    return Array.from(methodsSet).map((method) => ({
      value: method,
      label: method,
    }));
  };

  // Fetch retail options for filtering
  async function fetchRetailOptions() {
    if (!retailConnectionsArray || retailConnectionsArray.length <= 1) return; // No need to fetch if only one retail

    try {
      const retailRef = collection(db, "retail");
      const retailSnapshot = await getDocs(retailRef);

      const retailOptions = retailSnapshot.docs
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            }) as Retail
        )
        .filter((retail) => retailConnectionsArray.includes(retail.id))
        .map((retail) => ({
          value: retail.id,
          label: retail.name || retail.id,
        }));

      setRetailOptions(retailOptions);
    } catch (error) {
      console.error("Error fetching retail options:", error);
    }
  }

  async function fetchAllOrders() {
    const orderCollection = collection(db, "orders");
    const baseQuery = query(
      orderCollection,
      where("retailId", "in", retailConnectionsArray)
    );

    const querySnapshot = await getDocs(baseQuery);
    const orders = querySnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as Order
    );

    setAllOrders(orders);
    updateVisibleOrders();
    setIsLoading(false);
  }

  // Update visible orders when sort parameters change
  useEffect(() => {
    if (!isLoading) {
      updateVisibleOrders();
    }
  }, [sortField, sortDirection, startDate, endDate]);

  async function updateVisibleOrders() {
    console.log("Fetching orders with:", { sortField, sortDirection });
    const orderCollection = collection(db, "orders");

    // Create a base query without sorting if we're sorting by orderId (document ID)
    let baseQuery;

    if (sortField === "orderId") {
      // When sorting by orderId, we need a different approach since it's the document ID
      baseQuery = query(
        orderCollection,
        where("retailId", "in", retailConnectionsArray),
        where("date", ">=", startDate.toISOString()),
        where("date", "<=", endDate.toISOString()),
        limit(numberOfOrders * 2) // Fetch more since we'll sort manually
      );
    } else {
      // Normal query with sorting
      baseQuery = query(
        orderCollection,
        where("retailId", "in", retailConnectionsArray),
        where("date", ">=", startDate.toISOString()),
        where("date", "<=", endDate.toISOString()),
        orderBy(sortField, sortDirection),
        limit(numberOfOrders)
      );
    }

    const querySnapshot = await getDocs(baseQuery);
    let orders = querySnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as Order
    );

    // If sorting by orderId, sort the results manually by document ID
    if (sortField === "orderId") {
      orders.sort((a, b) => {
        if (sortDirection === "asc") {
          return a.id.localeCompare(b.id);
        } else {
          return b.id.localeCompare(a.id);
        }
      });

      // Limit to the required number
      orders = orders.slice(0, numberOfOrders);
    }

    setVisibleOrders(orders);
  }

  const handleSortChange = (value: string) => {
    // Parse the selected value which will be in format "field_direction"
    const [field, direction] = value.split("_");
    console.log("Sort changed to:", { field, direction, value });

    // Update both states at once to ensure useEffect catches them together
    setSortField(field);
    setSortDirection(direction as "asc" | "desc");
    // We don't call updateVisibleOrders here anymore, the useEffect will handle it
  };

  // Filter orders based on search query, selected statuses, delivery methods, and retails
  const filteredOrders = visibleOrders.filter((order) => {
    // Search query filter
    const matchesSearch = !searchQuery
      ? true
      : order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.contactInfo?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.contactInfo?.phone?.toLowerCase().includes(searchQuery.toLowerCase());

    // Status filter
    const matchesStatus =
      selectedStatuses.length === 0
        ? true
        : getLatestStatuses(order).some((status) => selectedStatuses.includes(status));

    // Delivery/Retrieval method filter
    const matchesDeliveryMethod =
      selectedDeliveryMethods.length === 0
        ? true
        : (order.deliveryMethod &&
            selectedDeliveryMethods.includes(order.deliveryMethod)) ||
          (order.retrieveMethod &&
            selectedDeliveryMethods.includes(order.retrieveMethod));

    // Retail filter
    const matchesRetail =
      selectedRetails.length === 0 || selectedRetails.includes(order.retailId || "");

    // Claims filter
    const matchesClaims = !showOnlyWithClaims || !!order.claim;

    return (
      matchesSearch &&
      matchesStatus &&
      matchesDeliveryMethod &&
      matchesRetail &&
      matchesClaims
    );
  });

  // Calculate stats for each status
  const calculateStatusStats = (status: string) => {
    const count = allOrders.filter((order) =>
      getLatestStatuses(order).includes(status)
    ).length;

    const percentage =
      allOrders.length > 0 ? ((count / allOrders.length) * 100).toFixed(1) : "0.0";

    return { count, percentage };
  };

  // Handle order updates from OrderVisualizer
  const handleOrderUpdate = (updatedOrder: Order) => {
    // Update the orders in state
    setVisibleOrders((prev) =>
      prev.map((order) => (order.id === updatedOrder.id ? updatedOrder : order))
    );

    setAllOrders((prev) =>
      prev.map((order) => (order.id === updatedOrder.id ? updatedOrder : order))
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen p-4">
      <h1 className="mb-4 text-2xl font-bold">Retail Orders V3</h1>

      {/* Status cards */}
      <div className="mb-8 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        {getUniqueStatuses().map((status) => {
          const { count, percentage } = calculateStatusStats(status);
          return (
            <div
              key={status}
              onClick={() => {
                setSelectedStatuses((prev) =>
                  prev.includes(status)
                    ? prev.filter((s) => s !== status)
                    : [...prev, status]
                );
              }}
              className={`cursor-pointer rounded-lg border border-gray-200 p-3 shadow transition-colors ${
                selectedStatuses.includes(status)
                  ? "border-blue-300 bg-blue-100"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              <h3 className="line-clamp-1 text-xs font-medium text-gray-500">
                {getTranslatedStatus(status)}
              </h3>
              <div className="mt-1 flex items-baseline justify-between">
                <p className="text-2xl font-semibold text-gray-900">{count}</p>
                <p className="text-xs text-gray-500">{percentage}%</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mb-4 flex flex-wrap gap-4">
        <div className="w-full md:w-64">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Search order
          </label>
          <input
            type="text"
            placeholder="Search by customer name, phone, or order ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded border p-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Start Date
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date: Date | null) => {
              if (date) {
                setStartDate(date);
                updateVisibleOrders();
              }
            }}
            dateFormat="dd/MM/yyyy"
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date("2020-01-01")}
            maxDate={endDate}
            className="rounded border p-2"
          />
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">End Date</label>
          <DatePicker
            selected={endDate}
            onChange={(date: Date | null) => {
              if (date) {
                setEndDate(date);
                updateVisibleOrders();
              }
            }}
            dateFormat="dd/MM/yyyy"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            className="rounded border p-2"
          />
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">Sort By</label>
          <select
            className="rounded border p-2"
            value={`${sortField}_${sortDirection}`}
            onChange={(e) => handleSortChange(e.target.value)}
          >
            <option value="date_desc">Date (Newest First)</option>
            <option value="date_asc">Date (Oldest First)</option>
            <option value="orderId_asc">Order ID (Ascending)</option>
            <option value="orderId_desc">Order ID (Descending)</option>
          </select>
        </div>

        {/* Delivery/Retrieve Method filter */}
        <div className="w-48">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Delivery/Retrieve Method
          </label>
          <Select
            isMulti
            options={getUniqueDeliveryMethods()}
            onChange={(selected) =>
              setSelectedDeliveryMethods(selected.map((option) => option.value))
            }
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select methods..."
          />
        </div>

        {/* Retail filter (only if multiple) */}
        {retailOptions.length > 1 && (
          <div className="w-48">
            <label className="mb-1 block text-sm font-medium text-gray-700">
              Filter by Retail
            </label>
            <Select
              isMulti
              options={retailOptions}
              onChange={(selected) =>
                setSelectedRetails(selected.map((option) => option.value))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select retail..."
            />
          </div>
        )}

        {/* Claims filter */}
        <div className="flex items-end">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={showOnlyWithClaims}
              onChange={(e) => setShowOnlyWithClaims(e.target.checked)}
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-700">
              Show only orders with claims
            </span>
          </label>
        </div>
      </div>

      {/* Use the enhanced OrderVisualizer with onOrderUpdate callback */}
      <OrderVisualizer
        orders={filteredOrders}
        showStepper={showStepper}
        onOrderUpdate={handleOrderUpdate}
      />
    </div>
  );
};

export default RetailOrdersV3;
