import { useState } from "react";

import SelectDepartment from "../components/SelectDepartment";
import Instructions from "../components/home/Instructions";
import OrderModal from "../components/newOrder/OrderModal";

export default function OsloKommunePage() {
  console.log(localStorage.getItem("uid"));
  console.log(localStorage.getItem("department"));
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);

  return (
    <>
      {localStorage.getItem("uid") && localStorage.getItem("department") ? (
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="-m-4 grid p-4">
            <div
              onClick={() => setOrderModalOpen(true)}
              className="flex cursor-pointer flex-col items-start justify-start border border-black p-8 hover:bg-green-100"
            >
              <div className="mb-4 ml-4 text-2xl">Bestill reparasjon av workwear</div>
              <img
                src="https://www.fikse.co/images/illustration/footer-woman-cat.svg"
                alt="Accessories"
                className="h-28 w-28"
              />
            </div>

            {isOrderModalOpen && (
              <OrderModal
                open={isOrderModalOpen}
                onClose={() => setOrderModalOpen(false)}
              />
            )}
          </div>
          <Instructions></Instructions>
        </div>
      ) : (
        <SelectDepartment></SelectDepartment>
      )}
      {}
    </>
  );
}
