// src/components/Page.tsx
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import NotFound from "../components/NotFound";
import ContentBlockRenderer from "./ContentBlockRenderer";
import client from "./sanityClient";

//import { PageData } from "../schema/types";

interface PageProps {
  slug: string;
}

const Page: React.FC = ({}) => {
  const { slug = "home" } = useParams<{ slug?: string }>();
  const [pageData, setPageData] = useState([]) as any;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "page" && slug.current == "${slug}"][0]`;

        // @ts-ignore
        const data = await client.fetch(query);

        setPageData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, [pageData]);

  if (isLoading && !pageData) {
    return <div>{/*Bugger plass i tiden det tar før den loader */}</div>;
  }

  if (!isLoading && !pageData) {
    return <NotFound />;
  }

  const stickyitems = pageData?.content?.find(
    (item: { _type: string }) => item._type === "stickyButtonsBlock"
  )?.buttons?.length;

  return (
    <div className="mx-auto">
      {slug === "consumer" && (
        <>
          <script src="https://js-eu1.hsforms.net/forms/embed/27148729.js" defer></script>
          <div
            className="hs-form-frame"
            data-region="eu1"
            data-form-id="6f90bbdd-c3b5-494a-97ab-13d716a43c03"
            data-portal-id="27148729"
          ></div>
        </>
      )}
      {pageData &&
        pageData.content &&
        pageData.content.map((block: any, index: any) => (
          <ContentBlockRenderer
            block={block}
            index={index}
            key={index}
            {...(stickyitems ? { offset: stickyitems } : {})}
          />
        ))}
    </div>
  );
};

export default Page;
