import { useEffect, useState } from "react";

import { collection, getDocs } from "firebase/firestore";

import { db } from "../../firebase";
import { AddServiceCategoryModal } from "./AddServiceCategoryModal";
import { AddServiceCategoryModalWithCode } from "./AddServiceCategoryModalWithCode";
import { EditServiceCategoryModal } from "./EditServiceCategoryModal";

interface ServiceCategory {
  id: string;
  description: string;
  fix_type: string;
  item: string;
  price_nok: number;
  problem: string;
  work: string;
  placeholder: string;
  carbonFootprint?: number;
}

interface ServiceCategories {
  id: string;
  name: string;
  categories: ServiceCategory[];
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
}

export default function ServiceCategoriesOverview() {
  const [serviceCategories, setServiceCategories] = useState<ServiceCategories[]>([]);
  const [selectedServiceCategory, setSelectedServiceCategory] =
    useState<ServiceCategories | null>(null);
  const [showCategories, setShowCategories] = useState<{
    [key: string]: boolean;
  }>({});
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddModalWithCodeOpen, setIsAddModalWithCodeOpen] = useState(false);

  useEffect(() => {
    const fetchServiceCategories = async () => {
      const querySnapshot = await getDocs(collection(db, "serviceCategories"));
      const serviceCategoryList: ServiceCategories[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as ServiceCategories[];

      const initialShowState = serviceCategoryList.reduce(
        (acc, category) => {
          acc[category.id] = false;
          return acc;
        },
        {} as { [key: string]: boolean }
      );

      setServiceCategories(serviceCategoryList);
      setShowCategories(initialShowState);
    };

    fetchServiceCategories();
  }, []);

  const toggleShowCategories = (id: string) => {
    setShowCategories((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Service Categories</h2>

      {/* Knapp for å åpne AddServiceCategoryModal */}
      <button
        onClick={() => setIsAddModalOpen(true)}
        className="mb-4 inline-flex justify-center border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Add Service Category
      </button>

      {/* Knapp for å åpne AddServiceCategoryModalWithCode */}
      <button
        onClick={() => setIsAddModalWithCodeOpen(true)}
        className="mb-4 ml-4 inline-flex justify-center border border-transparent bg-blue-300 px-4 py-2 text-sm font-medium shadow-sm hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Add Service Category with Code
      </button>

      {serviceCategories.map((serviceCategory) => (
        <div
          key={serviceCategory.id}
          className="mb-4 overflow-hidden border border-gray-200 bg-white p-6 shadow"
        >
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-bold text-gray-900">{serviceCategory.name}</h3>
            <div>
              <button
                className="mr-4 text-sm text-gray-600 hover:underline"
                onClick={() => toggleShowCategories(serviceCategory.id)}
              >
                {showCategories[serviceCategory.id] ? "Hide" : "Show"}
              </button>
              <button
                className="text-sm text-gray-600 hover:underline"
                onClick={() => setSelectedServiceCategory(serviceCategory)}
              >
                Edit
              </button>
            </div>
          </div>
          {showCategories[serviceCategory.id] && (
            <div>
              <div className="mt-4 grid grid-cols-8 gap-4 text-sm text-gray-700">
                <div className="font-semibold">ID</div>
                <div className="font-semibold">Item</div>
                <div className="font-semibold">Problem</div>
                <div className="font-semibold">Work</div>
                <div className="font-semibold">Fix Type</div>
                <div className="font-semibold">Price (NOK)</div>
                <div className="font-semibold">Carbon (g)</div>
                <div className="font-semibold">Description</div>
              </div>
              {serviceCategory.categories.map((category, index) => (
                <div
                  key={index}
                  className="grid grid-cols-8 gap-4 border-t border-gray-200 py-2"
                >
                  <div>{category.id}</div>
                  <div>{category.item}</div>
                  <div>{category.problem}</div>
                  <div>{category.work}</div>
                  <div>{category.fix_type}</div>
                  <div>{category.price_nok}</div>
                  <div>{category.carbonFootprint || 0}</div>
                  <div>{category.description}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {selectedServiceCategory && (
        <EditServiceCategoryModal
          serviceCategory={selectedServiceCategory}
          onClose={() => setSelectedServiceCategory(null)}
          onSave={(updatedServiceCategory) => {
            setServiceCategories((prevServiceCategories) =>
              prevServiceCategories.map((serviceCategory) =>
                serviceCategory.id === updatedServiceCategory.id
                  ? updatedServiceCategory
                  : serviceCategory
              )
            );
            setSelectedServiceCategory(null);
          }}
        />
      )}

      {isAddModalOpen && (
        <AddServiceCategoryModal
          onClose={() => setIsAddModalOpen(false)}
          onSave={(newServiceCategory) => {
            setServiceCategories((prevServiceCategories) => [
              ...prevServiceCategories,
              newServiceCategory,
            ]);
            setIsAddModalOpen(false);
          }}
        />
      )}

      {isAddModalWithCodeOpen && (
        <AddServiceCategoryModalWithCode
          onClose={() => setIsAddModalWithCodeOpen(false)}
          onSave={(newServiceCategory) => {
            setServiceCategories((prevServiceCategories) => [
              ...prevServiceCategories,
              newServiceCategory,
            ]);
            setIsAddModalWithCodeOpen(false);
          }}
        />
      )}
    </div>
  );
}
