import { Order } from "@type/Order";
import Retail from "@type/retail";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";

import { db } from "../firebase";

export async function retailAdressesFromRetailUUID(
  retailUUIDs: string[]
): Promise<string[]> {
  const addresses: string[] = [];
  const retailRef = collection(db, "retail");
  const chunkSize = 10;

  for (let i = 0; i < retailUUIDs.length; i += chunkSize) {
    const chunk = retailUUIDs.slice(i, i + chunkSize);
    const q = query(retailRef, where("__name__", "in", chunk));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (Array.isArray(data.addresses)) {
        addresses.push(...data.addresses);
      }
    });
  }

  return addresses;
}

export async function eligibleForPostalFromRetailUUID(
  retailUUIDs: string[]
): Promise<boolean> {
  const retailRef = collection(db, "retail");
  const chunkSize = 10;

  for (let i = 0; i < retailUUIDs.length; i += chunkSize) {
    const chunk = retailUUIDs.slice(i, i + chunkSize);
    const q = query(retailRef, where("__name__", "in", chunk));
    const querySnapshot = await getDocs(q);

    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      if (data.flags.postalServices === true) {
        return true;
      }
    }
  }

  return false;
}
export async function getRetailElementsFromRetailUUIDstring(
  retailIds: string[]
): Promise<Retail[]> {
  const retailsRef = collection(db, "retail");
  const retailsSnapshot = await getDocs(retailsRef);
  return retailsSnapshot.docs
    .filter((doc) => retailIds.includes(doc.id))
    .map((doc) => ({ id: doc.id, ...doc.data() }) as Retail);
}

export function retailOrderAsStringFromLocalStorage(): string[] | null {
  const orders = localStorage.getItem("retailConnections");
  return orders?.split(",") || null;
}

export const getPageName = (url: string | undefined) => {
  if (!url) return null;
  try {
    const urlWithoutHash = url.split("#")[0];
    const urlWithoutQuery = urlWithoutHash.split("?")[0];
    const segments = urlWithoutQuery.split("/").filter(Boolean);
    const lastSegment = segments[segments.length - 1];
    return lastSegment || null;
  } catch (error) {
    console.error("Error parsing URL:", error);
    return null;
  }
};

export async function getOrder(orderId: string): Promise<Order> {
  const orderRef = doc(db, "orders", orderId);
  const orderSnap = await getDoc(orderRef);
  return orderSnap.data() as Order;
}
