// src/components/ContentBlockRenderer.tsx
import React, { useEffect, useState } from "react";

import { getAuth, signOut } from "firebase/auth";
import { cp } from "fs";
import { title } from "process";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

import { getCurrentUserEmailAsync, signOutUser } from "../auth";
import ModalTile from "../components/ModalTile";
import LoggInModal from "../components/SignInModal";
import SignInWorkaround from "../components/SignInWorkaround";
import { OrderModalButtons } from "../components/home/OrderModalButtons";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import { ReactComponent as HamburgerIcon } from "../images/icons-fikse/hamburger_Slim.svg";
import { accountHasAccessToDashboard } from "../pages/DashboardPage";
import ContactUsBlock from "./contactUsBlock";
import { urlFor } from "./imageUrl";
import { ButtonBlock, ContentBlock, Language } from "./types";

interface Props {
  block: ContentBlock;
  index: number;
  offset?: number;
}

const ContentBlockRenderer: React.FC<Props> = ({ block, index, offset }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [hamburgerMenue, setHamburgerMenue] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false); // Add this line
  const [showSignOut, setShowSignOut] = useState(false); // Add this line
  const { t } = useTranslation();
  const [hasDashboardAccess, setHasDashboardAccess] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      const email = await getCurrentUserEmailAsync();
      if (email) {
        const hasAccess = await accountHasAccessToDashboard(email);
        setHasDashboardAccess(hasAccess);
      }
    };
    checkAccess();
  }, []);

  const languagePicker = (input: Language[]) => {
    return input.find((item) => item.language === currentLanguage)?.text ?? "";
  };

  const Button = (button: ButtonBlock, preset?: number) => {
    const isFikseDomain = (url: string) => {
      return url.includes("fikse.co") || url.includes("fiksefirstapp.web.app");
    };

    const target =
      button.url.startsWith("http") && !isFikseDomain(button.url) ? "_blank" : "_self";

    return (
      <a
        href={
          button.url.startsWith("http") || button.url.startsWith("mailto:")
            ? button.url
            : `/${button.url}`
        }
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        className={`flex items-center justify-between whitespace-pre-line border-b border-black ${preset == 1 ? "px-6" : "px-16"} p-4 px-8 py-5 text-left text-lg sm:pl-16 sm:text-3xl`}
      >
        {languagePicker(button.text)}
        <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
      </a>
    );
  };

  const commandButton = (instructions: () => void, text: string, preset?: number) => {
    return (
      <button
        onClick={instructions}
        className={`flex items-center justify-between whitespace-pre-line border-b border-black ${preset == 1 ? "px-6" : "px-16"} p-4 px-8 py-5 text-left text-lg hover:cursor-pointer sm:pl-16 sm:text-3xl`}
      >
        {text}
        <ChevronRightIcon className="ml-48 h-7 w-7" aria-hidden="true" />
      </button>
    );
  };

  /*TOODO: FIX STYGG RECURSION MED KNAPPENE*/
  switch (block._type) {
    case "headerBlock":
      return (
        <>
          <div className="right-0 top-0 max-h-56 max-w-64 p-4 px-8 pr-4 text-xl sm:max-w-80 sm:pl-16 sm:text-3xl md:max-w-[70%] md:text-3xl">
            <p className="hidden w-full whitespace-pre-line break-words md:block md:w-auto">
              {languagePicker(block.text)}
            </p>
          </div>
          <div className="border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl">
            <div className="flex flex-col-reverse items-center justify-between pt-10 md:flex-row md:items-end md:pt-4">
              <p className="block w-full whitespace-pre-line break-words text-2xl md:hidden md:w-auto">
                {languagePicker(block.text)}
              </p>
              {block.logo && (
                <div className="w-full p-4 md:w-2/5">
                  <img
                    src={urlFor(block.logo).url()}
                    alt="Logo"
                    className="h-auto w-full object-contain"
                  />
                </div>
              )}
              {block.image && (
                <div className="w-3/4 p-4 md:w-2/5">
                  <img
                    src={urlFor(block.image[0]).url()}
                    alt=""
                    className="h-auto max-h-96 w-full object-contain"
                  />
                </div>
              )}
            </div>
          </div>
        </>
      );

    case "textBlock":
      return (
        <div className="border-b border-black">
          <div className="prose max-w-none whitespace-pre-line p-4 px-8 text-lg sm:pl-16 sm:text-3xl">
            {languagePicker(block.text)}
          </div>
        </div>
      );

    case "logoBlock":
      return (
        <div className="flex items-center space-x-4">
          {block.logo && (
            <img
              src={urlFor(block.logo).width(100).url()}
              alt="Logo"
              className="h-24 w-24 object-contain"
            />
          )}
          <p className="whitespace-pre-line text-lg">{languagePicker(block.info)}</p>
        </div>
      );

    case "hamburgerBlock":
      return (
        <div className="absolute right-0 top-0 z-50 flex flex-col items-end space-y-4 p-4">
          <div className="flex w-screen items-center justify-between px-4 md:w-auto md:justify-end md:px-0">
            <button
              onClick={() => (window.location.href = "/")}
              className={`h-8 w-auto px-2 pl-6 text-${block.colour} md:hidden`}
            >
              {t("homeCAPS")}
            </button>

            <div className="flex flex-row items-end space-x-4">
              <button
                onClick={() => (window.location.href = "/")}
                className={`h-8 w-auto px-2 text-${block.colour} mb-0 hidden md:block`}
              >
                {t("homeCAPS")}
              </button>

              {!window.location.pathname.match("offices") &&
                (getAuth().currentUser ? (
                  <button
                    onClick={() => setShowSignOut(true)}
                    className={`h-8 w-auto px-2 text-${block.colour} mb-0`}
                  >
                    {t("navBar.logOut").toUpperCase()}
                  </button>
                ) : (
                  <button
                    onClick={() => setShowSignIn(true)}
                    className={`h-8 w-auto px-2 text-${block.colour} mb-0`}
                  >
                    {t("navBar.logIn").toUpperCase()}
                  </button>
                ))}

              <div className="flex space-x-6">
                <button
                  onClick={() => i18n.changeLanguage("no")}
                  className={`h-8 w-8 ${currentLanguage === "no" ? `text-${block.colour}` : `text-${block.colourHover}`}`}
                >
                  NO
                </button>
                <button
                  onClick={() => i18n.changeLanguage("en")}
                  className={`h-8 w-8 ${currentLanguage === "en" ? `text-${block.colour}` : `text-${block.colourHover}`}`}
                >
                  EN
                </button>
              </div>
            </div>
          </div>
          <button onClick={() => setHamburgerMenue(!hamburgerMenue)}>
            <HamburgerIcon
              className="h-16 w-[5.2rem]"
              style={{ fill: isHovered ? block.colourHover : block.colour }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              aria-hidden="true"
            />
          </button>

          {block.buttons && (
            <ModalTile
              open={hamburgerMenue}
              onClose={() => setHamburgerMenue(false)}
              title=""
            >
              {hasDashboardAccess && (
                <a
                  href={"/dashboard"}
                  className={`flex items-center justify-between whitespace-pre-line border-b border-black p-4 px-6 px-8 py-5 text-left text-lg sm:pl-16 sm:text-3xl`}
                >
                  {t("Dashboard")}
                  <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
                </a>
              )}
              {block.buttons.map((button, index) => Button(button, 1))}

              {getAuth().currentUser && (
                <a
                  onClick={() => {
                    const auth = getAuth();
                    signOutUser()
                      .then(() => {
                        console.log("User signed out");
                        window.location.reload(); // Refresh the window
                      })
                      .catch((error) => {
                        console.error("Error signing out:", error);
                      });
                  }}
                  href={"/"}
                  className={`flex items-center justify-between whitespace-pre-line border-b border-black p-4 px-6 px-8 py-5 text-left text-lg sm:pl-16 sm:text-3xl`}
                >
                  {t("navBar.logOut")}
                  <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
                </a>
              )}
            </ModalTile>
          )}

          <LoggInModal open={showSignIn} onClose={() => setShowSignIn(false)} />
          <ModalTile
            open={showSignOut}
            onClose={() => setShowSignOut(false)}
            title={t("navBar.profile")}
          >
            <div className="flex flex-col">
              <p className="mb-6 p-6 text-lg">
                {t("navBar.email") + getAuth().currentUser?.email ||
                  localStorage.getItem("verifiedUid")}
              </p>
              <button
                onClick={async () => {
                  await signOutUser();
                  window.location.reload();
                }}
                className="mt-auto flex w-full flex-row justify-between rounded-none border-b border-t border-black bg-fikseGreen px-6 py-4 text-left text-lg"
              >
                {t("navBar.logOut")}
                <ChevronRightIcon
                  className="mt-1 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </button>
            </div>{" "}
          </ModalTile>
        </div>
      );

    case "posterBlock":
      let posterOffset = offset === 2 ? "8" : offset === 1 ? "4" : "0";
      posterOffset += window.innerWidth <= 768 ? 10 : 5;
      console.log("posterOffset", posterOffset);

      return (
        <div className="border-b border-black">
          <div
            className="sm:pm-16 p w-full flex-col justify-between bg-cover bg-center px-8 pb-8 pt-4"
            style={{
              backgroundImage: `url(${urlFor(block.backgroundImage).url()})`,
              height: `calc(100vh - ${posterOffset}rem)`,
            }}
          >
            <div className="flex h-full flex-col justify-between">
              <div
                className={`right-0 top-0 max-h-56 w-full max-w-[65%] whitespace-pre-line break-words text-xl sm:text-3xl ${block.colour ? `text-${block.colour}` : "text-white"}`}
              >
                <div className="hidden md:block">
                  {block.infoText && languagePicker(block.infoText)}
                </div>
              </div>

              <div>
                {block.logo && (
                  <div className="flex justify-between">
                    <img
                      src={urlFor(block.logo).url()}
                      alt="Logo"
                      className="w-full object-contain md:h-28 md:w-auto"
                    />
                  </div>
                )}
                <div
                  className={`${block.colour ? `text-${block.colour}` : "text-white"}`}
                  style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.45)" }}
                >
                  {block.infoText && (
                    <p className="block w-full whitespace-pre-line break-words pt-8 text-left text-2xl md:hidden">
                      {languagePicker(block.infoText)}
                    </p>
                  )}

                  {block.slogan && (
                    <p className="hidden w-full whitespace-pre-line break-words pt-8 text-left text-3xl md:block">
                      {languagePicker(block.slogan)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case "orderblock":
      if (block.discount && block.discount !== "0") {
        localStorage.setItem("discount", block.discount);
      }
      return (
        <OrderModalButtons
          categories={[...block.items]}
          discountPassTrough={block.discount}
          attributes={block.attributes ? block.attributes : undefined}
        />
      );

    case "imageBlock":
      return (
        <div className="mb-6">
          {block.image && (
            <img
              src={urlFor(block.image).width(800).url()}
              alt={languagePicker(block.caption) || "Image"}
              className="h-auto w-full"
            />
          )}
          {block.caption && (
            <p className="mt-2 whitespace-pre-line text-center">
              {languagePicker(block.caption)}
            </p>
          )}
        </div>
      );

    case "bloggBlock": {
      const isEven = index % 2 === 0;

      return (
        <>
          <div className="flex min-h-[45vh] w-full flex-col border-b border-black md:min-h-fit md:flex-row">
            {/* Image Section */}
            <div className={`w-full md:w-1/2 ${isEven ? "md:order-2" : "md:order-1"}`}>
              {block.image && (
                <img
                  src={urlFor(block.image).url()}
                  className="h-full w-full object-cover"
                  width={800}
                  height={600}
                />
              )}
            </div>

            {/* Text Section */}
            <div
              className={`flex w-full flex-col justify-center md:w-1/2 ${
                isEven ? "md:order-1" : "md:order-2"
              }`}
            >
              {block.text && (
                <div className="prose max-w-none whitespace-pre-line p-4 px-8 text-lg sm:pl-16 sm:text-3xl">
                  {languagePicker(block.text)}
                </div>
              )}
            </div>
          </div>
          {block.button && Button(block.button)}
        </>
      );
    }

    case "buttonBlock":
      return <>{Button(block)}</>;

    case "carouselBlock":
      return (
        <div className="flex flex-col-reverse border-b border-black p-8 md:flex-row md:p-16">
          <div className="w-full text-base md:w-2/5">
            <h1 className="mb-4 whitespace-pre-line text-7xl">
              {languagePicker(block.header)}
            </h1>
            <p className="whitespace-pre-line text-xl">{languagePicker(block.text)}</p>
          </div>
          <div className="mt-8 w-full md:ml-36 md:mt-0 md:w-2/5 md:items-end md:justify-center">
            <img src={urlFor(block.images[0]).url()} alt="Logo" className="mx-auto" />
          </div>
        </div>
      );

    case "footer":
      const footerOffset = offset === 2 ? "10" : offset === 1 ? "5" : "10";

      console.log("footerOffset", footerOffset);

      return (
        <div
          className="items-top mt-16 flex w-full justify-between whitespace-pre-line p-4 px-8 text-base text-lg sm:pl-16 sm:text-3xl"
          style={{ paddingBottom: `${footerOffset}rem` }}
        >
          <div className="flex flex-col items-start space-y-4">
            <div className="flex space-x-4">
              {block.SocialMedia &&
                block.SocialMedia.map((social, index) => (
                  <a key={index} href={social.url}>
                    <img
                      src={urlFor(social.icon).url()}
                      className="h-8 w-8"
                      alt="Social Media Icon"
                    />
                  </a>
                ))}
            </div>
            <p>{block.address}</p>
          </div>
          <div className="flex flex-col space-y-4">
            {block.clickableLinks &&
              block.clickableLinks.map((link, index) => (
                <div key={index} className="flex flex-col items-end">
                  <a href={link.url} className="hover:underline">
                    {languagePicker(link.text)}
                  </a>
                </div>
              ))}
          </div>
        </div>
      );

    case "stickyButtonsBlock":
      return (
        <div className="fixed bottom-0 left-0 w-full justify-center border-t border-black bg-white">
          {block.buttons.map((button, index) => Button(block.buttons[index]))}
        </div>
      );

    case "collaborationBlock":
      return (
        <div className="w-full overflow-hidden border-b border-black">
          <div className="py-4">
            <Marquee
              gradient={false}
              speed={window.innerWidth < 768 ? 50 : 100}
              direction="left"
            >
              {block.image.map((imageBlock, index) => (
                <div key={index} className="mx-2 flex-shrink-0 md:mx-10">
                  <img
                    src={urlFor(imageBlock.image).url()}
                    className="h-4 w-auto object-contain md:h-14"
                    alt=""
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      );

    case "contactUsBlock":
      return <ContactUsBlock block={block} index={index} />;

    default:
      return null;
  }
};

export default ContentBlockRenderer;

///COPY PASTE FOR FIX ---- p-4 px-8 text-lg sm:pl-16 sm:text-3xl -----
