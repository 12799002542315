import { useState } from "react";

import { Auth, sendPasswordResetEmail, sendSignInLinkToEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";

import { signInWithEmailPassword } from "../auth";
import { auth, functionsEW1 } from "../firebase";
import i18n from "../i18n";
import { Button } from "../ui/button";
import ModalTile from "./ModalTile";
import SignUpModal from "./SignUpModal";

interface LoggInModalProps {
  open: boolean;
  onClose: () => void;
}

// Loading spinner component
const LoadingSpinner = () => (
  <svg
    className="-ml-1 mr-2 h-4 w-4 animate-spin text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const LoggInModal = ({ open, onClose }: LoggInModalProps) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [emailLinkLoginModalOpen, setEmailLinkLoginModalOpen] = useState<boolean>(false);
  const [wrongPassword, setWrongPassword] = useState<boolean>(false);
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState<boolean>(false);
  const [SignUpModalOpen, setSignUpModalOpen] = useState<boolean>(false);
  const [emailNotSignedUp, setEmailNotSignedUp] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const { t } = useTranslation();

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: window.location.origin, //Denne her gjorde egentlig svert lite, kan være en ide å se om url: må være med
    // This must be true for email link sign-in
    handleCodeInApp: true,
    language: i18n.language,
  };

  const closeLoginModal = () => {
    setEmailLinkLoginModalOpen(false);
    setPasswordResetModalOpen(false);
    setPassword("");
    setWrongPassword(false);
    setEmailNotSignedUp(false);
    setSignUpModalOpen(false);
    onClose();
  };

  const logginWithLink = async () => {
    // Don't set loading state here since it's managed by the caller
    try {
      const methods = await fetchSignInMethods(auth, email);

      if (methods.length === 0) {
        // User doesn't have an account
        setEmailNotSignedUp(true);
        return;
      }

      // User has an account, proceed with email link
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      // Save the email in localStorage
      window.localStorage.setItem("emailForSignIn", email);

      setEmailLinkLoginModalOpen(true);
    } catch (error) {
      console.error("Error sending sign-in link: ", error);
      throw error; // Rethrow to allow caller to handle it
    }
  };

  const loggAndSignInWithEmailandPassword = async () => {
    // Don't set loading state here since it's managed by the caller
    try {
      const result = await signInWithEmailPassword(email, password);
      if (result.error) {
        throw new Error(result.error.message);
      }
      closeLoginModal();
    } catch (error) {
      console.error("Error signing in: ", error);
      setWrongPassword(true);
      throw error; // Rethrow to allow caller to handle it
    }
  };

  // Function to show only the login modal
  const showLoginModal = () => {
    setSignUpModalOpen(false); // Hide sign-in modal
    // We don't need to do anything else, as the main LoggInModal is still visible
    // because its open state is controlled by the parent component
  };

  function fetchSignInMethods(auth: Auth, email: string): Promise<string[]> {
    const checkIfUserHasPassword = httpsCallable(functionsEW1, "checkIfUserHasPassword");

    return checkIfUserHasPassword({ email })
      .then((result: any) => {
        const data = result.data;
        // If the user doesn't exist or has no sign-in methods
        if (!data.exists || data.signInMethods.length === 0) {
          return [];
        }
        return data.signInMethods;
      })
      .catch((error) => {
        console.error("Error checking user authentication methods:", error);
        return [];
      });
  }

  return (
    <>
      <SignUpModal
        open={SignUpModalOpen}
        onClose={() => {
          // When closing the SignUpModal, close the entire flow
          // if that's what the user intended
          closeLoginModal();
        }}
        showLogin={showLoginModal}
      />

      <ModalTile
        open={open && !SignUpModalOpen}
        onClose={closeLoginModal}
        title="Logg inn"
      >
        <>
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="w-full flex-auto border-0 border-b border-t border-black bg-white/5 p-4 sm:text-sm sm:leading-6"
              placeholder="Skriv din epost her"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="w-full flex-auto border-0 border-b border-black bg-white/5 p-4 sm:text-sm sm:leading-6"
            placeholder="Passord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="w-full">
            <button
              type="button"
              className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
              onClick={async () => {
                setWrongPassword(false);
                setEmailNotSignedUp(false);
                setIsLoginLoading(true);
                try {
                  const methods = await fetchSignInMethods(auth, email);
                  console.log("email", email);
                  console.log("methods", methods);

                  if (methods.length === 0) {
                    // User doesn't have an account
                    setEmailNotSignedUp(true);
                    return;
                  }
                  await loggAndSignInWithEmailandPassword();
                } catch (error) {
                  console.error("Error during login process:", error);
                } finally {
                  setIsLoginLoading(false);
                }
              }}
            >
              {isLoginLoading ? (
                <div className="flex items-center">
                  <LoadingSpinner />
                  <span>Logger inn...</span>
                </div>
              ) : (
                "Logg inn"
              )}
            </button>
          </div>
          <button
            type="button"
            className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
            onClick={async () => {
              setWrongPassword(false);
              setEmailNotSignedUp(false);
              setIsLinkLoading(true);
              try {
                await logginWithLink();
              } catch (error) {
                console.error("Error sending login link:", error);
              } finally {
                setIsLinkLoading(false);
              }
            }}
          >
            {isLinkLoading ? (
              <div className="flex items-center">
                <LoadingSpinner />
                <span>Sender lenke...</span>
              </div>
            ) : (
              "Send meg en innloggingslenke"
            )}
          </button>
          <button
            type="button"
            className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
            onClick={() => setSignUpModalOpen(true)}
          >
            {t("navBar.signIn")}
          </button>
        </>
        {/*Errormeldinger*/}
        {emailNotSignedUp && (
          <Button variant="warning">Eposten {email} er ikke registrert.</Button>
        )}
        {wrongPassword && (
          <>
            <Button
              variant="warning"
              className="hover:bg-fikseRed/90"
              onClick={() => {
                sendPasswordResetEmail(auth, email, actionCodeSettings);
                setPasswordResetModalOpen(true);
              }}
            >
              Feil passord, trykk her for å resette passordet ditt.
            </Button>
          </>
        )}

        <ModalTile
          open={emailLinkLoginModalOpen}
          onClose={() => {
            closeLoginModal();
          }}
          title="E-post sendt"
        >
          <div className="border">
            <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
              Lenke er sendt til epost {email}. Sjekk inboksen din for lenke.
            </p>
          </div>
        </ModalTile>
        <ModalTile
          open={passwordResetModalOpen}
          onClose={() => {
            closeLoginModal();
          }}
          title="Tilbakestill passord"
        >
          <div className="border">
            <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
              En lenke for å tilbakestille passordet er sendt til {email}. Sjekk innboksen
              din (og eventuelt spam-mappen) for å finne lenken.
            </p>
            <div className="w-full">
              <button
                type="button"
                className="w-full border-t border-black bg-[#8cd7ff] p-4 text-center text-sm hover:bg-[#7bc3e0]"
                onClick={() => {
                  closeLoginModal();
                }}
              >
                Lukk
              </button>
            </div>
          </div>
        </ModalTile>
      </ModalTile>
    </>
  );
};

LoggInModal.propTypes = {};

export default LoggInModal;
