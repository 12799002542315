import React from "react";

import { Analytics, MonthlyStoreAndTotalAnalytics } from "@type/retailAnalytic";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface SavingsGraphProps {
  analytics: Analytics;
  showTotalSavings: boolean;
}

const SavingsGraph: React.FC<SavingsGraphProps> = ({ analytics, showTotalSavings }) => {
  // Transform analytics data into the format needed for the graph
  const graphData =
    analytics.savingsAnalytics?.map((month: MonthlyStoreAndTotalAnalytics) => {
      // Create base data point with total savings
      const dataPoint: Record<string, string | number> = {
        month: month.yearandmonth,
        total: month.totalMonthAmount,
      };

      // Add individual store savings
      month.storeSpesific.forEach((storeSaving) => {
        dataPoint[storeSaving.storeName] = storeSaving.totalMonthAmount;
      });

      return dataPoint;
    }) || [];

  // Get unique store names for generating lines
  const storeNames =
    analytics.savingsAnalytics?.[0]?.storeSpesific.map((store) => store.storeName) || [];

  // Generate unique colors for each store
  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#00C49F"];

  return (
    <div className="mt-4 h-[400px] w-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" label={{ value: "Month", position: "bottom" }} />
          <YAxis label={{ value: "Savings (NOK)", angle: -90, position: "insideLeft" }} />
          <Tooltip />
          <Legend />

          {/* Total savings line - only show if showTotalSavings is true */}
          {showTotalSavings && (
            <Line
              type="monotone"
              dataKey="total"
              stroke="#000000"
              strokeWidth={2}
              name="Total Savings"
              dot={false}
            />
          )}

          {/* Individual store lines */}
          {storeNames.map((store: string, index: number) => (
            <Line
              key={store}
              type="monotone"
              dataKey={store}
              stroke={colors[index % colors.length]}
              name={store}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SavingsGraph;
