import { useState } from "react";

import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { useTranslation } from "react-i18next";

import { auth, db, storage } from "../firebase";
import i18n from "../i18n";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import ModalTile from "./ModalTile";

export interface SignUpModalProps {
  open: boolean;
  onClose: () => void;
  showLogin?: () => void; // Optional function to show the login modal
  forcedEmail?: string;
}

// Helper function to check if an email is already registered
const isEmailAlreadyRegistered = async (email: string): Promise<boolean> => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    return methods.length > 0;
  } catch (error) {
    console.error("Error checking if email exists:", error);
    throw error; // Re-throw to handle in the component
  }
};

// Loading spinner component
const LoadingSpinner = () => (
  <svg
    className="-ml-1 mr-2 h-4 w-4 animate-spin text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const SignUpModal = ({ open, onClose, showLogin, forcedEmail }: SignUpModalProps) => {
  const [email, setEmail] = useState<string>(forcedEmail || "");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [emailLinkSignUpModalOpen, setEmailLinkSignUpModalOpen] =
    useState<boolean>(false);
  const [weakPassword, setWeakPassword] = useState<boolean>(false);
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState<boolean>(false);
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState<boolean>(false);
  const [emailCheckError, setEmailCheckError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const { t } = useTranslation();

  const actionCodeSettings = {
    url: "https://beta.fikse.co/",
    handleCodeInApp: true,
    language: i18n.language,
  };

  const onModalClose = () => {
    setEmailLinkSignUpModalOpen(false);
    onClose();
  };

  const signInWithLink = () => {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setEmailLinkSignUpModalOpen(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error sending sign-in link", errorCode, errorMessage);
      });
  };

  const validatePassword = () => {
    // Check password length
    if (password.length < 6) {
      setWeakPassword(true);
      return false;
    }
    setWeakPassword(false);

    // Check if passwords match
    if (password !== passwordConfirmation) {
      setPasswordMismatch(true);
      return false;
    }
    setPasswordMismatch(false);

    return true;
  };

  // Handle image selection
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && typeof event.target.result === "string") {
          setSelectedImage(event.target.result);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // Trigger file input click
  const triggerFileInput = () => {
    const fileInput = document.getElementById("picture") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  // Handle image removal
  const removeImage = () => {
    setSelectedImage(null);
    // Reset the file input
    const fileInput = document.getElementById("picture") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  // Upload profile picture to Firebase Storage
  const uploadProfilePicture = async (uid: string): Promise<string | null> => {
    if (!selectedImage) return null;

    try {
      // Create a reference to 'profilePictures/{uid}'
      const storageRef = ref(storage, `profilePictures/${uid}`);

      // Convert the data URL to a format Firebase can store
      // Remove the data:image/jpeg;base64, part
      const imageData = selectedImage.split(",")[1];

      // Upload the image as a data URL
      await uploadString(storageRef, imageData, "base64");

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      throw error;
    }
  };

  // Update user profile with the profile picture URL
  const updateUserProfile = async (user: any, photoURL: string | null) => {
    try {
      // Update the user's profile in Firebase Authentication
      if (photoURL) {
        await updateProfile(user, {
          photoURL: photoURL,
        });
      }

      // Also store the profile picture URL in Firestore
      await setDoc(
        doc(db, "users", user.uid),
        {
          email: user.email,
          photoURL: photoURL,
          createdAt: new Date().toISOString(),
        },
        { merge: true }
      );

      console.log("User profile updated with profile picture");
    } catch (error) {
      console.error("Error updating user profile:", error);
      throw error;
    }
  };

  return (
    <>
      <ModalTile open={open} onClose={onClose} title={t("signUpModal.title")}>
        <>
          <div className="flex flex-col items-start border-y border-black">
            <div className="flex w-full items-center">
              {selectedImage && (
                <div className="h-[52px] w-[52px] flex-shrink-0">
                  <img
                    src={selectedImage}
                    alt="Preview"
                    className="h-full w-full border-r border-black object-cover"
                  />
                </div>
              )}
              <div className="flex-grow">
                <input
                  id="picture"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                />
                <Button
                  type="button"
                  onClick={triggerFileInput}
                  variant="blue"
                  className="border-none"
                >
                  {selectedImage
                    ? t("signUpModal.updateImage")
                    : t("signUpModal.uploadImage")}
                </Button>
              </div>
            </div>
          </div>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={`border-b border-black ${forcedEmail && "bg-gray-200 text-gray-700"}`}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!forcedEmail}
          ></Input>
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            required
            placeholder={t("signUpModal.password")}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (weakPassword && e.target.value.length >= 6) {
                setWeakPassword(false);
              }
            }}
          ></Input>
          <Input
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            autoComplete="new-password"
            required
            placeholder={t("signUpModal.passwordConfirmation")}
            value={passwordConfirmation}
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
              // Clear mismatch error when passwords match
              if (passwordMismatch && e.target.value === password) {
                setPasswordMismatch(false);
              }
            }}
          ></Input>
          <div className="w-full">
            <button
              type="button"
              className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
              onClick={async () => {
                // Reset error states
                setEmailAlreadyInUse(false);
                setEmailCheckError(null);
                setUploadError(null);
                setIsUploading(false);

                try {
                  const emailExists = await isEmailAlreadyRegistered(email);

                  if (emailExists) {
                    // Email is already in use
                    setEmailAlreadyInUse(true);
                    return;
                  }

                  if (validatePassword()) {
                    try {
                      setIsUploading(true);

                      // Create user with email and password
                      const userCredential = await createUserWithEmailAndPassword(
                        auth,
                        email,
                        password
                      );
                      const user = userCredential.user;
                      console.log("User registered successfully:", user.uid);

                      // Upload profile picture if one was selected
                      let profilePictureUrl = null;
                      if (selectedImage) {
                        try {
                          profilePictureUrl = await uploadProfilePicture(user.uid);
                          console.log("Profile picture uploaded:", profilePictureUrl);

                          // Update the user's profile with the profile picture URL
                          await updateUserProfile(user, profilePictureUrl);
                        } catch (uploadErr) {
                          console.error("Failed to upload profile picture:", uploadErr);
                          setUploadError(
                            "Your account was created but there was an error saving your profile picture."
                          );
                          // Continue with registration even if image upload fails
                        }
                      } else {
                        // Still update user profile without a profile picture
                        await updateUserProfile(user, null);
                      }

                      // Close the modal after successful registration
                      onClose();

                      setIsUploading(false);
                      setEmailAlreadyInUse(false);
                    } catch (error: any) {
                      setIsUploading(false);
                      console.error("Error during registration:", error);
                      // Show more specific error message
                      if (error.code === "auth/email-already-in-use") {
                        setEmailAlreadyInUse(true);
                      } else {
                        setEmailCheckError(
                          error.message || "Error creating account. Please try again."
                        );
                      }
                    }
                  }
                } catch (error: any) {
                  setIsUploading(false);
                  console.error("Error checking email:", error);
                  setEmailCheckError(
                    error.message || "Error checking if email exists. Please try again."
                  );
                }
              }}
            >
              {isUploading ? (
                <span className="flex items-center justify-center">
                  <LoadingSpinner />
                  Creating account...
                </span>
              ) : (
                t("signUpModal.signIn")
              )}
            </button>
            <Button
              variant="blue"
              onClick={() => {
                if (showLogin) {
                  showLogin();
                } else {
                  onClose();
                }
              }}
            >
              Har du allerede bruker, logg inn ved å trykke her
            </Button>
            {weakPassword && (
              <Button variant="warning">
                {t(
                  "signUpModal.passwordTooShort",
                  "Password must be at least 6 characters"
                )}
              </Button>
            )}
            {passwordMismatch && (
              <Button variant="warning">
                {t("signUpModal.passwordsDoNotMatch", "Passwords do not match")}
              </Button>
            )}
            {emailAlreadyInUse && (
              <Button
                variant="warning"
                className="whitespace-normal"
                onClick={() => {
                  sendPasswordResetEmail(auth, email, actionCodeSettings);
                  setPasswordResetModalOpen(true);
                }}
              >
                {`E-posten ${email} er allerede registrert. Tilbakestill passord ved å klikke her`}
              </Button>
            )}
            {uploadError && (
              <div className="mt-2 text-sm text-orange-600">{uploadError}</div>
            )}
          </div>
          {/* Display email check error if any */}
          {emailCheckError && (
            <div className="mt-2 text-sm text-red-600">{emailCheckError}</div>
          )}
        </>
        <ModalTile
          open={emailLinkSignUpModalOpen}
          onClose={() => {
            setEmailLinkSignUpModalOpen(false);
            onModalClose();
          }}
          title={t("signUpModal.emailSent")}
        >
          <div className="border">
            <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
              {t("signUpModal.linkSentMessage")}
            </p>
          </div>
        </ModalTile>
      </ModalTile>

      {/* Password Reset Modal */}
      <ModalTile
        open={passwordResetModalOpen}
        onClose={() => {
          setPasswordResetModalOpen(false);
        }}
        title="Tilbakestill passord"
      >
        <div className="border">
          <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
            En lenke for å tilbakestille passordet er sendt til {email}. Sjekk innboksen
            din (og eventuelt spam-mappen) for å finne lenken.
          </p>
          <div className="w-full">
            <button
              type="button"
              className="w-full border-t border-black bg-[#8cd7ff] p-4 text-center text-sm hover:bg-[#7bc3e0]"
              onClick={() => {
                setPasswordResetModalOpen(false);
              }}
            >
              Lukk
            </button>
          </div>
        </div>
      </ModalTile>
    </>
  );
};

export default SignUpModal;
