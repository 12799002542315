import { useState } from "react";

import { Order } from "@type/Order";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { t } from "i18next";

import ModalTile from "../../components/ModalTile";
import StepperFromOrderInfo from "../../components/StepperFromOrderInfo";
import { db } from "../../firebase";

interface OrderVisualizerProps {
  orders: Order[];
  showStepper?: boolean;
  onOrderUpdate?: (updatedOrder: Order) => void;
}

const OrderVisualizer: React.FC<OrderVisualizerProps> = ({
  orders,
  showStepper = false,
  onOrderUpdate,
}) => {
  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>({});
  const storage = getStorage();

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [retailPrice, setRetailPrice] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getLatestStatuses = (order: Order): string[] => {
    if (!order.orderInfo || !Array.isArray(order.orderInfo)) return [];

    return order.orderInfo
      .map((info) => {
        if (!info.event || !Array.isArray(info.event) || info.event.length === 0) {
          return "";
        }
        const lastEvent = info.event[info.event.length - 1];
        return lastEvent.status || "";
      })
      .filter((status) => status !== "");
  };

  const getTranslatedStatus = (status: string): string => {
    return t(`workwearOrderPage.${status}`) || status;
  };

  // Open price modal
  const openPriceModal = (order: Order) => {
    setCurrentOrder(order);
    setRetailPrice(order.claim?.retailPrice || 0);
    setIsModalOpen(true);
  };

  // Handle saving retail price
  const handleSaveRetailPrice = async () => {
    if (!currentOrder || !currentOrder.id) return;

    setIsSubmitting(true);
    try {
      const orderRef = doc(db, "orders", currentOrder.id);

      // If claim doesn't exist, create it with required fields
      if (!currentOrder.claim) {
        await updateDoc(orderRef, {
          claim: {
            note: "",
            article: "Repair claim",
            retailPrice: retailPrice,
          },
        });
      } else {
        // Update existing claim with the new retail price
        await updateDoc(orderRef, {
          "claim.retailPrice": retailPrice,
        });
      }

      // Create updated order
      const updatedOrder = {
        ...currentOrder,
        claim: {
          ...(currentOrder.claim || { note: "", article: "Repair claim" }),
          retailPrice,
        },
      };

      // Notify parent component if callback is provided
      if (onOrderUpdate) {
        onOrderUpdate(updatedOrder);
      }

      // Close the modal
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating retail price:", error);
      alert("Failed to update retail price. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const openPDF = async (order: Order, index: number, itemName: string) => {
    setDownloading((prev) => ({ ...prev, [`${order.id}-${index}`]: true }));

    try {
      const pdfRef = ref(storage, `tailorNotes/${order.id}-${index + 1}.pdf`);
      const url = await getDownloadURL(pdfRef);

      // Open PDF in new tab
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening PDF:", error);
      alert("Error opening PDF. Please try again.");
    } finally {
      setDownloading((prev) => ({ ...prev, [`${order.id}-${index}`]: false }));
    }
  };

  return (
    <div>
      {/* Orders list */}
      <div className="space-y-4">
        {orders.map((order) => (
          <div key={order.id} className="border-b border-black p-4">
            <div className="flex flex-wrap justify-between gap-4">
              <div>
                <h3 className="text-lg font-medium">
                  Order ID:{" "}
                  <a
                    href={`/order/${order.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    {order.id.slice(0, 5)}
                  </a>
                </h3>
                <p>Customer: {order.contactInfo?.name || "N/A"}</p>
                <p>Phone: {order.contactInfo?.phone}</p>
                <p>
                  Date:{" "}
                  {new Date(order.date).toLocaleString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p>Total Price: {order.totalPrice} NOK</p>
                {order.brand && <p>Retail: {order.brand}</p>}

                {/* Claim action button - only show if order has a claim */}
                {order.claim && (
                  <button
                    onClick={() => openPriceModal(order)}
                    className={`mt-2 rounded px-4 py-2 text-white ${
                      order.claim.retailPrice
                        ? "bg-blue-600 hover:bg-blue-700" // Blue for edit
                        : "bg-green-600 hover:bg-green-700" // Green for add
                    }`}
                  >
                    {order.claim.retailPrice
                      ? `Edit Sales Price (${order.claim.retailPrice} NOK)`
                      : "Add Sales Price"}
                  </button>
                )}

                <div className="mt-4 space-y-2">
                  {order.orderInfo?.map((info, index) => (
                    <div key={`${order.id}-${index}`} className="flex items-center gap-4">
                      <button
                        onClick={() => openPDF(order, index, info.item || "Item")}
                        disabled={downloading[`${order.id}-${index}`]}
                        className={`min-w-[150px] rounded px-3 py-1 text-sm text-white ${
                          downloading[`${order.id}-${index}`]
                            ? "cursor-not-allowed bg-gray-400"
                            : "bg-blue-600 hover:bg-blue-700"
                        }`}
                      >
                        {downloading[`${order.id}-${index}`]
                          ? "Opening..."
                          : `PDF ${index + 1}: ${info.item || "Item"}`}
                      </button>

                      {showStepper && <StepperFromOrderInfo orderInfo={info} />}
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <p>
                  Statuses:{" "}
                  {getLatestStatuses(order).length > 0
                    ? getLatestStatuses(order).map(getTranslatedStatus).join(", ")
                    : "No statuses"}
                </p>
                {order.deliveryMethod && <p>Delivery Method: {order.deliveryMethod}</p>}
                {order.retrieveMethod && <p>Retrieve Method: {order.retrieveMethod}</p>}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Price Edit Modal */}
      <ModalTile
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          currentOrder?.claim?.retailPrice !== undefined
            ? "Edit Sales Price"
            : "Add Sales Price"
        }
      >
        <div className="p-6">
          <p className="mb-4">Order ID: {currentOrder?.id}</p>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Sales Price (NOK)
            </label>
            <input
              type="number"
              value={retailPrice}
              onChange={(e) => setRetailPrice(Number(e.target.value))}
              className="w-full rounded-md border border-gray-300 p-2 focus:border-blue-500 focus:outline-none"
              min="0"
              step="1"
            />
          </div>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setIsModalOpen(false)}
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveRetailPrice}
              disabled={isSubmitting}
              className={`rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none ${
                isSubmitting ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </ModalTile>
    </div>
  );
};

export default OrderVisualizer;
