import React, { useState } from "react";

import { RotateCcw } from "lucide-react";
import { useTranslation } from "react-i18next";

import ModalTile from "../../components/ModalTile";
import { fallbackImageUrl } from "../../components/newOrder/OrderModal";
import { ActivityItem, Order } from "../../types/Order";
import OrderCardDetails from "./OrderCardDetailsV2";

const OrderLog: React.FC<{
  order: Order;
  activity: any[];
  onRevert?: (activityId: number) => Promise<void>;
  selectedOrderItem?: number;
  hideContactInfo?: boolean;
}> = ({ order, activity, onRevert, selectedOrderItem = -1, hideContactInfo = false }) => {
  const { t } = useTranslation();
  const [revertModalOpen, setRevertModalOpen] = useState(false);
  const [selectedEventIndex, setSelectedEventIndex] = useState<number | null>(null);
  const [isReverting, setIsReverting] = useState(false);

  const currentUserEmail = localStorage.getItem("uid");
  const userRoles = localStorage.getItem("roles")?.split(",") || [];

  const currentItem =
    selectedOrderItem >= 0
      ? order?.orderInfo?.[selectedOrderItem]
      : order?.orderInfo?.[0];
  const events = currentItem?.event || [];
  const orderStates = currentItem?.eventPredictor || [];

  const handleRevertClick = (eventIndex: number) => {
    setSelectedEventIndex(eventIndex);
    setRevertModalOpen(true);
  };

  const handleConfirmRevert = async () => {
    if (selectedEventIndex === null || !onRevert) return;

    setIsReverting(true);
    try {
      await onRevert(selectedEventIndex);
      setRevertModalOpen(false);
    } catch (error) {
      console.error("Failed to revert event:", error);
    } finally {
      setIsReverting(false);
    }
  };

  const canRevert = (event: any, eventIdx: number) => {
    if (eventIdx !== events.length - 1) return false; // Only allow reverting last event
    if (event.handler === currentUserEmail) return true;
    if (userRoles.includes("operator")) return true;

    const status = event.status;
    return (
      status === "orderStateCreated" ||
      status === "orderStatePickedUpFromTailor" ||
      (status === "orderStateDeliverToTailor" && userRoles.includes("tailor")) ||
      ((status === "orderStateCustomerDropOff" || status === "orderStateRepaired") &&
        userRoles.includes("transporter")) ||
      (status === "orderStatePickedUpFromTailor" && userRoles.includes("courier"))
    );
  };

  if (selectedOrderItem === -1 && order?.orderInfo.length > 1) {
    return (
      <div>
        <div
          className={`hidden ${
            order?.orderInfo?.some((info) => info.imageUrl !== fallbackImageUrl)
              ? "min-h-[calc(55vh-7rem)]"
              : ""
          } md:block`}
        >
          <OrderCardDetails order={{ ...order, orderInfo: order?.orderInfo || [] }} />
        </div>

        {order.orderInfo.map((item, index) => (
          <div key={index}>
            <p className="border-b border-black p-4 pt-10 text-base">
              {t("workwearOrderPage.trackingLog")} - {index + 1}: {item.item}
            </p>

            <ul role="list" className="mb-0">
              {(item.event || []).map((event, eventIdx) => {
                const message = t(`workwearOrderPage.${event.status}`);
                const timestamp = event.date;
                const formattedTime = new Date(timestamp).toLocaleString("en-GB", {
                  dateStyle: "short",
                  timeStyle: "short",
                });
                return (
                  <li
                    key={`${timestamp}-${eventIdx}`}
                    className={`border-b border-black p-4 text-base ${
                      eventIdx === (item.event || []).length - 1
                        ? "bg-fikseBeige text-black"
                        : "text-fikseGrey"
                    }`}
                  >
                    <span className="flex items-center justify-between">
                      <span>{message}</span>
                      <span className="ml-1 flex items-center gap-2">
                        <time dateTime={timestamp}>{formattedTime}</time>
                      </span>
                    </span>
                  </li>
                );
              })}

              {(item.eventPredictor || [])
                .slice(
                  (item.eventPredictor || []).indexOf(
                    (item.event || [])[(item.event || []).length - 1]?.status
                  ) + 1
                )
                .map((state, index) => (
                  <li
                    key={`future-state-${index}`}
                    className="border-b border-black p-4 text-base text-fikseGrey"
                  >
                    {t(`workwearOrderPage.${state}`)}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }

  /*
  // No clue what the purpose of this was, but removed it in order
  // to fix the bug where the order card was not visible on the order page.  

  if (selectedOrderItem === -1) {
    return (
      <div
        className={`hidden ${
          order?.orderInfo?.some((info) => info.imageUrl !== fallbackImageUrl)
            ? "min-h-[calc(55vh-7rem)]"
            : ""
        } md:block`}
      >
        <OrderCardDetails order={{ ...order, orderInfo: order?.orderInfo || [] }} />
      </div>
    );
  }*/

  return (
    <div>
      <div
        className={`hidden ${
          order?.orderInfo?.some((info) => info.imageUrl !== fallbackImageUrl)
            ? "min-h-[calc(55vh-7rem)]"
            : ""
        } md:block`}
      >
        <OrderCardDetails order={{ ...order, orderInfo: order?.orderInfo || [] }} />
      </div>

      <p className="border-b border-black p-4 pt-16 text-base">
        {t("workwearOrderPage.trackingLog")}{" "}
      </p>
      <ul role="list" className="-mb-8 min-h-[478px]">
        {events.map((event, eventIdx) => {
          const message = t(`workwearOrderPage.${event.status}`);
          const timestamp = event.date;
          const formattedTime = new Date(timestamp).toLocaleString("en-GB", {
            dateStyle: "short",
            timeStyle: "short",
          });
          return (
            <li
              key={`${timestamp}-${eventIdx}`}
              className={`border-b border-black p-4 text-base ${
                eventIdx === events.length - 1
                  ? "bg-fikseBeige text-black"
                  : "text-fikseGrey"
              }`}
            >
              <span className="flex items-center justify-between">
                <span>{message}</span>
                {canRevert(event, eventIdx) && (
                  <button
                    onClick={() => handleRevertClick(eventIdx)}
                    disabled={isReverting}
                    className="hover:text-fikseBlueHover inline-flex items-center gap-2 text-base font-bold text-fikseRed disabled:opacity-50"
                  >
                    <RotateCcw className="h-4 w-4" />
                    {t("revert")}
                  </button>
                )}
                <span className="ml-1 flex items-center gap-2">
                  <time dateTime={timestamp}>{formattedTime}</time>
                </span>
              </span>
            </li>
          );
        })}

        {orderStates
          .slice(orderStates.indexOf(events[events.length - 1]?.status) + 1)
          .map((state, index) => (
            <li
              key={`future-state-${index}`}
              className="border-b border-black p-4 text-base text-fikseGrey"
            >
              {t(`workwearOrderPage.${state}`)}
            </li>
          ))}
      </ul>

      <ModalTile
        open={revertModalOpen}
        onClose={() => setRevertModalOpen(false)}
        title={t("revertActivity")}
      >
        <div className="space-y-6">
          <p className="text-base">{t("revertConfirmation")}</p>
          <div className="flex w-full border-t border-black">
            <button
              type="button"
              className="flex-1 border-r border-black bg-fikseBeige p-4 text-base hover:bg-fikseBeigeHover"
              onClick={() => setRevertModalOpen(false)}
              disabled={isReverting}
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="hover:bg-fikseRedHover flex-1 bg-fikseRed p-4 text-base"
              onClick={handleConfirmRevert}
              disabled={isReverting}
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      </ModalTile>
    </div>
  );
};

export default OrderLog;
