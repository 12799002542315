import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChartPieIcon,
  ChatBubbleLeftIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  ScissorsIcon,
} from "@heroicons/react/24/outline";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

import { getCurrentUserEmail, getCurrentUserEmailAsync } from "../auth";
import NavBar from "../components/NavBar";
import LoggInModal from "../components/SignInModal";
import FikseAdminOverview from "../components/admin/FikseAdminOverview";
import OfficeDashboard from "../components/admin/OfficeDashboard";
import OrderOverview from "../components/admin/OrderOverview";
import OrderOverview2 from "../components/admin/OrderOverviewWorkWear";
import OrdersLegacy from "../components/admin/OrdersLegacy";
import Overview from "../components/admin/Overview";
import Reports from "../components/admin/Reports";
import RetailOverview from "../components/admin/RetailOverview";
import ServiceCategoriesOverview from "../components/admin/ServiceCategoriesOverview";
import TailorOverview from "../components/admin/TailorOverview";
import AdminDiscountOverview from "../components/admin/discount/AdminDiscountOverview";
import LoadingSpinner from "../components/fikseLoadingLetter";
import Log2 from "../dashboard/Log2";
import LogisticsOrderTailorDeligation from "../dashboard/LogisticsOrderTailorDeligation";
import PlayGround from "../dashboard/PlayGround";
import RetailAnalytics from "../dashboard/RetailAnalytics";
import RetailModerator from "../dashboard/RetailModerator";
import RetailOrders from "../dashboard/RetailOrders";
import RetailOrdersV2 from "../dashboard/RetailOrdersV3";
import SendSMS from "../dashboard/SendSMS";
import TailorModerator from "../dashboard/TailorModerator";
import { auth, db } from "../firebase";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

export async function accountHasAccessToDashboard(email: string): Promise<boolean> {
  if (!email) return false;

  try {
    // Check all possible access roles in parallel
    const [
      isAdmin,
      isTailor,
      isRetailModerator,
      isWorkwear,
      isTailorModerator,
      isRetailEmployee,
    ] = await Promise.all([
      checkIfUserIsAdmin(email),
      checkIfUserIsTailor(email),
      checkIfUserIsRetailModerator(email),
      checkIfUserIsWorkwear(email),
      checkIfUserIsTailorModerator(email),
      checkIfUserIsRetailEmployee(email),
    ]);

    // Return true if the user has any valid role
    return (
      isAdmin ||
      isTailor ||
      isRetailModerator ||
      isRetailEmployee ||
      isWorkwear ||
      isTailorModerator
    );
  } catch (error) {
    console.error("Error checking dashboard access:", error);
    return false;
  }
}

export const checkIfUserIsAdmin = async (email: string) => {
  try {
    const adminDocRef = doc(db, "dashboard", "admin");
    const adminDocSnapshot = await getDoc(adminDocRef);
    if (adminDocSnapshot.exists()) {
      const authorizedEmails = adminDocSnapshot.data().email || [];
      return authorizedEmails.includes(email);
    }
    return false;
  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
};

export const checkIfUserIsTailor = async (email: string) => {
  const tailorsRef = collection(db, "tailors");
  const snapshot = await getDocs(tailorsRef);
  for (const doc of snapshot.docs) {
    const preVerifiedEmails = doc.data().preVerifiedEmails || [];
    if (preVerifiedEmails.includes(email)) return true;
  }
  return false;
};

export const checkIfUserIsRetailModerator = async (email: string) => {
  const retailRef = collection(db, "retail");
  const snapshot = await getDocs(retailRef);
  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (
      data.contactPerson?.email === email ||
      (data.moderatorEmails && data.moderatorEmails.includes(email))
    ) {
      return true;
    }
  }
  return false;
};

export const checkIfUserIsWorkwear = async (email: string) => {
  try {
    const workwearDocRef = doc(db, "dashboard", "workwearDashboard");
    const workwearDocSnapshot = await getDoc(workwearDocRef);
    if (workwearDocSnapshot.exists()) {
      const authorizedEmails = workwearDocSnapshot.data().email || [];
      return authorizedEmails.includes(email);
    }
    return false;
  } catch (error) {
    console.error("Error checking workwear status:", error);
    return false;
  }
};

export const checkIfUserIsTailorModerator = async (email: string) => {
  const tailorsRef = collection(db, "tailors");
  const snapshot = await getDocs(tailorsRef);
  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (
      data.contactPerson?.email === email ||
      (data.moderatorEmails && data.moderatorEmails.includes(email))
    ) {
      return true;
    }
  }
  return false;
};

export const checkIfUserIsRetailEmployee = async (email: string) => {
  const retailRef = collection(db, "retail");
  const snapshot = await getDocs(retailRef);
  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (data.preVerifiedEmails && data.preVerifiedEmails.includes(email)) {
      return true;
    }
  }
  return false;
};

export const getUserRetailConnections = async (
  email: string
): Promise<{ id: string; name: string }[]> => {
  const retailRef = collection(db, "retail");
  const snapshot = await getDocs(retailRef);
  const connections = [];

  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (
      data.contactPerson?.email === email ||
      (data.moderatorEmails && data.moderatorEmails.includes(email)) ||
      (data.preVerifiedEmails && data.preVerifiedEmails.includes(email))
    ) {
      connections.push({
        id: doc.id,
        name: data.name || "Unknown Retail",
      });
    }
  }

  return connections;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationGroup {
  label: string;
  items: {
    id: string;
    name: string;
    href: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    current: boolean;
  }[];
}

const tailorNavigation: NavigationGroup[] = [
  {
    label: "Tailor",
    items: [
      {
        id: "tailor-dashboard",
        name: "Dashboard",
        href: "/dashboard/tailor",
        icon: ScissorsIcon,
        current: false,
      },
      {
        id: "tailor-orders",
        name: "Orders",
        href: "/dashboard/tailor/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "tailor-reports",
        name: "Reports",
        href: "/dashboard/tailor/reports",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "tailor-moderator",
        name: "Moderator",
        href: "/dashboard/tailor/moderator",
        icon: Cog6ToothIcon,
        current: false,
      },
    ],
  },
];

const workwearNavigation: NavigationGroup[] = [
  {
    label: "WorkWear",
    items: [
      {
        id: "workwear-orders",
        name: "Orders",
        href: "/dashboard/workwear/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "workwear-reports",
        name: "Future Features",
        href: "/dashboard/workwear/reports",
        icon: ChartPieIcon,
        current: false,
      },
    ],
  },
];

const adminNavigation: NavigationGroup[] = [
  {
    label: "Fikse admin",
    items: [
      {
        id: "admin-overview",
        name: "Overview",
        href: "/dashboard/admin",
        icon: CalendarIcon,
        current: true,
      },
      {
        id: "admin-orders-legacy",
        name: "Orders Legacy",
        href: "/dashboard/admin/orders-legacy",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-orders",
        name: "Orders",
        href: "/dashboard/admin/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-reports",
        name: "Reports",
        href: "/dashboard/admin/reports",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "admin-tailor",
        name: "Repair partners",
        href: "/dashboard/admin/tailor",
        icon: ScissorsIcon,
        current: false,
      },
      {
        id: "admin-retail",
        name: "Retail partners",
        href: "/dashboard/admin/retail",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-service-categories",
        name: "Service categories",
        href: "/dashboard/admin/service-categories",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-discount",
        name: "Discount codes",
        href: "/dashboard/admin/discount",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "admin-sms",
        name: "SMS",
        href: "/dashboard/admin/sms",
        icon: ChatBubbleLeftIcon,
        current: false,
      },
      {
        id: "admin-playground",
        name: "DevPlayground",
        href: "/dashboard/admin/playground",
        icon: Cog6ToothIcon,
        current: false,
      },
      {
        id: "admin-logistics",
        name: "Logistics",
        href: "/dashboard/admin/logistics",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-office",
        name: "Admin Office",
        href: "/dashboard/admin/office",
        icon: ClipboardDocumentIcon,
        current: false,
      },
    ],
  },
];

const retailNavigation: NavigationGroup[] = [
  {
    label: "Retail",
    items: [
      {
        id: "retail-moderator",
        name: "Overview",
        href: "/dashboard/retail",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "retail-orders",
        name: "Orders",
        href: "/dashboard/retail/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "retail-analytics",
        name: "Analytics",
        href: "/dashboard/retail/analytics",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "retail-workers",
        name: "Workers",
        href: "/dashboard/retail/workers",
        icon: ClipboardDocumentIcon,
        current: false,
      },
    ],
  },
];

export default function DashboardPage() {
  const [activeNavigationGroup, setActiveNavigationGroup] = useState<string>("");
  const [navigation, setNavigation] = useState<NavigationGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // First check login status before loading anything
  useEffect(() => {
    const checkLoginStatus = async () => {
      const email = await getCurrentUserEmailAsync();
      if (!email) {
        // User is not logged in
        setIsLoggedIn(false);
        setIsLoading(false); // Stop loading immediately
        return;
      }

      setIsLoggedIn(true);

      // Continue with access checks for logged-in users
      const hasAccess = await accountHasAccessToDashboard(email);
      if (!hasAccess) {
        // If user is logged in but doesn't have access, redirect to dashboard
        navigate("/dashboard");
        return;
      }

      // Check if the current path is accessible
      const path = location.pathname;
      const isPathAuthorized = await checkPathAuthorization(email, path);
      if (!isPathAuthorized) {
        navigate("/dashboard");
      }
    };

    checkLoginStatus();
  }, [location.pathname]);

  // Set up navigation once when component mounts or user changes
  useEffect(() => {
    // Only set up navigation if the user is logged in
    if (!isLoggedIn) return;

    const setupNavigation = async () => {
      setIsLoading(true);
      const user = auth.currentUser;
      if (!user?.email) return;

      const [
        isAdmin,
        isTailor,
        isRetailModerator,
        isWorkwear,
        isTailorModerator,
        isRetailEmployee,
      ] = await Promise.all([
        checkIfUserIsAdmin(user.email),
        checkIfUserIsTailor(user.email),
        checkIfUserIsRetailModerator(user.email),
        checkIfUserIsWorkwear(user.email),
        checkIfUserIsTailorModerator(user.email),
        checkIfUserIsRetailEmployee(user.email),
      ]);

      if (
        !isAdmin &&
        !isTailor &&
        !isRetailModerator &&
        !isWorkwear &&
        !isTailorModerator &&
        !isRetailEmployee
      ) {
        navigate("/dashboard");
        return;
      }

      let finalNavigation: NavigationGroup[] = [];
      if (isAdmin) finalNavigation.push(...adminNavigation);
      if (isTailor || isTailorModerator) {
        const tailorNav = {
          ...tailorNavigation[0],
          items: [...tailorNavigation[0].items],
        };

        if (!isTailorModerator) {
          tailorNav.items = tailorNav.items.filter(
            (item) => item.id !== "tailor-moderator"
          );
        }

        finalNavigation.push(tailorNav);
      }
      if (isRetailModerator) {
        finalNavigation.push(...retailNavigation);
      } else if (isRetailEmployee) {
        // For retail employees, only show the orders page
        finalNavigation.push({
          label: "Retail",
          items: [
            {
              id: "retail-orders",
              name: "Orders",
              href: "/dashboard/retail/orders",
              icon: ClipboardDocumentIcon,
              current: true, // Mark as current by default for single-tab users
            },
          ],
        });
      }
      if (isWorkwear) finalNavigation.push(...workwearNavigation);

      // Update current status based on path
      const currentPath = location.pathname;
      finalNavigation = finalNavigation.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          current: item.href === currentPath,
        })),
      }));

      setNavigation(finalNavigation);

      // Set active navigation group based on the current path
      const currentGroup = finalNavigation.find((group) =>
        group.items.some((item) => item.href === currentPath)
      );

      if (currentGroup) {
        setActiveNavigationGroup(currentGroup.label);
      } else if (finalNavigation.length > 0) {
        setActiveNavigationGroup(finalNavigation[0].label);

        // If we have a single group with a single item, auto-navigate to it
        if (finalNavigation.length === 1 && finalNavigation[0].items.length === 1) {
          const onlyItem = finalNavigation[0].items[0];
          if (currentPath !== onlyItem.href) {
            navigate(onlyItem.href);
          }
        }
      }

      setIsLoading(false);
    };

    setupNavigation();
  }, [auth.currentUser, isLoggedIn]);

  // Handle login modal close
  const handleLoginModalClose = () => {
    setShowLoginModal(false);
    // After closing the login modal, check if user is now logged in
    const checkLoginStatus = async () => {
      const email = await getCurrentUserEmailAsync();
      if (email) {
        // User is now logged in, check access again
        const hasAccess = await accountHasAccessToDashboard(email);
        if (hasAccess) {
          // Refresh page to load appropriate dashboard
          window.location.reload();
        } else {
          // User is logged in but doesn't have access
          navigate("/dashboard");
        }
      }
    };
    checkLoginStatus();
  };

  // Handle login button click
  const handleLoginButtonClick = () => {
    setShowLoginModal(true);
  };

  // Render login screen when user is not logged in
  const renderLoginScreen = () => {
    return (
      <div className="flex min-h-[400px] flex-col items-center justify-center p-8">
        <h2 className="mb-6 text-2xl font-semibold">Dashboard Access</h2>
        <p className="mb-8 text-center text-gray-600">
          You need to be logged in to access the dashboard.
        </p>
        <button
          className="rounded-md bg-[#8cd7ff] px-6 py-3 font-medium text-black hover:bg-[#7bc3e0]"
          onClick={handleLoginButtonClick}
        >
          Log In
        </button>
      </div>
    );
  };

  // Function to check if user has access to specific path
  const checkPathAuthorization = async (email: string, path: string) => {
    const [
      isAdmin,
      isTailor,
      isRetailModerator,
      isWorkwear,
      isTailorModerator,
      isRetailEmployee,
    ] = await Promise.all([
      checkIfUserIsAdmin(email),
      checkIfUserIsTailor(email),
      checkIfUserIsRetailModerator(email),
      checkIfUserIsWorkwear(email),
      checkIfUserIsTailorModerator(email),
      checkIfUserIsRetailEmployee(email),
    ]);

    // Check path against user roles
    if (path.startsWith("/dashboard/admin") && !isAdmin) return false;
    if (path.startsWith("/dashboard/tailor") && !isTailor && !isTailorModerator)
      return false;
    if (path.startsWith("/dashboard/retail")) {
      if (path === "/dashboard/retail/orders") {
        return isRetailModerator || isRetailEmployee;
      }
      return isRetailModerator; // Only moderators can access other retail pages
    }
    if (path.startsWith("/dashboard/workwear") && !isWorkwear) return false;

    return true;
  };

  // Update current navigation item when path changes
  useEffect(() => {
    const currentPath = location.pathname;

    // Find which navigation group contains the current path
    let foundCurrentGroup = false;

    setNavigation((prevNav) => {
      const updatedNav = prevNav.map((group) => {
        // Check if this group contains the current path
        const containsCurrentPath = group.items.some((item) => item.href === currentPath);
        if (containsCurrentPath) {
          foundCurrentGroup = true;
        }

        return {
          ...group,
          items: group.items.map((item) => ({
            ...item,
            current: item.href === currentPath,
          })),
        };
      });

      return updatedNav;
    });

    // Update active navigation group if we found a group containing the current path
    setNavigation((prevNav) => {
      const currentGroup = prevNav.find((group) =>
        group.items.some((item) => item.href === currentPath)
      );

      if (currentGroup) {
        setActiveNavigationGroup(currentGroup.label);
      }

      return prevNav;
    });
  }, [location.pathname]);

  const handleNavigationChange = (href: string) => {
    navigate(href);
  };

  const handleGroupChange = (groupLabel: string) => {
    setActiveNavigationGroup(groupLabel);

    // Find the first item in the selected group and navigate to it
    const selectedGroup = navigation.find((group) => group.label === groupLabel);
    if (selectedGroup && selectedGroup.items.length > 0) {
      // If there's a current item in this group, navigate to it
      const currentItem = selectedGroup.items.find((item) => item.current);
      if (currentItem) {
        navigate(currentItem.href);
      } else {
        // Otherwise navigate to the first item
        navigate(selectedGroup.items[0].href);
      }
    }
  };

  const renderCurrentComponent = () => {
    const path = location.pathname;

    // If we're on the root dashboard path and have navigation groups,
    // try to render the content for the first item in the active group
    if (path === "/dashboard" && navigation.length > 0) {
      const activeGroup = navigation.find(
        (group) => group.label === activeNavigationGroup
      );
      if (activeGroup && activeGroup.items.length > 0) {
        const firstItemPath = activeGroup.items[0].href;
        // Redirect to the first item's path
        navigate(firstItemPath);
        // For now, show a loading indicator
        return <LoadingSpinner className="py-12" />;
      }
    }

    switch (path) {
      case "/dashboard/tailor":
        return <div>This is the Tailor Dashboard (empty for now).</div>;
      case "/dashboard/tailor/orders":
        return <OrderOverview />;
      case "/dashboard/workwear/orders":
        return <OrderOverview2 />;
      case "/dashboard/tailor/reports":
        return <Reports />;
      case "/dashboard/admin":
        return <Overview />;
      case "/dashboard/admin/orders":
        return <FikseAdminOverview />;
      case "/dashboard/admin/orders-legacy":
        return <OrdersLegacy />;
      case "/dashboard/admin/reports":
        return <Reports />;
      case "/dashboard/admin/tailor":
        return <TailorOverview />;
      case "/dashboard/admin/retail":
        return <RetailOverview />;
      case "/dashboard/admin/service-categories":
        return <ServiceCategoriesOverview />;
      case "/dashboard/admin/discount":
        return <AdminDiscountOverview />;
      case "/dashboard/admin/sms":
        return <SendSMS />;
      case "/dashboard/admin/playground":
        return <PlayGround />;
      case "/dashboard/retail":
        return <RetailModerator />;
      case "/dashboard/retail/orders-legacy":
        return <RetailOrders />;
      case "/dashboard/retail/orders":
        return <RetailOrdersV2 />;
      case "/dashboard/retail/analytics":
        return <RetailAnalytics />;
      case "/dashboard/retail/workers":
        return <RetailModerator />;
      case "/dashboard/admin/logistics":
        return <LogisticsOrderTailorDeligation />;
      case "/dashboard/tailor/moderator":
        return <TailorModerator />;
      case "/dashboard/admin/office":
        return <OfficeDashboard />;
      default:
        // If we don't have a specific component for this path but have navigation,
        // try to find an appropriate component based on the active group
        if (navigation.length > 0) {
          const activeGroup = navigation.find(
            (group) => group.label === activeNavigationGroup
          );
          if (activeGroup && activeGroup.items.length > 0) {
            // Find the first item that should be current
            const currentItem = activeGroup.items.find((item) => item.current);
            if (currentItem && currentItem.href !== path) {
              // Redirect to the correct path
              navigate(currentItem.href);
              return <LoadingSpinner className="py-12" />;
            }
          }
        }
        // If all else fails, show an empty div
        return <div></div>;
    }
  };

  // Render navigation items for the active group
  const renderNavigationItems = () => {
    const activeGroup = navigation.find((group) => group.label === activeNavigationGroup);

    if (!activeGroup) return null;

    // If there's only one item, no need to show tab navigation
    if (activeGroup.items.length === 1) {
      // Still set the current status for the single item
      const singleItem = activeGroup.items[0];
      if (!singleItem.current) {
        // Ensure we navigate to this path if not already there
        if (location.pathname !== singleItem.href) {
          navigate(singleItem.href);
        }

        setNavigation((prevNav) =>
          prevNav.map((group) => ({
            ...group,
            items: group.items.map((item) => ({
              ...item,
              current: item.id === singleItem.id,
            })),
          }))
        );
      }

      // Instead of returning null, show a simple header to indicate what section they're in
      return (
        <div className="mb-6 border-b border-gray-200 px-4 py-2">
          <h2 className="text-lg font-medium">{singleItem.name}</h2>
        </div>
      );
    }

    // Find the current active item's href
    const currentHref =
      activeGroup.items.find((item) => item.current)?.href || activeGroup.items[0]?.href;

    return (
      <div className="mb-6">
        <Tabs
          value={currentHref}
          onValueChange={handleNavigationChange}
          className="w-full border-b border-black"
        >
          <TabsList>
            {activeGroup.items.map((item) => (
              <TabsTrigger
                key={item.id}
                value={item.href}
                disabled={false} // Ensure no tab is disabled
              >
                {item.name}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>
    );
  };

  return (
    <>
      <div>
        {/* Main content */}
        <div>
          <div className="border-gray-20 sticky top-0 z-[80] flex h-16 shrink-0 items-center justify-between border-b bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <div className="flex w-full items-center justify-between">
              <FikseLogo
                className="h-8 w-auto hover:cursor-pointer"
                onClick={() => (window.location.href = "/dashboard")}
              />
              <div className="flex-1">
                <NavBar />
              </div>
            </div>
          </div>

          <main className="container mx-auto mt-10 border border-black">
            {isLoggedIn === false ? (
              // User is not logged in - show login screen
              renderLoginScreen()
            ) : isLoading ? (
              // User is logged in but we're still loading data
              <div className="flex items-center justify-center py-12">
                <LoadingSpinner className="min-h-[400px] w-full max-w-md" />
              </div>
            ) : (
              // User is logged in and data is loaded
              <>
                {/* Tab navigation for groups - only show if there's more than one navigation group */}
                {navigation.length > 1 && (
                  <Tabs
                    value={activeNavigationGroup}
                    onValueChange={handleGroupChange}
                    className="border-b border-black"
                  >
                    <TabsList>
                      {navigation.map((group) => (
                        <TabsTrigger key={group.label} value={group.label}>
                          {group.label}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                  </Tabs>
                )}

                {/* Navigation items for the active group */}
                {renderNavigationItems()}

                {/* Content area - always render regardless of tabs */}
                <div className="mt-4">{renderCurrentComponent()}</div>
              </>
            )}
          </main>
        </div>
      </div>

      {/* Login Modal */}
      <LoggInModal open={showLoginModal} onClose={handleLoginModalClose} />
    </>
  );
}
