import * as React from "react";

import * as TabsPrimitive from "@radix-ui/react-tabs";

import { cn } from "../utils";

function Tabs({ className, ...props }: React.ComponentProps<typeof TabsPrimitive.Root>) {
  return (
    <TabsPrimitive.Root
      data-slot="tabs"
      className={cn("flex w-full flex-col", className)}
      {...props}
    />
  );
}

function TabsList({
  className,
  ...props
}: React.ComponentProps<typeof TabsPrimitive.List>) {
  const listRef = React.useRef<HTMLDivElement>(null);
  const [showLeftScroll, setShowLeftScroll] = React.useState(false);
  const [showRightScroll, setShowRightScroll] = React.useState(false);

  // Check if scroll buttons should be visible
  const checkScrollButtons = React.useCallback(() => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft + clientWidth < scrollWidth - 10); // Small buffer for rounding errors
    }
  }, []);

  // Add scroll event listener
  React.useEffect(() => {
    const scrollContainer = listRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", checkScrollButtons);
      // Initial check
      checkScrollButtons();

      // Check on window resize too
      window.addEventListener("resize", checkScrollButtons);

      return () => {
        scrollContainer.removeEventListener("scroll", checkScrollButtons);
        window.removeEventListener("resize", checkScrollButtons);
      };
    }
  }, [checkScrollButtons]);

  // Scroll left/right handlers
  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div className="relative overflow-hidden">
      {/* Left scroll button */}
      {showLeftScroll && (
        <button
          className="absolute bottom-0 left-0 top-0 z-10 h-full cursor-pointer bg-gradient-to-r from-white to-transparent px-2"
          onClick={scrollLeft}
          aria-label="Scroll left"
        >
          <div className="rounded-full bg-white p-1 shadow-sm">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 18L9 12L15 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      )}

      <div
        ref={listRef}
        className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent no-scrollbar overflow-x-auto"
      >
        <TabsPrimitive.List
          data-slot="tabs-list"
          className={cn(
            "bg-muted text-muted-foreground flex h-8 items-center justify-start p-0 py-6",
            className
          )}
          {...props}
        />
      </div>

      {/* Right scroll button */}
      {showRightScroll && (
        <button
          className="absolute bottom-0 right-0 top-0 z-10 h-full cursor-pointer bg-gradient-to-l from-white to-transparent px-2"
          onClick={scrollRight}
          aria-label="Scroll right"
        >
          <div className="rounded-full bg-white p-1 shadow-sm">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 6L15 12L9 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      )}
    </div>
  );
}

function TabsTrigger({
  className,
  ...props
}: React.ComponentProps<typeof TabsPrimitive.Trigger>) {
  return (
    <TabsPrimitive.Trigger
      data-slot="tabs-trigger"
      className={cn(
        "data-[state=active]:text-foreground focus-visible:border-ring focus-visible:ring-ring/50 focus-visible:outline-ring inline-flex min-w-[120px] flex-shrink-0 items-center justify-center whitespace-nowrap border-r border-black px-6 py-[14px] text-sm font-medium transition-[color,box-shadow] focus-visible:outline-1 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-fikseBlue data-[state=active]:shadow-sm [&_svg:not([class*='size-'])]:size-4 [&_svg]:pointer-events-none [&_svg]:shrink-0",
        className
      )}
      {...props}
    />
  );
}

function TabsContent({
  className,
  ...props
}: React.ComponentProps<typeof TabsPrimitive.Content>) {
  return (
    <TabsPrimitive.Content
      data-slot="tabs-content"
      className={cn("flex-1 outline-none", className)}
      {...props}
    />
  );
}

// Add custom scrollbar styles to the global CSS (can be placed at the bottom of the file)
const addScrollbarStyles = () => {
  // Only add styles once
  if (document.getElementById("custom-scrollbar-styles")) return;

  const style = document.createElement("style");
  style.id = "custom-scrollbar-styles";
  style.innerHTML = `
    /* Hide scrollbar but keep functionality */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;     /* Firefox */
    }
    .no-scrollbar::-webkit-scrollbar {
      display: none;             /* Chrome, Safari, Opera */
    }
    
    /* Custom scrollbar styling */
    .scrollbar-thin::-webkit-scrollbar {
      height: 4px;
    }
    .scrollbar-thumb-gray-300::-webkit-scrollbar-thumb {
      background-color: #d1d5db;
      border-radius: 9999px;
    }
    .scrollbar-track-transparent::-webkit-scrollbar-track {
      background-color: transparent;
    }
  `;
  document.head.appendChild(style);
};

// Execute on component mount
if (typeof window !== "undefined") {
  addScrollbarStyles();
}

export { Tabs, TabsList, TabsTrigger, TabsContent };
