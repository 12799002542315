// /src/pages/OrderPage/OrderPage.tsx
import React, { useEffect, useRef, useState } from "react";

import { set } from "date-fns";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";

import { connectRolesWithLocalStorge, getCurrentUserEmail } from "../auth";
import AwaitingVerification from "../components/AwaitingVerification";
import DeviationPromt from "../components/DeviationPromt";
import FormattedTimestamp from "../components/FormatTimeAndDate";
import ModalTile from "../components/ModalTile";
import PreCancleOrderProps from "../components/PreCancleOrder";
import { sendSMS } from "../components/contact";
import { newActivity } from "../components/firebaseCommands";
import AvvikIOrdre, { cancelOrderInFirestore } from "../components/newOrder/AvvikInOrder";
import ConfirmationView from "../components/newOrder/ConfirmationView";
import DropOffLocation from "../components/newOrder/DropOffLocation";
import { fallbackImageUrl } from "../components/newOrder/OrderModal";
import { initiateLateCheckout } from "../components/utilities/payment";
import { app, db } from "../firebase";
import animationData from "../images/FikseAnimation.json";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import { ReactComponent as DeliverIcon } from "../images/icons-fikse/deliver.svg";
import { ReactComponent as SignIcon } from "../images/icons-fikse/sign.svg";
import { Order, STATES_TAILOR_CAN_UPDATE } from "../types/Order";
import { LINKED_ORDER_STATES, OrderInfo, STOP_ORDER_STATES } from "../types/Order";
import OrderCard from "./OrderPage/OrderCardV2";
import OrderLogV2 from "./OrderPage/OrderLogV2";
import OrderTabs from "./OrderPage/OrderTabsV2";
import SwipeBottomBar from "./swipeBottomBar";

/* Oppdatert OrderInfo- og Order-typer */

interface OrderPageProps {
  database: string;
}
/*export interface OrderInfo {
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  customerComment?: string;
  imageUrl: string;
  work?: string; // Optional work field
  tailorNote?: string; // Optional tailor note field
  previousPrice?: number[]; // Optional previous price field
  count?: number; // Optional count field
  event?: Event[]; // Optional event field // Optional event field
}*/

export const brandPriceVisibility = (retailId?: string): boolean => {
  const roles = localStorage.getItem("roles") || "";
  return (
    !retailId ||
    (localStorage.getItem("retailConnections") || "").includes(retailId) ||
    roles.includes("tailor")
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center py-10">
    <Lottie options={defaultOptions} />
  </div>
);

const ErrorBanner: React.FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div
      className="relative mb-6 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
      role="alert"
    >
      <strong className="font-bold">{t("error")}</strong>
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// Remove or comment out the static orderStates array
/*export const orderStates: string[] = [
  "orderStateCreated",
  "orderStateCustomerDropOff",
  "orderStateDeliverToTailor", 
  "orderStateRepaired",
  "orderStatePickedUpFromTailor",
  "orderStateDelivered",
];*/

// alphabeticallySortOrderInfo is a blackbox
// no fucking clue how this alphabeticallySortOrderInfo works, but it does.
const alphabeticallySortOrderInfo = (orderInfo: any[]) => {
  return orderInfo.map((item) => {
    // Create a new object with sorted keys
    const alphabeticallySorted = Object.keys(item)
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = item[key];
          // Recursively sort nested objects/arrays
          if (Array.isArray(item[key])) {
            obj[key] = item[key].map((subItem: Record<string, unknown>) =>
              typeof subItem === "object"
                ? Object.keys(subItem)
                    .sort()
                    .reduce((subObj: Record<string, unknown>, subKey: string) => {
                      subObj[subKey] = subItem[subKey];
                      return subObj;
                    }, {})
                : subItem
            );
          }
          return obj;
        },
        {} as Record<string, unknown>
      );
    return alphabeticallySorted;
  });
};

const WorkwearOrderPage: React.FC<OrderPageProps> = ({ database }) => {
  const params = new URLSearchParams(window.location.search);

  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [focusedOrderItems, setFocusedOrderItems] = useState<number>(() => {
    const focusedItem = params.get("item");
    if (order?.orderInfo.length === 1) {
      return 1;
    }

    return focusedItem ? parseInt(focusedItem) - 1 : -1;
  });
  const [AvvikModal, setAvvikModal] = useState<boolean>(false);
  const [actionNeedsToBeTaken, setActionNeedsToBeTaken] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const scannedFromQRCode = Boolean(params.get("QR") !== null);

  // Add list of approved status elements
  const approvedLastElements = [
    "changesApproved",
    "cancelledByTailor",
    "accepted",
    "paid",
    "verified",
  ];

  // Get eventPredictor from the first orderInfo item
  const orderStates = order?.orderInfo[0]?.eventPredictor || [];

  //Brukes for å filterer bort unskede flyter i ordere
  const filteredOrderStates = order?.theyDeliver
    ? orderStates.filter((state) => state !== "orderStateCustomerDropOff")
    : orderStates;

  const { t } = useTranslation();
  const hasEffectRun = useRef(false);
  const lastActivityContent = order?.activity[order?.activity.length - 1].content;
  const finishedOrder: boolean =
    lastActivityContent === filteredOrderStates[filteredOrderStates.length - 1];
  const signleItemOrder: boolean = order?.orderInfo.length === 1;

  const fetchOrder = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!orderId) {
        throw new Error(t("workwearOrderPage.noOrderId"));
      }

      const orderRef = doc(db, database, orderId);
      const orderSnap = await getDoc(orderRef);

      if (!orderSnap.exists()) {
        throw new Error(t("workwearOrderPage.orderNotFound"));
      }

      const data = orderSnap.data() as Omit<Order, "id">;

      setOrder({ id: orderId, ...data });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("workwearOrderPage.unknownError"));
      }
    } finally {
      const randomDelay = Math.floor(Math.random() * (850 - 600 + 1)) + 600;
      const minLoadingTime = new Promise((resolve) => setTimeout(resolve, randomDelay));
      await Promise.all([minLoadingTime]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasEffectRun.current) return;
    hasEffectRun.current = true;

    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    //Fjern window.location.hostname !== "fikse.co" for å rulle ut til fikse.co
    /*   if (scannedFromQRCode && window.location.hostname !== "fikse.co") {
      window.location.href = `/tailorOrderView?orderId=${orderId}&item=${focusedOrderItems}`;
      return;
    }*/
    //Oppdaterer roles fortløpende ved endring i firebase
    let mounted = true;
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      if (user && mounted) {
        try {
          await connectRolesWithLocalStorge(user.email || "");
          console.log("Roles updated successfully");
        } catch (error) {
          console.error("Failed to update roles:", error);
          // Could add error handling UI here
        }
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const verifiedUid = localStorage.getItem("verifiedUid");
    const uid = localStorage.getItem("uid");

    if (verifiedUid === uid) {
      setIsVerified(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#newOrder") {
      setShowConfirmation(true);
    }

    if (window.location.hash === "#scanned" || window.location.hash === "#checkPayment") {
      setFocusedOrderItems(-1);
    }
  }, []);

  /*Conditions to check if bottom bar is visible*/
  useEffect(() => {
    const roles = localStorage.getItem("roles");
    const orderItemIndex = focusedOrderItems < 0 ? 0 : focusedOrderItems;
    const currentItem = order?.orderInfo?.[orderItemIndex];
    const lastEvent = currentItem?.event?.[currentItem.event?.length - 1];

    /* if (order?.orderInfo.length === 1) {
      setFocusedOrderItems(0);
    }
    console.log(focusedOrderItems);*/

    setShowUpdateStatus(false);
    setActionNeedsToBeTaken(false);

    if (!currentItem || !lastEvent) return;

    // Check if last event is a stop state
    if (STOP_ORDER_STATES.includes(lastEvent.status)) {
      setActionNeedsToBeTaken(true);
      return;
    }

    // Basic conditions for showing update status
    if (
      lastEvent.status === "orderStateCreated" ||
      lastEvent.status === "orderStatePickedUpFromTailor"
    ) {
      setShowUpdateStatus(true);
    }

    // Tailor-specific conditions
    if (
      roles?.includes("tailor") &&
      STATES_TAILOR_CAN_UPDATE.includes(lastEvent.status)
    ) {
      setShowUpdateStatus(true);
    }

    // Transporter conditions
    if (
      roles?.includes("transporter") &&
      (lastEvent.status === "orderStateCustomerDropOff" ||
        lastEvent.status === "orderStateRepaired")
    ) {
      setShowUpdateStatus(true);
    }

    // Courier conditions
    if (roles?.includes("courier") && lastEvent.status === "orderStateRepaired") {
      setShowUpdateStatus(true);
    }
  }, [order, focusedOrderItems]);

  const handleStatusUpdate = async (newStatus: string) => {
    if (newStatus) {
      const orderRef = doc(db, database, orderId!);
      const userEmail = getCurrentUserEmail();

      const updatedActivity = [
        ...order!.activity,
        {
          id: order!.activity.length + 1,
          content: newStatus,
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(),
          iconBackground: "bg-blue-500",
          handler: userEmail, // Add handler field
        },
      ];

      await updateDoc(orderRef, {
        currentStatus: newStatus,
        activity: updatedActivity,
      });

      fetchOrder();
    } else {
      alert("Vennligst velg en status for å oppdatere.");
    }
  };

  const handleProceed = () => {
    setShowConfirmation(false);
    window.history.replaceState(null, "", " ");
  };

  const email = localStorage.getItem("uid") || "";

  //denne her ser ut til å være en funksjon som sender en epost til kunden om at ordren er mottatt. Men ikke fullført?
  const handleSendOrderConfirmation = async () => {
    if (order) {
      try {
        const functions = getFunctions(undefined, "europe-west1");
        const sendOrderConfirmation = httpsCallable(
          functions,
          "sendOrderConfirmationEmail"
        );

        const response = await sendOrderConfirmation({
          email: order.email,
          orderInfo: order.orderInfo,
          contactInfo: order.contactInfo,
          orderId: order.id,
        });

        const data = response.data as { success: boolean }; // Type-casting response.data

        if (data.success) {
          alert("Ordrebekreftelse sendt!");
        } else {
          alert("Noe gikk galt under sending av e-post.");
        }
      } catch (error) {
        console.error("Error sending order confirmation:", error);
        alert("Noe gikk galt under sending av e-post.");
      }
    }
  };

  const selectedFocusIndex = async (index: number) => {
    await setFocusedOrderItems(index);
    // Update URL with new query parameter, preserving any existing hash
    const params = new URLSearchParams(window.location.search);
    params.set("item", (index + 1).toString());
    const hash = window.location.hash;
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}${hash}`
    );
  };

  const handleStatusUpdateClick = async () => {
    setLoading(true);
    const orderItemIndex = focusedOrderItems < 0 ? 0 : focusedOrderItems;

    try {
      // Fetch latest order data first
      const orderRef = doc(db, database, orderId!);
      const latestOrderSnap = await getDoc(orderRef);
      const latestOrder = latestOrderSnap.data() as Order;

      // Sort both orderInfo arrays before comparing
      // Vi storterer alfabetisk fordi "json" som er hentet kan ha tilfeldig rekkefølge til stringify comparison
      const currentSortedFormat = alphabeticallySortOrderInfo(order?.orderInfo || []);
      const latestSortedFormat = alphabeticallySortOrderInfo(
        latestOrder?.orderInfo || []
      );

      // Compare sorted versions
      if (JSON.stringify(currentSortedFormat) !== JSON.stringify(latestSortedFormat)) {
        console.log("Current orderInfo:", JSON.stringify(currentSortedFormat, null, 2));
        console.log("Latest orderInfo:", JSON.stringify(latestSortedFormat, null, 2));
        setShowUpdateModal(true);
        setLoading(false);
        return;
      }

      const currentItem = order?.orderInfo?.[orderItemIndex];
      if (!currentItem?.eventPredictor) return;

      const lastEvent = currentItem.event?.[currentItem.event.length - 1];
      const currentStatus = lastEvent?.status || currentItem.eventPredictor[0];
      const currentIndex = currentItem.eventPredictor.indexOf(currentStatus);
      const newStatus = currentItem.eventPredictor[currentIndex + 1];

      if (!newStatus) return;

      const updatedOrderInfo = [...(order?.orderInfo || [])];

      // Check if current status is in LINKED_ORDER_STATES
      if (LINKED_ORDER_STATES.includes(currentStatus)) {
        // Update all items that have the same current status and same next predicted state
        updatedOrderInfo.forEach((item, index) => {
          const itemLastEvent = item.event?.[item.event.length - 1];
          const itemCurrentStatus = itemLastEvent?.status || item.eventPredictor[0];

          // Get the next predicted state for this item
          const itemCurrentIndex = item.eventPredictor.indexOf(itemCurrentStatus);
          const itemNextStatus = item.eventPredictor[itemCurrentIndex + 1];

          // Only update if current status matches AND next predicted state matches
          if (itemCurrentStatus === currentStatus && itemNextStatus === newStatus) {
            updatedOrderInfo[index] = {
              ...item,
              event: [
                ...(item.event || []),
                {
                  date: new Date().toISOString(),
                  status: newStatus,
                  handler: getCurrentUserEmail(),
                },
              ],
            };
          }
        });
      } else {
        // Update only the focused item
        updatedOrderInfo[orderItemIndex] = {
          ...currentItem,
          event: [
            ...(currentItem.event || []),
            {
              date: new Date().toISOString(),
              status: newStatus,
              handler: getCurrentUserEmail(),
            },
          ],
        };
      }

      await updateDoc(orderRef, {
        orderInfo: updatedOrderInfo,
      });

      await fetchOrder();
    } catch (error) {
      console.error("Failed to update order status:", error);
      setError(t("workwearOrderPage.updateError"));
    } finally {
      setLoading(false);
    }
  };

  const handleRevertActivity = async (eventIndex: number) => {
    if (!order || !orderId) return;

    const orderItemIndex = focusedOrderItems < 0 ? 0 : focusedOrderItems;
    const currentItem = order.orderInfo[orderItemIndex];
    if (!currentItem?.eventPredictor) return;

    try {
      setLoading(true);

      // Remove the last event
      const updatedEvents = currentItem.event?.slice(0, -1) || [];
      const updatedOrderInfo = [...order.orderInfo];
      updatedOrderInfo[orderItemIndex] = {
        ...currentItem,
        event: updatedEvents,
      };

      // Update Firestore
      const orderRef = doc(db, database, orderId);
      await updateDoc(orderRef, {
        orderInfo: updatedOrderInfo,
      });

      // Refresh order data
      await fetchOrder();
    } catch (error) {
      console.error("Failed to revert event:", error);
      setError(t("workwearOrderPage.revertError"));
    } finally {
      setLoading(false);
    }
  };

  // Add useEffect to handle modal close
  useEffect(() => {
    if (!showUpdateModal) {
      fetchOrder();
    }
  }, [showUpdateModal]);

  console.log("focusedOrderItems:", focusedOrderItems);
  const collectiveChange: boolean = (() => {
    //Code that checks if all the last events statuses are the same, and if they are part of LINKED_ORDER_STATES
    if (!order?.orderInfo || order.orderInfo.length < 2) {
      return true; //Update everything when there is only one item
    }

    const lastStatuses = order.orderInfo.map((item) => {
      const lastEvent = item.event?.[item.event.length - 1];
      return lastEvent?.status || "";
    });

    // Check if all statuses are the same and not empty
    const firstStatus = lastStatuses[0];
    if (!firstStatus) return false;

    const allSameStatus = lastStatuses.every((status) => status === firstStatus);

    // Check if the status is in LINKED_ORDER_STATES
    const isLinkedStatus = LINKED_ORDER_STATES.includes(firstStatus);

    return allSameStatus && isLinkedStatus;
  })();

  return (
    <div className="mx-auto min-h-screen">
      <div
        dangerouslySetInnerHTML={
          {
            __html: `<!-- OrderPageV2 -->`,
          } /*Denne biten med kode her vil være synlig i html frontend */
        }
      />

      {/* max-w-7xl*/}
      {loading && <LoadingSpinner />}
      {error && <ErrorBanner message={error} />}
      {!loading && !error && (
        <>
          {!isVerified ? (
            <AwaitingVerification email={email} />
          ) : showConfirmation ? (
            <ConfirmationView />
          ) : (
            <>
              {/*Header */}
              <h1 className="mb-8 pl-4 text-3xl">
                {window.location.hostname === "localhost"}
                {t("workwearOrderPage.order")}: {order?.id.slice(0, 5)}
              </h1>
              {/*tabs */}
              {!scannedFromQRCode && (
                <OrderTabs
                  order={order!}
                  onChange={selectedFocusIndex}
                  activeTab={focusedOrderItems}
                />
              )}
              {!order?.paid &&
                orderId &&
                database != "workwearOrders" &&
                window.location.hash !== "#checkPayment" && (
                  <>
                    <div
                      className="mb-10 flex w-full cursor-pointer items-center justify-between border-y border-black bg-fikseRed p-4 text-left text-base"
                      onClick={() => initiateLateCheckout(orderId, database)}
                    >
                      <span>{t("orderIsNotPaid")}</span>
                      <ChevronRightIcon aria-hidden="true" />
                    </div>
                  </>
                )}
              {order?.activity[order.activity.length - 1].message && (
                <>
                  <div className="flex w-full items-center border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <SignIcon className="mr-2 h-6 w-6" />
                    <span>{t("markPackage") + " " + orderId?.slice(0, 5)}</span>
                  </div>
                  <div className="mb-10 flex w-full items-center border-b border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <DeliverIcon className="mr-2 h-6 w-6" />
                    <span>
                      {t("nextStep")}
                      {order?.theyDeliver
                        ? "Ahlsell"
                        : order?.deliveryDepartment +
                          t("nextStepTwo") +
                          order?.deliveryAddress}{" "}
                      {t("nextStepThree")}
                    </span>
                  </div>
                </>
              )}
              {actionNeedsToBeTaken && (
                <>
                  <div className="mb-10 flex w-full items-center border-b border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <SignIcon className="mr-2 h-6 w-6" />
                    <span>Venter svar fra kunde på endringer.</span>
                  </div>
                </>
              )}
              {lastActivityContent === "orderDeviation" && (
                <DeviationPromt
                  newPrice={order?.activity[order.activity.length - 1].deviationPrice}
                  tailorsNote={order?.tailorsNote}
                  onDeclinedClick={async () => {
                    setLoading(true);
                    await cancelOrderInFirestore(database, orderId!, t);
                    window.location.reload();
                  }}
                  onAcceptClick={async () => {
                    setLoading(true);
                    await newActivity(database, orderId!, "orderStateDeliverToTailor");
                    window.location.reload();
                  }}
                />
              )}
              {order &&
                (localStorage.getItem("verifiedUid") === localStorage.getItem("uid") ? (
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <OrderCard
                      order={order}
                      activity={order.activity}
                      index={order.orderInfo.length === 1 ? 0 : focusedOrderItems}
                      database={database}
                    />
                    <OrderLogV2
                      order={order}
                      activity={order.activity}
                      onRevert={handleRevertActivity}
                      selectedOrderItem={focusedOrderItems}
                    />
                    <div className="p-8" />
                  </div>
                ) : (
                  <AwaitingVerification email={email} />
                ))}{" "}
              {/*BottomBar*/}
              {showUpdateStatus &&
                !actionNeedsToBeTaken &&
                (collectiveChange || focusedOrderItems !== -1) && (
                  <>
                    <SwipeBottomBar
                      onSwipe={handleStatusUpdateClick}
                      text={
                        (() => {
                          const orderItemIndex =
                            focusedOrderItems < 0 ? 0 : focusedOrderItems;
                          const currentItem = order?.orderInfo?.[orderItemIndex];

                          const nextEvent =
                            currentItem?.eventPredictor?.[
                              currentItem?.event?.length ?? 0 - 1
                            ];

                          if (!currentItem || !nextEvent) {
                            return t("workwearOrderPage.orderStateDeliveredBar");
                          }

                          return t(`swipeBottomBar.${nextEvent}`);
                        })() || ""
                      }
                    />
                    {localStorage.getItem("roles")?.includes("operator") && (
                      <>
                        KUN SYNELIG FOR FIKSE INTERNE. Dette er en midlertidig tekst
                        placeholder for kanseleringsknappens om enda ikke fikset for ny
                        ordrestuktur{" "}
                        {/*<PreCancleOrderProps Order={order!} Database={database} />*/}
                      </>
                    )}
                  </>
                )}
            </>
          )}
        </>
      )}
      {showUpdateModal && (
        <ModalTile
          open={showUpdateModal}
          onClose={() => {
            setShowUpdateModal(false);
            window.location.reload();
          }}
          title={t("orderUpdate.refresh")}
        >
          <div className="p-6">
            <p className="mb-4 text-lg">{t("orderUpdate.serverChange")}</p>
            <button
              onClick={() => window.location.reload()}
              className="w-full rounded bg-fikseBlue px-4 py-2 text-white hover:bg-blue-600"
            >
              {t("orderUpdate.refresh")}
            </button>
          </div>
        </ModalTile>
      )}
    </div>
  );
};

export default WorkwearOrderPage;
