import React from "react";

import OrderPageV2 from "../pages/OrderPageV2";
import { TailorOrderView } from "../pages/TailorOrderView";

export default function ViewOrderRouter() {
  const params = new URLSearchParams(window.location.search);

  const scannedFromQRCode = Boolean(params.get("QR") !== null);
  const itemId = Number(params.get("item"));
  const isOnMobile = window.innerWidth < 768;

  const isTailor = localStorage.getItem("roles")?.includes("tailor");

  const deBuggingVariableToNotShowOnProd = window.location.hostname !== "fikse.co";
  if (
    //scannedFromQRCode && remove this command for QR sacanning requirement.
    isOnMobile &&
    itemId >= 0 &&
    isTailor &&
    deBuggingVariableToNotShowOnProd
  ) {
    return <TailorOrderView />;
  }

  return <OrderPageV2 database={"orders"} />;
}
