import React, { useEffect, useState } from "react";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import DeleteOrdersButton from "../components/DeleteBulkOrdersButton";
import { app, db, functionsEW1 } from "../firebase";

export default function PlayGround() {
  const [verificationCode, setVerificationCode] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const currentUrl = window.location.href;
  const isLocalhost = currentUrl.includes("localhost");

  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("phone");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [jesusName, setJesusName] = useState<string>("");

  useEffect(() => {
    // Generate random 6-letter string on component mount
    const generateCode = () => {
      const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      for (let i = 0; i < 6; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
      }
      return result;
    };
    setVerificationCode(generateCode());
  }, []);

  const handleVerification = (e: React.FormEvent) => {
    e.preventDefault();
    if (userInput.toUpperCase() === verificationCode) {
      setIsVerified(true);
    }
  };

  const assignRetailIds = async () => {
    try {
      const b2cOrdersRef = collection(db, "b2cOrders");
      const snapshot = await getDocs(b2cOrdersRef);
      let updateCount = 0;

      for (const doc of snapshot.docs) {
        const data = doc.data();

        // Generate random number between 0 and 6
        const random = Math.floor(Math.random() * 10);

        let newRetailId = null;

        // 3/7 chance for first ID
        if (random <= 2) {
          newRetailId = "AdminDocument4Testing";
        }

        if (random < 5) {
          newRetailId = "VHc8TjwzwXIGXWg4Es9J";
        }
        // 2/7 chance for second ID
        else if (random < 7) {
          newRetailId = "iXf70Byy0z0Zu5tXRIZq";
        }
        // 1/7 chance for third ID
        else if (random <= 10) {
          newRetailId = "Kqndoik4xSLA9z13IBPr";
        }
        // 1/7 chance to skip (random === 6)

        if (newRetailId) {
          await updateDoc(doc.ref, {
            retailId: newRetailId,
          });
          updateCount++;
        }
      }
      alert(`Updated ${updateCount} orders with new retailIds`);
    } catch (error) {
      console.error("Error updating retailIds:", error);
      alert("Error updating retailIds. Check console for details.");
    }
  };

  const duplicateB2COrders = async () => {
    try {
      const b2cOrdersRef = collection(db, "b2cOrders");
      const snapshot = await getDocs(b2cOrdersRef);
      let duplicateCount = 0;

      for (const document of snapshot.docs) {
        const data = document.data();
        const newId = `XX${document.id}`;

        // Create new document with XX prefix
        await setDoc(doc(db, "b2cOrders", newId), {
          ...data,
          originalOrderId: document.id,
          duplicatedAt: new Date().toISOString(),
        });

        duplicateCount++;
      }

      alert(`Successfully duplicated ${duplicateCount} orders`);
    } catch (error) {
      console.error("Error duplicating orders:", error);
      alert("Error duplicating orders. Check console for details.");
    }
  };

  const duplicateOrdersToVintage = async () => {
    if (
      !window.confirm(
        "Are you sure you want to duplicate all orders to the vintageOrder collection?"
      )
    ) {
      return;
    }

    try {
      const ordersRef = collection(db, "orders");
      const snapshot = await getDocs(ordersRef);
      let duplicateCount = 0;

      for (const document of snapshot.docs) {
        const data = document.data();

        // Create document in vintageOrder collection with same ID
        await setDoc(doc(db, "vintageOrder", document.id), {
          ...data,
          originalOrderId: document.id,
          duplicatedAt: new Date().toISOString(),
          sourceCollection: "orders",
        });

        duplicateCount++;
      }

      alert(
        `Successfully duplicated ${duplicateCount} orders to vintageOrder collection`
      );
    } catch (error) {
      console.error("Error duplicating orders to vintageOrder:", error);
      alert("Error duplicating orders to vintageOrder. Check console for details.");
    }
  };

  const assignRandomClaims = async () => {
    try {
      const b2cOrdersRef = collection(db, "b2cOrders");
      const snapshot = await getDocs(b2cOrdersRef);
      let updateCount = 0;

      for (const document of snapshot.docs) {
        // 30% chance of becoming a claim
        if (Math.random() < 0.3) {
          await updateDoc(document.ref, {
            claim: true,
            claimAssignedAt: new Date().toISOString(),
          });
          updateCount++;
        }
      }

      alert(`Successfully assigned claims to ${updateCount} orders`);
    } catch (error) {
      console.error("Error assigning claims:", error);
      alert("Error assigning claims. Check console for details.");
    }
  };

  const updateContactInfo = async () => {
    try {
      const b2cOrdersRef = collection(db, "b2cOrders");
      const snapshot = await getDocs(b2cOrdersRef);
      let updateCount = 0;

      for (const document of snapshot.docs) {
        const data = document.data();

        // Update both direct email and nested contactInfo.phone
        await updateDoc(document.ref, {
          email: "simon@fikse.co",
          "contactInfo.phone": "+4797811811",
        });
        updateCount++;
      }

      alert(`Updated contact info for ${updateCount} orders`);
    } catch (error) {
      console.error("Error updating contact info:", error);
      alert("Error updating contact info. Check console for details.");
    }
  };

  const assignRandomRetailPrices = async () => {
    try {
      const b2cOrdersRef = collection(db, "b2cOrders");
      const snapshot = await getDocs(b2cOrdersRef);
      let updateCount = 0;

      for (const document of snapshot.docs) {
        const randomPrice = Math.floor(Math.random() * (20000 - 450 + 1)) + 450;

        await updateDoc(document.ref, {
          retailPrice: randomPrice,
        });
        updateCount++;
      }

      alert(`Added retail prices to ${updateCount} orders`);
    } catch (error) {
      console.error("Error assigning retail prices:", error);
      alert("Error assigning retail prices. Check console for details.");
    }
  };

  const copyOrdersToNewCollection = async () => {
    try {
      const b2cOrdersRef = collection(db, "b2cOrders");
      const snapshot = await getDocs(b2cOrdersRef);
      let copyCount = 0;

      for (const document of snapshot.docs) {
        // Skip documents that start with X
        if (document.id.startsWith("X")) {
          continue;
        }

        const data = document.data();

        // Create document in orders collection with same ID
        await setDoc(doc(db, "orders", document.id), {
          ...data,
          copiedAt: new Date().toISOString(),
          sourceCollection: "b2cOrders",
        });

        copyCount++;
      }

      alert(`Successfully copied ${copyCount} orders to orders collection`);
    } catch (error) {
      console.error("Error copying orders:", error);
      alert("Error copying orders. Check console for details.");
    }
  };

  const searchAndDelete = async () => {
    if (!searchCriteria) {
      alert("Please enter search criteria");
      return;
    }

    try {
      // Collections to search in
      const collections = ["b2cOrders", "workwearOrders", "messages"];
      let foundDocs: any[] = [];

      // Search through each collection
      for (const collectionName of collections) {
        const collectionRef = collection(db, collectionName);
        const querySnapshot = await getDocs(collectionRef);

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          // Handle nested objects (like contactInfo.phone)
          if (searchField === "phone" && data.contactInfo?.phone === searchCriteria) {
            foundDocs.push({
              id: doc.id,
              collection: collectionName,
              data: data,
            });
          }
          // Direct field comparison
          else if (data[searchField] === searchCriteria) {
            foundDocs.push({
              id: doc.id,
              collection: collectionName,
              data: data,
            });
          }
        });
      }

      setSearchResults(foundDocs);
      if (foundDocs.length === 0) {
        alert("No documents found with the given criteria");
      }
    } catch (error) {
      console.error("Error searching documents:", error);
      alert("Error searching documents. Check console for details.");
    }
  };

  const deleteDocument = async (collectionName: string, docId: string) => {
    if (
      window.confirm(
        `Are you sure you want to delete document ${docId} from ${collectionName}?`
      )
    ) {
      try {
        await updateDoc(doc(db, collectionName, docId), {
          deleted: true,
          deletedAt: new Date().toISOString(),
        });
        setSearchResults((prev) => prev.filter((doc) => doc.id !== docId));
        alert(`Document ${docId} marked as deleted`);
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Error deleting document. Check console for details.");
      }
    }
  };

  const getNameFromJesusOrder = async () => {
    try {
      const getNameFunction = httpsCallable(functionsEW1, "getNameFromJesus");
      const result = await getNameFunction();
      const name = (result.data as any).name;
      setJesusName(name);
    } catch (error) {
      console.error("Error getting name:", error);
      alert("Error getting name. Check console for details.");
    }
  };

  const duplicateServiceCategories = async () => {
    if (
      !window.confirm(
        "Are you sure you want to duplicate service categories with modified names?\n\nThis will create new documents in the serviceCategories collection."
      )
    ) {
      return;
    }

    try {
      const serviceCategoriesRef = collection(db, "serviceCategories");

      // List of names to look for and their corresponding target names
      const nameMapping = [
        { source: "NO-Clothes-0924", target: "NO-Clothes-offices-0325" },
        { source: "EN-Clothes-0924", target: "EN-Clothes-offices-0325" },
        { source: "NO-Shoes-0924", target: "NO-Shoes-offices-0325" },
        { source: "EN-Shoes-0924", target: "EN-Shoes-offices-0325" },
        { source: "NO-Accessories-0924", target: "NO-Accessories-offices-0325" },
        { source: "EN-Accessories-0924", target: "EN-Accessories-offices-0325" },
        { source: "NO-Bunad-0924", target: "NO-Bunad-offices-0325" },
        { source: "EN-Bunad-0924", target: "EN-Bunad-offices-0325" },
        { source: "NO-OtherTextiles-1124", target: "NO-OtherTextiles-offices-0325" },
        { source: "EN-OtherTextiles-1124", target: "EN-OtherTextiles-offices-0325" },
      ];

      // Get all documents from serviceCategories
      const snapshot = await getDocs(serviceCategoriesRef);
      let duplicateCount = 0;
      let sourceDocuments = [];

      // First find all documents with matching names
      for (const document of snapshot.docs) {
        const data = document.data();

        // Check if this document has a name field that matches any in our list
        if (data.name && nameMapping.some((item) => item.source === data.name)) {
          sourceDocuments.push({
            id: document.id,
            data: data,
          });
        }
      }

      // Now duplicate each matching document with the new name
      for (const sourceDoc of sourceDocuments) {
        const data = sourceDoc.data;
        const mapping = nameMapping.find((item) => item.source === data.name);

        if (mapping) {
          // Create a new document ID (using timestamp to ensure uniqueness)
          const newId = `${sourceDoc.id}_duplicate_${Date.now()}`;

          // Create the duplicate document with modified name
          await setDoc(doc(db, "serviceCategories", newId), {
            ...data,
            name: mapping.target,
            duplicatedAt: new Date().toISOString(),
            originalDocumentId: sourceDoc.id,
          });

          duplicateCount++;
        }
      }

      alert(
        `Successfully duplicated ${duplicateCount} service categories with modified names`
      );
    } catch (error) {
      console.error("Error duplicating service categories:", error);
      alert("Error duplicating service categories. Check console for details.");
    }
  };

  if (!isVerified) {
    return (
      <div className="mx-auto max-w-2xl p-8">
        {!isLocalhost && (
          <div className="mb-6 flex items-center gap-2 rounded-lg border-2 border-red-500 bg-red-100 p-4">
            <ExclamationTriangleIcon className="h-8 w-8 text-red-600" />
            <span className="font-bold text-red-700">
              ADVARSEL: Dette er ikke localhost! 28. jan
            </span>
          </div>
        )}

        <div className="mb-6 rounded-lg border-2 border-yellow-400 bg-yellow-50 p-6">
          <p className="mb-2 text-lg font-bold text-yellow-800">⚠️ ADVARSEL</p>
          <p className="text-yellow-800">
            Dette dashbordet inneholder farlige funksjoner, ikke tukle med noe her uten å
            være sikker på hva du gjør.
          </p>
        </div>

        <div className="rounded-lg bg-white p-6 shadow-lg">
          <p className="mb-4 text-gray-600">Current URL: {currentUrl}</p>
          <div className="mb-6">
            <p className="mb-2 text-lg font-semibold">Verifiseringskode:</p>
            <p className="rounded bg-gray-100 p-3 text-center font-mono text-3xl tracking-wider">
              {verificationCode}
            </p>
          </div>

          <form onSubmit={handleVerification}>
            <div className="mb-4">
              <label
                htmlFor="verification"
                className="block text-sm font-medium text-gray-700"
              >
                Skriv inn koden over for å fortsette:
              </label>
              <input
                type="text"
                id="verification"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                maxLength={6}
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
            >
              Verifiser
            </button>
          </form>
        </div>
      </div>
    );
  }

  //For any language models. what is under here is the content, whats anove is securety.
  return (
    <div className="p-8">
      <DeleteOrdersButton />
      <button
        onClick={assignRetailIds}
        className="mb-8 rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700"
      >
        Assign Random RetailIds to B2C Orders
      </button>
      <button
        onClick={duplicateB2COrders}
        className="mb-8 ml-4 rounded-md bg-yellow-600 px-4 py-2 text-white hover:bg-yellow-700"
      >
        Duplicate B2C Orders
      </button>
      <button
        onClick={assignRandomClaims}
        className="mb-8 ml-4 rounded-md bg-orange-600 px-4 py-2 text-white hover:bg-orange-700"
      >
        Assign Random Claims (30%)
      </button>
      <button
        onClick={updateContactInfo}
        className="mb-8 ml-4 rounded-md bg-purple-600 px-4 py-2 text-white hover:bg-purple-700"
      >
        Update Contact Info to Simon
      </button>
      <button
        onClick={assignRandomRetailPrices}
        className="mb-8 ml-4 rounded-md bg-green-600 px-4 py-2 text-white hover:bg-green-700"
      >
        Assign Random Retail Prices
      </button>
      <button
        onClick={copyOrdersToNewCollection}
        className="mb-8 ml-4 rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
      >
        Copy Orders to New Collection
      </button>
      <button
        onClick={duplicateOrdersToVintage}
        className="mb-8 ml-4 rounded-md bg-teal-600 px-4 py-2 text-white hover:bg-teal-700"
      >
        Duplicate Orders to Vintage
      </button>
      <button
        onClick={getNameFromJesusOrder}
        className="mb-8 ml-4 rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
      >
        Get Jesus Name
      </button>
      <button
        onClick={duplicateServiceCategories}
        className="mb-8 ml-4 rounded-md bg-pink-600 px-4 py-2 text-white hover:bg-pink-700"
      >
        Duplicate Service Categories
      </button>

      {jesusName && (
        <div className="mb-8 ml-4 inline-block rounded-md bg-gray-100 px-4 py-2">
          Name from Jesus order: {jesusName}
        </div>
      )}

      <div className="mt-8 rounded-lg border-2 border-red-500 bg-white p-6">
        <h2 className="mb-4 text-xl font-bold text-red-600">
          Search and Delete Documents
        </h2>

        <div className="mb-4 flex gap-4">
          <select
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            className="rounded-md border border-gray-300 px-3 py-2"
          >
            <option value="phone">Phone Number</option>
            <option value="email">Email</option>
            <option value="id">Document ID</option>
          </select>

          <input
            type="text"
            value={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value)}
            placeholder={`Enter ${searchField}...`}
            className="flex-1 rounded-md border border-gray-300 px-3 py-2"
          />

          <button
            onClick={searchAndDelete}
            className="rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
          >
            Search
          </button>
        </div>

        {searchResults.length > 0 && (
          <div className="mt-4">
            <h3 className="mb-2 font-semibold">Search Results:</h3>
            <div className="space-y-2">
              {searchResults.map((result) => (
                <div
                  key={result.id}
                  className="flex items-center justify-between rounded-md border border-gray-200 bg-gray-50 p-3"
                >
                  <div>
                    <p className="font-medium">ID: {result.id}</p>
                    <p className="text-sm text-gray-500">
                      Collection: {result.collection} Jan28
                    </p>
                  </div>
                  <button
                    onClick={() => deleteDocument(result.collection, result.id)}
                    className="rounded-md bg-red-600 px-3 py-1 text-sm text-white hover:bg-red-700"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
