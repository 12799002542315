import React, { useEffect, useState } from "react";

import { Tailor } from "@type/tailor";
import {
  GeoPoint,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";

import { suportedLanguages } from "../fikse.config";
import { db } from "../firebase";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordionV2";
import ModalTile from "./ModalTile";
import PhoneNumberInput from "./PhoneNumberInput";
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import LoadingSpinner from "./fikseLoadingLetter";

interface SignUpComercialProps {
  open: boolean;
  onClose: () => void;
}

//Hvis noe skal endres i denne listen må også no/en.json oppdateres.
const otherBusinesses = [
  "leatherworkers",
  "special-occasions-specialist",
  "bunad-specialist",
  "bridal-wear-specialists",
  "sportswear-repair-services",
  "goldsmiths",
  "watch-repair-specialists",
  "upholstery-specialists",
  "furniture-restoration-services",
  "antique-restoration-specialists",
  "woodworking-carpentry-workshops",
  "smartphone-tablet-repair-shops",
  "laptop-pc-repair-services",
  "appliance-repair-technicians",
  "audio-visual-equipment-technicians",
  "bicycle-ebike-repair-shops",
  "motorcycle-mechanics",
  "boat-marine-equipment-repairers",
  "electric-scooter-repair-specialists",
  "locksmiths",
  "home-appliance-repairers",
  "maintenance-service-providers",
  "glass-repair-replacement-services",
  "woodworking-carpentry",
  "ceramists-pottery-repairers",
  "framing-art-restoration-specialists",
  "instrument-repair-specialists",
  "custom-craft-artists",
  "designer-bag-leather-goods-specialists",
  "luxury-watchmakers",
  "premium-garment-care-services",
  "camera-photography-equipment-repair-services",
  "industrial-sewing-equipment-repairers",
  "commercial-kitchen-equipment-repair-technicians",
];

const SignUpComercial = ({ open, onClose }: SignUpComercialProps) => {
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [showOtherBusiness, setShowOtherBusiness] = useState(false);

  const [filterText, setFilterText] = useState("");
  // Track the last time geocoding was called
  const [lastGeocodingTime, setLastGeocodingTime] = useState<number | null>(null);

  //This is the state for the input fields
  const [selectedBusinessOption, setSelectedBusinessOption] = useState<string | null>(
    null
  );

  const [orgNumber, setOrgNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [languageSelected, setLanguageSelected] = useState<string | null>(null);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [firebaseCoordinates, setFirebaseCoordinates] = useState<GeoPoint | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  //This is the function that checks if the user can proceed

  useEffect(() => {
    if (selectedBusinessOption) {
      setShowStartScreen(false);
    }
  }, [selectedBusinessOption]);

  useEffect(() => {
    if (orgNumber && orgNumber.length === 9) {
      fetchBusinessInfo(orgNumber);
    }
  }, [orgNumber]);

  const masterCloser = () => {
    // Reset states when closing the form
    setSignUpModalOpen(false);
    setSignInModalOpen(false);
    setLastGeocodingTime(null);
    onClose();
  };

  // Clean up on unmount
  useEffect(() => {
    return () => {
      // Reset the last geocoding time when the component unmounts
      setLastGeocodingTime(null);
    };
  }, []);

  // Add separate validation effects for each field
  useEffect(() => {
    if (orgNumber.length === 9) {
      const timeout = setTimeout(() => {
        checkIfOrgNumberExists();
      }, 500); // Debounce
      return () => clearTimeout(timeout);
    }
  }, [orgNumber]);

  const filteredBusinesses = otherBusinesses.filter((business) =>
    t(`signUpComercial.${business}`).toLowerCase().includes(filterText.toLowerCase())
  );

  // Utility function for geocoding that respects rate limits
  const geocodeWithRateLimit = async (
    addressString: string
  ): Promise<{ latitude: number; longitude: number } | null> => {
    try {
      const now = Date.now();

      // Only add a delay if this isn't the first request and less than 1 second has passed
      if (lastGeocodingTime !== null) {
        const timeSinceLastRequest = now - lastGeocodingTime;
        if (timeSinceLastRequest < 1000) {
          // Wait only the remaining time needed to reach 1 second
          const delayTime = 1000 - timeSinceLastRequest;
          console.log(`Waiting ${delayTime}ms before making geocoding request`);
          await new Promise((resolve) => setTimeout(resolve, delayTime));
        }
      }

      // Update the last geocoding time
      setLastGeocodingTime(Date.now());

      // Encode the address for URL
      const encodedAddress = encodeURIComponent(addressString);
      const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json&limit=1`;

      const response = await fetch(apiUrl, {
        headers: {
          // Add a User-Agent as per Nominatim usage policy
          "User-Agent": "FiksePortal/1.0",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.length > 0) {
          return {
            latitude: parseFloat(data[0].lat),
            longitude: parseFloat(data[0].lon),
          };
        }
      }
      return null;
    } catch (error) {
      console.error("Error during geocoding:", error);
      return null;
    }
  };

  function checkInputFields() {
    if (orgNumber.length !== 9) {
      console.log("Invalid org number" + orgNumber.length);
      setError(t("signUpComercial.invalidOrgNumber"));
      return false;
    }
    if (businessName.length === 0) {
      setError(t("signUpComercial.invalidBusinessName"));
      return false;
    }
    if (fullName.length === 0) {
      setError(t("signUpComercial.invalidFullName"));
      return false;
    }
    if (phoneNumber.length < 8) {
      setError(t("signUpComercial.invalidPhoneNumber"));
      return false;
    }
    if (!email.includes("@") || !email.includes(".")) {
      setError(t("signUpComercial.invalidEmail"));
      return false;
    }

    return true;
  }

  function fetchBusinessInfo(orgNr: string) {
    if (orgNr.length === 9) {
      fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNr}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Organization number not found");
          }
          return response.json();
        })
        .then((data) => {
          //For generating the business name
          const formatedBusinessName = data.navn
            .split(" ")
            .map((word: string) => {
              if (word.endsWith("AS")) {
                return word.slice(0, -2) + "AS";
              }
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(" ");
          //save the business name
          setBusinessName(formatedBusinessName);

          //for generating the address
          let address = "";

          // Try to get business address first
          if (data.forretningsadresse && data.forretningsadresse.adresse) {
            const addrLines = data.forretningsadresse.adresse.join(", ");
            const postnr = data.forretningsadresse.postnummer || "";
            const poststed = data.forretningsadresse.poststed || "";

            address = `${addrLines}, ${postnr} ${poststed.charAt(0).toUpperCase() + poststed.slice(1).toLowerCase()}`;
          }
          // Fall back to postal address if business address doesn't exist
          else if (data.postadresse && data.postadresse.adresse) {
            const addrLines = data.postadresse.adresse.join(", ");
            const postnr = data.postadresse.postnummer || "";
            const poststed = data.postadresse.poststed || "";

            address = `${addrLines}, ${postnr} ${poststed.charAt(0).toUpperCase() + poststed.slice(1).toLowerCase()}`;
          }
          //save the address
          setAddress(address || "");

          console.log("Postadresse1:", data.forretningsadresse);
          console.log("Postadresse:", data.postadresse);

          //get the coordinates
          if (address && address.trim().length > 0) {
            console.log("Attempting to geocode address:", address);

            // Use the utility function for geocoding
            geocodeWithRateLimit(address).then((result) => {
              if (result) {
                // Set the coordinates
                setCoordinates(result);

                // Create a GeoPoint for Firebase
                const geoPoint = new GeoPoint(result.latitude, result.longitude);
                setFirebaseCoordinates(geoPoint);

                console.log("Coordinates set:", result);
              } else {
                console.error("No results found for address:", address);
              }
            });
          }

          // Du kan lagre adresseinfo i state-variabler om nødvendig
          // For eksempel:
          // setBusinessAddress(data.forretningsadresse.adresse.join(", "));
        })
        .catch((error) => {
          console.error("Error fetching business info:", error);
        });
    }
  }

  const checkIfOrgNumberExists = async () => {
    const q = query(collection(db, "tailors"), where("orgNumber", "==", orgNumber));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      setError(orgNumber + " " + t("signUpComercial.tailorAlreadyExists"));
    } else if (error.includes(t("signUpComercial.tailorAlreadyExists"))) {
      // Clear only the "tailor already exists" error if it's fixed
      setError("");
    }
  };

  const uploadTailorToFirebase = async () => {
    const idGenerator = () => {
      const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      for (let i = 0; i < 20; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    };

    const tailorForUpload: Tailor = {
      id: idGenerator(),
      name: businessName,
      orgNumber: orgNumber,
      address: address,
      ...(coordinates && { coordinates }),
      contactPerson: {
        name: fullName,
        phone: phoneNumber,
        email: email,
      },
      cut: {
        agreeBefore: false,
        cleaning: 0.15,
        repairs: 0.15,
      },
      roles: ["tailor", "transporter"], //Sjekk typedefinisjonsfil for tailor for å se hvilke roller som er tilgjengelig
    };

    const tailorRef = doc(collection(db, "tailors"), tailorForUpload.id);
    try {
      const docSnap = await getDoc(tailorRef);

      if (docSnap.exists()) {
        // Document already exists
        console.log("Tailor already exists");
        setError(orgNumber + " " + t("signUpComercial.tailorAlreadyExists"));
      } else {
        // Document does not exist
        await setDoc(tailorRef, tailorForUpload);
        console.log("Tailor uploaded to Firebase");
      }
    } catch (error) {
      console.error("Error checking/creating document:", error);
      setError(t("signUpComercial.errorCreatingAccount"));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <ModalTile
        title={t("signUpComercial.creatingAccount")}
        open={true}
        onClose={masterCloser}
      >
        <LoadingSpinner />
      </ModalTile>
    );
  }

  return (
    <>
      {/*Code below is what allows a user to select selectedBusinessOption*/}
      {showStartScreen && (
        <>
          {/*This is the first popup*/}
          {!selectedBusinessOption && (
            <ModalTile
              title={t("signUpComercial.createAccount")}
              open={open}
              onClose={masterCloser}
            >
              <p className="fiksetekst2 border-t border-black pb-12">
                {t("signUpComercial.tailoredNeeds")}
              </p>
              <button
                className="fikseButton"
                onClick={() => setSelectedBusinessOption("tailor-services")}
              >
                {t("signUpComercial.tailorServices")}
              </button>
              <button
                className="fikseButton"
                onClick={() => setSelectedBusinessOption("cobbler")}
              >
                {t("signUpComercial.cobbler")}
              </button>
              <button
                className="fikseButton"
                onClick={() => setSelectedBusinessOption("dry-cleaner")}
              >
                {t("signUpComercial.dryCleaner")}
              </button>
              <button className="fikseButton" onClick={() => setShowOtherBusiness(true)}>
                {t("signUpComercial.other")}
              </button>
            </ModalTile>
          )}
          {/*This is the popup that comes when other is clicked*/}
          {showOtherBusiness && (
            <ModalTile
              title={t("signUpComercial.otherBusinessTitle")}
              open={showOtherBusiness}
              onClose={masterCloser}
            >
              <input
                type="text"
                className="fikseButton bg-fiksdis border-t border-black"
                placeholder={t("signUpComercial.filterBusinesses")}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />

              <div className="grid max-h-80 grid-cols-1 overflow-y-auto">
                {filteredBusinesses.length > 0 ? (
                  filteredBusinesses.map((business) => (
                    <button
                      key={business}
                      className="fikseButton"
                      onClick={() => {
                        setSelectedBusinessOption(business);
                        setShowOtherBusiness(false);
                      }}
                    >
                      {t(`signUpComercial.${business}`)}
                    </button>
                  ))
                ) : (
                  <p className="py-2 text-center">
                    {t("signUpComercial.noBusinessesFound")}
                  </p>
                )}
              </div>
            </ModalTile>
          )}
        </>
      )}

      {/*This is the popup that comes when a business is selected*/}
      {selectedBusinessOption && !signUpModalOpen && (
        <ModalTile
          title={t("signUpComercial.createAccount")}
          open={true}
          onClose={masterCloser}
        >
          <p className="fiksetekst1 pb-12">{t("signUpComercial.businessDetails")}</p>

          <input
            type="text"
            className="fikseButton bg-fikseDisabled"
            placeholder={t("signUpComercial.orgNumber")}
            value={orgNumber}
            onChange={(e) => setOrgNumber(e.target.value.replace(/\s/g, ""))}
          />

          <input
            type="text"
            className="fikseButton bg-fikseDisabled"
            placeholder={t("signUpComercial.businessName")}
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />

          {address && (
            <input
              type="text"
              className="fikseButton bg-fikseDisabled"
              placeholder={t("signUpComercial.address")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          )}
          <input
            type="text"
            className="fikseButton border-b border-none bg-fikseDisabled"
            placeholder={t("signUpComercial.fullName")}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />

          <div className="border-b border-black">
            <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} />
          </div>

          <input
            type="text"
            className="fikseButton bg-fikseDisabled"
            placeholder={t("signUpComercial.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {/*This is the dropdown for language*/}
          <div className="relative">
            <select
              value={languageSelected || ""}
              className="fikseBox w-full appearance-none pr-10"
              defaultValue=""
              onChange={(e) => {
                const value = e.target.value;
                setLanguageSelected(value);
              }}
            >
              <option value="" hidden>
                {t("signUpComercial.language")}
              </option>
              {suportedLanguages.map((language, index) => (
                <option className="p-4" key={index} value={language}>
                  {language}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ChevronRightIcon className="m-3 mt-6 h-6 w-6 rotate-90 transform" />
            </div>
          </div>

          <div className="flex w-full flex-col sm:flex-row">
            <button
              className={`fikseBox flex ${error && "sm:w-1/3"}`}
              onClick={() => {
                if (error !== "") {
                  setError("");
                }
                setShowStartScreen(true);
                setSelectedBusinessOption(null);
              }}
            >
              {t("signUpComercial.back")}
            </button>

            <button
              className={`fikseBox flex border-black ${
                !error ? "bg-fikseGreen" : "bg-fikseRed"
              } sm:border-l`}
              onClick={async () => {
                setLoading(true);
                if (checkInputFields()) {
                  await checkIfOrgNumberExists();
                  if (!error) {
                    await uploadTailorToFirebase();
                    setSignUpModalOpen(true);
                    setSelectedBusinessOption(null); //fjerner modalen for å skrive bedriftsinfo
                  }
                }
                setLoading(false);
              }}
            >
              {error ? error : t("signUpComercial.next")}
            </button>
          </div>
        </ModalTile>
      )}
      <SignUpModal
        open={signUpModalOpen}
        onClose={() => masterCloser()}
        showLogin={() => {
          setSignUpModalOpen(false);
          setSignInModalOpen(true);
        }}
        forcedEmail={email}
      />
      <SignInModal open={signInModalOpen} onClose={() => masterCloser()} />
    </>
  );
};

export default SignUpComercial;
