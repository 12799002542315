import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { set } from "date-fns";
import { on } from "events";
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useHref } from "react-router-dom";

import {
  checkIfUserHasPassword,
  createUserWithVerification,
  sendNewVerificationEmail,
  sendPasswordResetEmailHandler,
  signInWithEmailPassword,
  signInWithGoogle,
  signInWithMicrosoft,
} from "../auth";
import { app, auth } from "../firebase";
import AwaitingVerification from "./AwaitingVerification";
import ModalTile from "./ModalTile";

interface SignInWorkaroundProps {
  open: boolean;
  onClose: () => void;
}

export default function SignInWorkaround({ open, onClose }: SignInWorkaroundProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerifcation, setPasswordVerification] = useState("");
  const [department, setDepartment] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [step, setStep] = useState("emailInput");
  const [showPasswordField, setShowPasswordField] = useState(false); // State to manage showing password field
  const [emailLinkLoginModalOpen, setEmailLinkLoginModalOpen] = useState(false);
  const [emailPasswordModalOpen, setEmailPasswordModalOpen] = useState(false);
  const [emailAuthStatus, setEmailAuthStatus] = useState<{
    hasPassword: boolean;
    isVerified: boolean;
  } | null>(null);
  const [loggInWithPassrodModal, setLoggInWithPassrodModal] = useState(false);
  const [accountNeedVerification, setAccountNeedVerification] = useState(false);
  const [proceedWithEmailLoggin, setProceedWithEmailLoggin] = useState(false);
  const [showMicrosoftVerificationModal, setShowMicrosoftVerificationModal] =
    useState(false);

  const [wrongPassword, setWrongPassword] = useState(false);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (uid) {
      setFirstName(null);
      setLastName(null);
      setDepartment(null);

      if (uid.endsWith("@ahlsell.no")) {
        window.location.href = "/ahlsell";
      }
      if (uid.endsWith("@fikse.co")) {
        // window.location.href = '/admin';
      }
    }
  }, [email]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.toLocaleLowerCase());
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordVerificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordVerification(event.target.value);
  };

  // Configuration for email sign-in links

  const logginWithLink2 = () => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.origin,
      // This must be true for email link sign-in
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };
  const logginWithLink = async () => {
    //Sjekk ut doks og mobilutvidelse https://firebase.google.com/docs/auth/web/email-link-auth
    const functions = getFunctions(app, "europe-west1");
    //onClose();

    const emailToForward = (): string => {
      if (window.location.href.includes("localhost")) {
        return "https://oslo.kommune.fikse.co/";
      } else {
        return window.location.href;
      }
    };

    const sendEmailFunction = httpsCallable(functions, "sendSignInEmail");
    console.log("emailToForward", emailToForward());
    window.localStorage.setItem("emailForSignIn", email);
    setEmailLinkLoginModalOpen(true);

    try {
      const result = await sendEmailFunction({
        email,
        urlForward: window.location.href, //"https://fiksefirstapp.web.app/", //emailToForward(),
      });
    } catch (error) {
      console.error("Error sending sign-in email:", error);
      // Handle errors (e.g., show a notification to the user)
    }
  };

  const loggAndSignInWithEmailandPassword = async () => {
    const result = await signInWithEmailPassword(email, password);
    if (result.success) {
      window.location.reload();
    } else {
      setWrongPassword(true);
    }
  };

  //Todo, this function can just be sendt with a paramter inside and defragmented into another file for cleaning up
  //EDIT DENNE MÅ I EGEN FUNGSJON FOR Å HÅNDTERE setWrongPassword og wrongPassword Isolert
  const renderPasswordSection = () => {
    if (!emailAuthStatus?.hasPassword) {
      //setEmailPasswordModalOpen(true);
      return (
        <div className="border">
          <div className="flex max-w-lg flex-col">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <div className="flex w-full flex-col gap-0">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`border-0 border-b border-black p-4 sm:text-sm sm:leading-6 ${password.length < 6 && password.length > 0 ? "bg-fikseRed" : ""}`}
                placeholder="Passord"
                value={password}
                onChange={handlePasswordChange}
              />
              <input
                id="passwordVerification"
                name="passwordVerification"
                type="password"
                autoComplete="new-password"
                required
                className={`border-0 border-b border-black p-4 sm:text-sm sm:leading-6 ${passwordVerifcation.length > 0 && passwordVerifcation !== password ? "bg-fikseRed" : ""}`}
                placeholder="Bekreft Passord"
                value={passwordVerifcation}
                onChange={handlePasswordVerificationChange}
              />
            </div>
            <button
              type="button"
              className={`w-full border-b border-black bg-fikseBlue p-4 text-left text-sm ${passwordVerifcation === password && password.length >= 6 && passwordVerifcation.length >= 6 ? `hover:bg-[#7bc3e0]` : `hover:bg-fikseRed`}`}
              onClick={async () => {
                if (
                  passwordVerifcation === password &&
                  password.length >= 6 &&
                  passwordVerifcation.length >= 6
                ) {
                  const result = await createUserWithVerification(email, password);
                  if (result.success) {
                    setEmailPasswordModalOpen(false);
                    // Show verification message to user
                    alert("Please check your email for verification link");
                  } else {
                    alert(result.message);
                  }
                  onClose();
                  setProceedWithEmailLoggin(false);
                }
              }}
            >
              Regisrere passord (minst 6 tegn)
            </button>
          </div>
        </div>
      );
    } else if (emailAuthStatus?.hasPassword && !emailAuthStatus?.isVerified) {
      //setAccountNeedVerification(true);
      return (
        <div className="border">
          <button
            type="button"
            className="w-full border-b border-black bg-fikseBlue p-4 text-left text-sm hover:bg-[#7bc3e0]"
            onClick={async () => {
              sendNewVerificationEmail(email);
              setAccountNeedVerification(false);
              setProceedWithEmailLoggin(false);
              setEmailLinkLoginModalOpen(true);
            }}
          >
            Send ny verifisieringskode
          </button>
        </div>
      );
    } else if (emailAuthStatus?.isVerified) {
      // setLoggInWithPassrodModal(true);
      return (
        <div className="flex max-w-lg flex-col">
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="min-w-0 flex-auto border-0 border-b border-black bg-white/5 p-4 sm:text-sm sm:leading-6"
            placeholder="Passord"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                const result = await signInWithEmailPassword(email, password);
                if (result.success) {
                  setLoggInWithPassrodModal(false);
                  window.location.reload();
                } else {
                  setWrongPassword(true);
                }
              }
            }}
          />
          <button
            type="button"
            className="w-full border-b border-black bg-fikseBlue p-4 text-left text-sm hover:bg-[#7bc3e0]"
            onClick={async () => {
              const result = await signInWithEmailPassword(email, password);
              if (result.success) {
                setLoggInWithPassrodModal(false);
                window.location.reload();
              } else {
                setWrongPassword(true);
              }
            }}
          >
            Logg inn med passord
          </button>
          {wrongPassword ? (
            <>
              <button
                type="button"
                className="w-full border-b border-black bg-fikseRed p-4 text-left text-sm hover:bg-[#d89096]"
                onClick={async () => {
                  await sendPasswordResetEmailHandler(email);
                  setLoggInWithPassrodModal(false);
                  setWrongPassword(false);
                  setEmailLinkLoginModalOpen(true);
                }}
              >
                Feil passord, resett passordet ditt.
              </button>
              <p className="border-black p-4 text-xs leading-8 text-gray-700">
                Har du tidligere brukt eposten din med innloggingslenke, må du resette
                passordet{" "}
              </p>
            </>
          ) : (
            <p className="border-black p-4 text-xs leading-8 text-gray-700">
              Har du tidligere brukt eposten din med innloggingslenke, må du{" "}
              <span
                onClick={async () => {
                  await sendPasswordResetEmailHandler(email);
                  setLoggInWithPassrodModal(false);
                  setWrongPassword(false);
                  setEmailLinkLoginModalOpen(true);
                }}
                className="cursor-pointer text-blue-500 underline"
              >
                resette passordet
              </span>
            </p>
          )}
        </div>
      );
    }
  };

  const handleConfirmClick = () => {
    if (email) {
      if (showPasswordField) {
        // Sign in with email and password
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            localStorage.setItem("uid", email);
            localStorage.setItem("verifiedUid", email);
            window.location.href = "/";
          })
          .catch((error) => {
            alert("Feil ved innlogging: " + error.message);
          });
      } else {
        // Email verification
        localStorage.setItem("uid", email);
        setStep("awaitingVerification");
      }
    } else {
      alert("Vennligst skriv inn en gyldig e-postadresse.");
    }
  };

  const passordLogginSection = () => {
    return (
      <>
        <div className="flex max-w-lg gap-x-4">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="min-w-0 flex-auto border-0 border-b border-black bg-white/5 p-4 sm:text-sm sm:leading-6"
            placeholder="Skriv din epost her"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
          className="w-full flex-auto border-0 border-b border-black bg-white/5 p-4 sm:text-sm sm:leading-6"
          placeholder="Passord"
          value={password}
          onChange={handlePasswordChange}
        />
        <div className="w-full">
          <button
            type="button"
            className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
            onClick={loggAndSignInWithEmailandPassword}
          >
            Fortsett
          </button>
        </div>
        {wrongPassword && (
          <>
            <button
              type="button"
              className="w-full border-b border-black bg-fikseRed p-4 text-left text-sm hover:bg-[#d89096]"
              onClick={async () => {
                await sendPasswordResetEmailHandler(email);
                setLoggInWithPassrodModal(false);
                setWrongPassword(false);
                setEmailLinkLoginModalOpen(true);
              }}
            >
              Feil passord, trykk her for å resette passordet ditt.
            </button>
            <p className="border-black p-4 text-xs leading-8 text-gray-700">
              Har du tidligere brukt eposten din med innloggingslenke, må du resette
              passordet{" "}
            </p>
          </>
        )}
        <button
          type="button"
          className="w-full border-y border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
          onClick={logginWithLink}
        >
          Send meg en innloggingslenke
        </button>
      </>
    );
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="fixed inset-0 z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto sm:pb-0 sm:pb-20">
            <div className="flex h-full w-full items-center justify-center sm:h-auto sm:w-auto">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative h-full w-screen transform overflow-auto bg-white text-left shadow-xl transition-all sm:h-auto sm:w-full sm:max-w-lg`}
                >
                  <ModalTile
                    open={proceedWithEmailLoggin}
                    onClose={() => setProceedWithEmailLoggin(false)}
                    title={"Logg inn med epost"}
                  >
                    <p className="mt-4 border-b border-black p-4 text-lg leading-8 text-gray-700">
                      Innlogging for {email}
                    </p>

                    {renderPasswordSection()}
                    <p className="my-4 p-4 text-center text-sm"> Eller </p>
                    <button
                      type="button"
                      className="w-full border-y border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
                      onClick={logginWithLink}
                    >
                      Send meg en innloggingslenke
                    </button>
                  </ModalTile>
                  <ModalTile
                    open={emailLinkLoginModalOpen}
                    onClose={() => setEmailLinkLoginModalOpen(false)}
                    title="E-post sendt"
                  >
                    <div className="border">
                      <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
                        Lenke er sendt til epost {email}. Sjekk inboksen din for lenke.
                      </p>
                    </div>
                  </ModalTile>

                  <ModalTile
                    open={showMicrosoftVerificationModal}
                    onClose={() => setShowMicrosoftVerificationModal(false)}
                    title="Microsoft-bruker oppdaget"
                  >
                    <div className="border">
                      <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
                        Vi har sendt deg en e-post med en lenke for å opprette passord for
                        din Fikse-konto. Vennligst sjekk innboksen din og følg
                        instruksjonene i e-posten.
                      </p>
                    </div>
                  </ModalTile>

                  <div className="absolute right-0 top-0 pr-4 pt-4">
                    <button
                      type="button"
                      className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#8cd7ff] focus:ring-offset-2"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      ></svg>
                    </button>
                  </div>
                  {step === "emailInput" ? (
                    <div>
                      <h1 className="ml-6 pt-4 text-3xl tracking-tight">
                        Logg inn hos Fikse
                      </h1>
                      <div className="border">
                        <p className="border-b border-black p-4 text-lg leading-8 text-gray-700">
                          <p className="mt-8">
                            Vi vil plassere deg på rett sted basert på din e-post, og
                            sende oppdateringer på mail.
                          </p>
                        </p>

                        {passordLogginSection()}
                      </div>
                      <div
                        className={`w-full border-b border-black p-5 text-center text-sm`}
                      ></div>
                    </div>
                  ) : (
                    //localStorage.getItem("uid") ? <AwaitingVerification email={email} /> : null
                    <AwaitingVerification email={email} />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

//todo: fjern
