import React, { ReactNode } from "react";

interface TextProps {
  text?: string;
  className?: string;
  children?: ReactNode;
  as?: keyof JSX.IntrinsicElements;
}

const Text: React.FC<TextProps> = ({ text, className, children, as = "p" }) => {
  const TextTag = as;

  // Default className that will be applied to the outer div
  const globalClassName = "text-base px-4";

  return (
    <div className={globalClassName}>
      <div className={className || ""}>
        <TextTag>{children || text}</TextTag>
      </div>
    </div>
  );
};

export default Text;
