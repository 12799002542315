import React from "react";

import { Analytics, MonthlyStoreAndTotalAnalytics } from "@type/retailAnalytic";
import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface CarbonLCAGraphProps {
  analytics: Analytics;
  showTotalCarbon: boolean;
}

const CarbonLCAGraph: React.FC<CarbonLCAGraphProps> = ({
  analytics,
  showTotalCarbon,
}) => {
  // Transform analytics data into the format needed for the graph
  const graphData =
    analytics.carbonFootprintAnalytics?.map((month: MonthlyStoreAndTotalAnalytics) => {
      // Create base data point with total carbon footprint
      const dataPoint: Record<string, string | number> = {
        month: month.yearandmonth,
        total: month.totalMonthAmount,
      };

      // Add individual store carbon footprints
      month.storeSpesific.forEach((storeCarbon) => {
        dataPoint[storeCarbon.storeName] = storeCarbon.totalMonthAmount;
      });

      return dataPoint;
    }) || [];

  // Get unique store names for generating areas
  const storeNames =
    analytics.carbonFootprintAnalytics?.[0]?.storeSpesific.map(
      (store) => store.storeName
    ) || [];

  // Generate unique colors for each store
  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#00C49F"];

  return (
    <div className="mt-4 h-[400px] w-full">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" label={{ value: "Month", position: "bottom" }} />
          <YAxis
            label={{
              value: "Carbon Footprint (CO₂e)",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          <Legend />

          {/* Total carbon footprint area - only show if showTotalCarbon is true */}
          {showTotalCarbon && (
            <Area
              type="monotone"
              dataKey="total"
              stroke="#000000"
              fill="#000000"
              fillOpacity={0.3}
              strokeWidth={2}
              name="Total Carbon Footprint"
            />
          )}

          {/* Individual store areas */}
          {storeNames.map((store: string, index: number) => (
            <Area
              key={store}
              type="monotone"
              dataKey={store}
              stroke={colors[index % colors.length]}
              fill={colors[index % colors.length]}
              fillOpacity={0.6}
              name={store}
              stackId={showTotalCarbon ? undefined : "1"} // Stack areas only when total is hidden
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CarbonLCAGraph;
