import React, { useMemo, useState } from "react";

import { Analytics, ClaimsAnalytics } from "@type/retailAnalytic";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface ClaimsGraphProps {
  analytics: Analytics;
}

const ClaimsGraph: React.FC<ClaimsGraphProps> = ({ analytics }) => {
  const [selectedUnitForBar, setSelectedUnitForBar] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");

  // Get all unique unit names
  const unitNames = useMemo(() => {
    const names = new Set<string>();
    analytics.claimAnalytics?.forEach((claim) => {
      claim.units.forEach((unit) => {
        names.add(unit.unitName);
      });
    });
    return Array.from(names);
  }, [analytics]);

  // Set initial selected unit and month
  React.useEffect(() => {
    if (!selectedUnitForBar && unitNames.length > 0) {
      setSelectedUnitForBar(unitNames[0]);
    }
    const claims = analytics.claimAnalytics;
    if (!selectedMonth && claims && claims.length > 0) {
      setSelectedMonth(claims[0].yearandmonth);
    }
  }, [unitNames, selectedUnitForBar, analytics.claimAnalytics, selectedMonth]);

  // Transform data for the main chart (all units)
  const mainChartData = useMemo(() => {
    if (!analytics.claimAnalytics) return [];

    return analytics.claimAnalytics.map((monthData) => {
      const dataPoint: Record<string, any> = {
        month: monthData.yearandmonth,
      };

      // Add data for all units
      monthData.units.forEach((unit) => {
        dataPoint[unit.unitName] = unit.totalMonthAmount;
      });

      return dataPoint;
    });
  }, [analytics.claimAnalytics]);

  // Transform data for the sidebar chart (top 5 items for selected unit and month)
  const sidebarData = useMemo(() => {
    if (!selectedUnitForBar || !selectedMonth || !analytics.claimAnalytics) return [];

    const monthData = analytics.claimAnalytics.find(
      (data) => data.yearandmonth === selectedMonth
    );
    if (!monthData) return [];

    const unitData = monthData.units.find((unit) => unit.unitName === selectedUnitForBar);
    if (!unitData) return [];

    return unitData.items
      .sort((a, b) => b.totalMonthAmount - a.totalMonthAmount)
      .slice(0, 5)
      .map((item) => ({
        name: item.itemName,
        amount: item.totalMonthAmount,
      }));
  }, [analytics.claimAnalytics, selectedUnitForBar, selectedMonth]);

  const lineColors = ["#413ea0", "#82ca9d", "#8884d8", "#ffc658", "#ff7300", "#00C49F"];

  if (!analytics.claimAnalytics || unitNames.length === 0) {
    return <div>No claims data available</div>;
  }

  return (
    <div className="mt-4 flex flex-col gap-4">
      {/* Main Chart Area */}
      <div className="h-[500px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={mainChartData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="month" scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />

            {/* Lines for all units */}
            {unitNames.map((unitName, index) => (
              <Line
                key={unitName}
                type="monotone"
                dataKey={unitName}
                stroke={lineColors[index % lineColors.length]}
                strokeWidth={2}
                dot={false}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      {/* Bottom Section */}
      <div className="flex gap-4">
        {/* Selectors */}
        <div className="flex w-1/3 flex-col gap-4">
          <div className="rounded-md border border-gray-200 p-4">
            <h3 className="mb-2 font-semibold">Velg Butikk</h3>
            <select
              value={selectedUnitForBar}
              onChange={(e) => setSelectedUnitForBar(e.target.value)}
              className="w-full rounded-md border border-gray-300 px-4 py-2"
            >
              {unitNames.map((unitName) => (
                <option key={unitName} value={unitName}>
                  {unitName}
                </option>
              ))}
            </select>
          </div>

          <div className="rounded-md border border-gray-200 p-4">
            <h3 className="mb-2 font-semibold">Velg Måned</h3>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="w-full rounded-md border border-gray-300 px-4 py-2"
            >
              {[...analytics.claimAnalytics].reverse().map((data) => (
                <option key={data.yearandmonth} value={data.yearandmonth}>
                  {data.yearandmonth}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Bar Chart */}
        <div className="h-[300px] w-2/3 rounded-md border border-gray-200 p-4">
          <h3 className="mb-2 font-semibold">Highest claim rates this month</h3>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={sidebarData}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="name" width={200} />
              <Tooltip />
              <Bar dataKey="amount" fill="#413ea0" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ClaimsGraph;
