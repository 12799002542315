import React from "react";

import { OrderInfo } from "@type/Order";
import { useTranslation } from "react-i18next";

// Color scheme for the stepper
interface ColorScheme {
  past: {
    bg: string;
    text: string;
  };
  current: {
    bg: string;
    text: string;
  };
  future: {
    bg: string;
    text: string;
  };
}

interface StepperFromOrderInfoProps {
  orderInfo: OrderInfo;
  colorScheme?: ColorScheme;
}

// Default color scheme
const defaultColorScheme: ColorScheme = {
  past: {
    bg: "bg-blue-600",
    text: "text-white",
  },
  current: {
    bg: "bg-blue-400",
    text: "text-white",
  },
  future: {
    bg: "bg-gray-200",
    text: "text-gray-800",
  },
};

const StepperFromOrderInfo: React.FC<StepperFromOrderInfoProps> = ({
  orderInfo,
  colorScheme = defaultColorScheme,
}) => {
  const { t } = useTranslation();

  // If no eventPredictor is available, we can't create a stepper
  if (!orderInfo.eventPredictor || orderInfo.eventPredictor.length === 0) {
    return <div className="text-sm text-gray-500">No steps available</div>;
  }

  // Determine current step by finding the latest event's status in eventPredictor
  const currentStepIndex = (): number => {
    if (!orderInfo.event || orderInfo.event.length === 0) {
      return 0; // No events yet, so we're at step 0
    }

    // Get the most recent event status
    const latestEvent = orderInfo.event[orderInfo.event.length - 1];
    const latestStatus = latestEvent.status;

    // Find its index in eventPredictor
    const statusIndex = orderInfo.eventPredictor.findIndex(
      (status) => status === latestStatus
    );

    // If status not found in eventPredictor, return 0
    return statusIndex === -1 ? 0 : statusIndex;
  };

  const currentIndex = currentStepIndex();

  // Function to determine the color scheme for a step
  const getStepStyle = (index: number) => {
    if (index < currentIndex) {
      return `${colorScheme.past.bg} ${colorScheme.past.text}`; // Past step
    } else if (index === currentIndex) {
      return `${colorScheme.current.bg} ${colorScheme.current.text}`; // Current step
    } else {
      return `${colorScheme.future.bg} ${colorScheme.future.text}`; // Future step
    }
  };

  // Translate status code using i18n instead of simple string manipulation
  const getStepLabel = (status: string): string => {
    return t(`workwearOrderPage.${status}`) || status.replace("orderState", "");
  };

  // Determine how many steps to show (up to 3 or 4 is usually good for UI)
  const maxVisibleSteps = Math.min(orderInfo.eventPredictor.length, 4);

  // Get the steps to show, prioritizing showing the current step
  const getStepsToShow = () => {
    // If we have 3 or fewer steps, show all
    if (orderInfo.eventPredictor.length <= maxVisibleSteps) {
      return orderInfo.eventPredictor;
    }

    // If current step is near the start
    if (currentIndex < 2) {
      return orderInfo.eventPredictor.slice(0, maxVisibleSteps);
    }

    // If current step is near the end
    if (currentIndex > orderInfo.eventPredictor.length - 2) {
      return orderInfo.eventPredictor.slice(-maxVisibleSteps);
    }

    // Otherwise, center around current step
    return orderInfo.eventPredictor.slice(
      currentIndex - 1,
      currentIndex + maxVisibleSteps - 1
    );
  };

  const visibleSteps = getStepsToShow();

  return (
    <div className="flex items-center">
      {visibleSteps.map((step, index) => {
        const actualIndex = orderInfo.eventPredictor.indexOf(step);
        const isFirst = index === 0;
        const isLast = index === visibleSteps.length - 1;
        const label = getStepLabel(step);

        // Determine padding classes based on position
        const paddingClasses = isFirst
          ? "pl-3 pr-4" // First item: 12px left, 16px right
          : isLast
            ? "pl-4 pr-3" // Last item: 16px left, 12px right
            : "px-4"; // Middle items: 16px both sides

        return (
          <div
            key={`step-${index}`}
            className="relative h-6"
            style={{
              marginRight: isLast ? "0" : "0.25px",
            }}
          >
            <div
              className={`h-full ${getStepStyle(actualIndex)} flex items-center justify-center whitespace-nowrap ${paddingClasses}`}
              style={{
                clipPath:
                  isFirst && isLast
                    ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
                    : isFirst
                      ? "polygon(0 0, calc(100% - 6px) 0, 100% 50%, calc(100% - 6px) 100%, 0 100%)"
                      : !isLast
                        ? "polygon(0 0, calc(100% - 6px) 0, 100% 50%, calc(100% - 6px) 100%, 0 100%, 6px 50%)"
                        : "polygon(0 0, 100% 0, 100% 100%, 0 100%, 6px 50%)",
                borderRadius: isFirst ? "3px 0 0 3px" : isLast ? "0 3px 3px 0" : "0",
              }}
            >
              <span className="text-xs font-medium">{label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepperFromOrderInfo;
