import { GeoPoint, collection, doc, getDoc } from "firebase/firestore";

import { db } from "../firebase";
import { User } from "./users";

export interface Tailor {
  id: string;
  name: string;
  orgNumber: string;
  address: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  firebaseCoordinates?: GeoPoint;
  language?: string;
  contactPerson: {
    name: string;
    phone: string;
    email?: string; // Changed to required field
  };
  cut: {
    agreeBefore: boolean;
    cleaning: number | null;
    repairs: number | null;
  };
  preVerifiedEmails?: string[];
  moderatorEmails?: string[]; // Add this line
  roles: string[];
}

export interface TailorInfo {
  tailorPersonEmail?: string;
  tailorPersonPhone?: string;
  tailorPersonName?: string;
  tailorPersonId?: string;
  timestamp: string;
  tailorHouseId: string;
  tailorHouseName: string;
  orderId: string;
}

export const tailorPersonDisplayName = async (uid: string): Promise<string> => {
  const docRef = await doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    return userData.name || userData.phoneNumber || userData.email;
  } else {
    return "Unknown User";
  }
};
