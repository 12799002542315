import { Analytics } from "../../types/retailAnalytic";

interface DateSelectorForRetailProps {
  analytics: Analytics;
  setAnalyticsDocument: (analytics: Analytics) => void;
}

const DateSelectorForRetail: React.FC<DateSelectorForRetailProps> = ({
  analytics,
  setAnalyticsDocument,
}) => {
  // Get all unique dates from different analytics types
  const getAllDates = (): string[] => {
    const dates = new Set<string>();

    analytics.savingsAnalytics?.forEach((item) => {
      dates.add(item.yearandmonth);
    });

    analytics.carbonFootprintAnalytics?.forEach((item) => {
      dates.add(item.yearandmonth);
    });

    analytics.claimAnalytics?.forEach((item) => {
      dates.add(item.yearandmonth);
    });

    return Array.from(dates).sort();
  };

  const allDates = getAllDates();

  const filterAnalytics = (fromDate: string, toDate: string) => {
    const filteredAnalytics: Analytics = {
      retailId: analytics.retailId,
      savingsAnalytics: analytics.savingsAnalytics?.filter(
        (item) => item.yearandmonth >= fromDate && item.yearandmonth <= toDate
      ),
      carbonFootprintAnalytics: analytics.carbonFootprintAnalytics?.filter(
        (item) => item.yearandmonth >= fromDate && item.yearandmonth <= toDate
      ),
      claimAnalytics: analytics.claimAnalytics?.filter(
        (item) => item.yearandmonth >= fromDate && item.yearandmonth <= toDate
      ),
    };

    setAnalyticsDocument(filteredAnalytics);
  };

  return (
    <div className="mb-4 flex gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">From Date</label>
        <select
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => {
            const toDate =
              document.querySelector<HTMLSelectElement>("[data-to-date]")?.value || "";
            filterAnalytics(e.target.value, toDate);
          }}
        >
          {allDates.map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">To Date</label>
        <select
          data-to-date
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => {
            const fromDate =
              document.querySelector<HTMLSelectElement>("[data-from-date]")?.value || "";
            filterAnalytics(fromDate, e.target.value);
          }}
        >
          {allDates.map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DateSelectorForRetail;
