import { useEffect, useState } from "react";

import { Analytics } from "@type/retailAnalytic";
import { collection, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Lottie from "react-lottie";

import { getRetailElementsFromRetailUUIDstring } from "../components/databaseHelper";
import LoadingSpinner from "../components/fikseLoadingLetter";
import { db, functionsEW1 } from "../firebase";
import Retail from "../types/retail";
import DateSelectorForRetail from "./dashBComponents/DateSelectorForRetail";
import RetailSelector from "./dashBComponents/RetailSelector";
import SavingsGraph from "./dashBComponents/SavingsGraph";
import CarbonLCAGraph from "./dashBComponents/carbonLCAgraph";
import ClaimsGraph from "./dashBComponents/claimsGraphs";
import generatedAnalytics from "./generated_analytics_new.json";

const generateAndPullAnalyticDucoment = async (inputRetailId: string) => {
  /*if (inputRetailId === "AdminDocument4Testing") {
    return generatedAnalytics as Analytics;
  }*/

  try {
    const analyticRef = collection(db, "analytics");
    const q = query(analyticRef, where("retailId", "==", inputRetailId));
    const analyticSnapshot = await getDocs(q);

    if (analyticSnapshot.empty) {
      const generateAnalytics = httpsCallable(functionsEW1, "generateAnalyticsData");
      const result = await generateAnalytics({ requestFromRetailID: inputRetailId });
      return result.data as Analytics;
    } else {
      return analyticSnapshot.docs[0].data() as Analytics;
    }
  } catch (error) {
    console.error("Error pulling analytics:", error);
    return null;
  }
};

const retailsInLocalStorage = async (): Promise<Retail[]> => {
  const localRetails = localStorage.getItem("retailConnections");
  if (!localRetails) return [];
  const retailIds = localRetails.split(",").filter((id) => id.length > 0);
  const retails = await getRetailElementsFromRetailUUIDstring(retailIds);
  return retails;
};

const RetailAnalytics: React.FC = () => {
  const [selectedRetailID, setSelectedRetailID] = useState<Retail | null>(null);
  const [analyticDocument, setAnalyticDocument] = useState<Analytics | null>(null);
  const [unfilteredAnalyticDocument, setUnfilteredAnalyticDocument] =
    useState<Analytics | null>(null);
  const [isLoadingRetailConnections, setIsLoadingRetailConnections] = useState(true);
  const [isGeneratingAnalytics, setIsGeneratingAnalytics] = useState(false);
  const [showTotalSavings, setShowTotalSavings] = useState(true);
  const [showTotalCarbon, setShowTotalCarbon] = useState(true);

  // Fetch analytics when selected retail changes
  useEffect(() => {
    if (selectedRetailID?.id) {
      const fetchAnalytics = async () => {
        const analytics = await generateAndPullAnalyticDucoment(selectedRetailID.id);
        setAnalyticDocument(analytics);
        setUnfilteredAnalyticDocument(analytics);
      };
      fetchAnalytics();
    }
    setIsLoadingRetailConnections(false);
  }, [selectedRetailID]);

  const handleGenerateAnalytics = async () => {
    if (!selectedRetailID?.id) return;

    setIsGeneratingAnalytics(true);
    try {
      const generateRetailAnalytics = httpsCallable(
        functionsEW1,
        "generateRetailAnalyticsDocument"
      );
      const result = await generateRetailAnalytics(selectedRetailID.id);
      console.log("Analytics generation result:", result.data);

      // Refresh analytics after generation
      const analytics = await generateAndPullAnalyticDucoment(selectedRetailID.id);
      setAnalyticDocument(analytics);
      setUnfilteredAnalyticDocument(analytics);
    } catch (error) {
      console.error("Error generating analytics:", error);
    } finally {
      setIsGeneratingAnalytics(false);
    }
  };

  if (isLoadingRetailConnections) {
    return <LoadingSpinner />;
  }

  return (
    <div className="px-8">
      <div className="mb-6 flex items-center gap-4">
        <RetailSelector
          selectedRetailID={selectedRetailID}
          onRetailSelect={setSelectedRetailID}
        />
        {selectedRetailID && (
          <button
            onClick={handleGenerateAnalytics}
            disabled={isGeneratingAnalytics}
            className={`rounded-md px-4 py-2 text-sm font-medium text-white transition-colors ${
              isGeneratingAnalytics
                ? "cursor-not-allowed bg-gray-400"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isGeneratingAnalytics ? "Generating..." : "Generate Analytics"}
          </button>
        )}
      </div>

      {analyticDocument && unfilteredAnalyticDocument && (
        <>
          <DateSelectorForRetail
            analytics={unfilteredAnalyticDocument}
            setAnalyticsDocument={setAnalyticDocument}
          />

          <div className="mt-4">
            <div className="mb-8">
              <h2 className="mb-4 text-xl font-semibold">Estimated savings</h2>

              <div className="mb-4 flex items-center gap-2">
                <button
                  onClick={() => setShowTotalSavings(!showTotalSavings)}
                  className={`rounded-md px-4 py-2 text-sm font-medium transition-colors ${
                    showTotalSavings
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {showTotalSavings ? "Hide Total Savings" : "Show Total Savings"}
                </button>
              </div>
              <SavingsGraph
                analytics={analyticDocument}
                showTotalSavings={showTotalSavings}
              />
            </div>

            <div className="mb-8">
              <h2 className="mb-4 text-xl font-semibold">
                LCA analysis: Carbon footprint and waste reduction
              </h2>

              <div className="mb-4 flex items-center gap-2">
                <button
                  onClick={() => setShowTotalCarbon(!showTotalCarbon)}
                  className={`rounded-md px-4 py-2 text-sm font-medium transition-colors ${
                    showTotalCarbon
                      ? "bg-green-600 text-white hover:bg-green-700"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {showTotalCarbon ? "Hide Total Carbon" : "Show Total Carbon"}
                </button>
              </div>
              <CarbonLCAGraph
                analytics={analyticDocument}
                showTotalCarbon={showTotalCarbon}
              />
            </div>

            <div className="mb-8">
              <h2 className="mb-4 text-xl font-semibold">Claims: Monthly average</h2>
              <ClaimsGraph analytics={analyticDocument} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RetailAnalytics;
