import React, { useState } from "react";

import { deleteDoc, doc, getFirestore } from "firebase/firestore";

import { db } from "../firebase";

const DeleteOrdersButton = () => {
  const [status, setStatus] = useState("idle");
  const [results, setResults] = useState({ success: 0, error: 0 });

  const orderIds = [
    "xXAtpHEBtAIXpu4w0cOt",
    "XXGqehNXohrfc4RO7Sa6Kj",
    "XXfGDOd6E8V7d6HOXtNxPk",
    "mxQz2XTlUdgfAW2fA0xx",
    "XXjhTisaeMVU1NgfXKzjR3",
    "XXDHLUQ308984B2C",
    "SMHBI972499B2C",
    "KBVKF937397B2C",
    "XXKBVKF937397B2C",
    "XXFMUDK010148B2C",
    "XXWLQSA514815B2C",
    "XXZABPV204448B2C",
    "CSECQ239205B2C",
    "XXCSECQ239205B2C",
    "HMQCE248847B2C",
    "XXHMQCE248847B2C",
    "AKFYA596737B2C",
    "VNMRY850200B2C",
    "XXOZPDD164019B2C",
    "TlVDpYTupvCeYjaqmSh2",
    "cIkHfJAdKzHgWrlkZgCF",
    "iwNWlqPaTN1zyHT27KE0",
    "XXfG9IeWvUyWfH1M80XfYA",
    "fG9IeWvUyWfH1M80XfYA",
    "kQkx9fPTweXHIrL0Qb7S",
  ];

  const deleteOrders = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete these orders? This action cannot be undone."
      )
    ) {
      return;
    }

    let successCount = 0;
    let errorCount = 0;

    setStatus("deleting");

    try {
      for (const orderId of orderIds) {
        try {
          const orderDocRef = doc(db, "b2cOrders", orderId);
          await deleteDoc(orderDocRef);
          successCount++;
          setResults((prev) => ({ ...prev, success: successCount }));
        } catch (error) {
          console.error(`Error deleting order ${orderId}:`, error);
          errorCount++;
          setResults((prev) => ({ ...prev, error: errorCount }));
        }
      }
      setStatus("completed");
    } catch (error) {
      console.error("Fatal error:", error);
      setStatus("error");
    }
  };

  const getStatusMessage = () => {
    switch (status) {
      case "deleting":
        return `Deleting orders... Successfully deleted: ${results.success}, Errors: ${results.error}`;
      case "completed":
        return `Deletion complete! Successfully deleted: ${results.success}, Errors: ${results.error}`;
      case "error":
        return "An error occurred during the deletion process.";
      default:
        return "";
    }
  };

  return (
    <div className="p-4">
      <button
        onClick={deleteOrders}
        disabled={status === "deleting"}
        className={`rounded bg-red-600 px-4 py-2 text-white ${status === "deleting" ? "cursor-not-allowed opacity-50" : "hover:bg-red-700"} transition-colors duration-200`}
      >
        {status === "deleting" ? "Deleting..." : "Delete Orders"}
      </button>

      {status !== "idle" && (
        <div
          className={`mt-4 rounded p-4 ${status === "error" ? "border border-red-300 bg-red-100" : "border border-blue-200 bg-blue-50"} `}
        >
          {getStatusMessage()}
        </div>
      )}
    </div>
  );
};

export default DeleteOrdersButton;
