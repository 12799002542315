import { useState } from "react";

import { Dialog } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../firebase";

interface ServiceCategory {
  id: string;
  description: string;
  fix_type: string;
  item: string;
  price_nok: number;
  problem: string;
  work: string;
  placeholder: string;
  carbonFootprint?: number;
}

interface ServiceCategories {
  id: string;
  name: string;
  categories: ServiceCategory[];
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
}

interface EditServiceCategoryModalProps {
  serviceCategory: ServiceCategories;
  onClose: () => void;
  onSave: (updatedServiceCategory: ServiceCategories) => void;
}

export function EditServiceCategoryModal({
  serviceCategory,
  onClose,
  onSave,
}: EditServiceCategoryModalProps) {
  const [formData, setFormData] = useState(serviceCategory);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCategoryChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Convert price_nok and carbonFootprint to numbers if those are the fields being changed
    const processedValue =
      name === "price_nok" || name === "carbonFootprint" ? Number(value) : value;

    const updatedCategories = formData.categories.map((category, i) =>
      i === index ? { ...category, [name]: processedValue } : category
    );
    setFormData({ ...formData, categories: updatedCategories });
  };

  const handleAddCategory = () => {
    setFormData({
      ...formData,
      categories: [
        ...formData.categories,
        {
          id: "",
          description: "",
          fix_type: "",
          item: "",
          price_nok: 0,
          problem: "",
          work: "",
          placeholder: "",
          carbonFootprint: 0,
        },
      ],
    });
  };

  const handleRemoveCategory = (index: number) => {
    if (window.confirm("Er du sikker på at du vil fjerne denne kategorien?")) {
      setFormData({
        ...formData,
        categories: formData.categories.filter((_, i) => i !== index),
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const serviceCategoryRef = doc(db, "serviceCategories", formData.id);
    const { name, categories, createdAt } = formData;
    await updateDoc(serviceCategoryRef, { name, categories, createdAt });
    onSave(formData);
  };

  return (
    <Dialog open={true} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <Dialog.Panel className="relative w-full max-w-4xl transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div className="">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                Edit Service Category
              </Dialog.Title>
              <form className="mt-2 space-y-4" onSubmit={handleSubmit}>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-4">
                  {formData.categories.map((category, index) => (
                    <div key={index} className="grid grid-cols-9 items-center gap-4">
                      <input
                        type="text"
                        name="id"
                        placeholder="ID"
                        value={category.id}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="description"
                        placeholder="Description"
                        value={category.description}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="work"
                        placeholder="Work"
                        value={category.work}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="number"
                        name="price_nok"
                        placeholder="Price (NOK)"
                        value={category.price_nok}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="number"
                        name="carbonFootprint"
                        placeholder="Carbon (g)"
                        value={category.carbonFootprint || 0}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="problem"
                        placeholder="Problem"
                        value={category.problem}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="fix_type"
                        placeholder="Fix Type"
                        value={category.fix_type}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="item"
                        placeholder="Item"
                        value={category.item}
                        onChange={(e) => handleCategoryChange(index, e)}
                        className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <button
                        type="button"
                        className="text-red-600 hover:text-red-900"
                        onClick={() => handleRemoveCategory(index)}
                      >
                        <XCircleIcon className="h-6 w-6" />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex justify-between">
                  <button
                    type="button"
                    className="inline-flex justify-center border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={handleAddCategory}
                  >
                    Add Category
                  </button>
                </div>
                <div className="mt-5 flex justify-between space-x-4 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center bg-[#8CD7FF] px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Save Changes
                  </button>
                  <button
                    onClick={onClose}
                    className="inline-flex w-full justify-center border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
